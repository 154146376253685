import 'reflect-metadata';
import createPrefetchProducer from 'web/src/modules/core/apps/main/prefetch/createPrefetchProducer';
import { bootstrap } from 'web/src/modules/core/apps/main/bootstrap';
import providePolyfills from 'web/src/utils/providePolyfills';
import { loadThemeStyles } from 'web/src/core/theme';
import { configureWebServiceLocator } from 'web/src/modules/core/ioc';
import { bootstrapEnvironment } from './plugins/EnvironmentPlugin/bootstrap';
loadThemeStyles();
// eslint-disable-next-line no-underscore-dangle
if ('string' == typeof window.__webpack_public_path__) // eslint-disable-next-line no-underscore-dangle
__webpack_public_path__ = window.__webpack_public_path__;
async function init() {
    providePolyfills();
    configureWebServiceLocator();
    bootstrapEnvironment();
    const prefetchProducer = createPrefetchProducer();
    bootstrap(prefetchProducer).// eslint-disable-next-line no-console
    catch((error)=>console.error(error));
}
init();
