import { _ as _define_property } from "@swc/helpers/_/_define_property";
import { getConflictingKeys } from '@leon-hub/utils';
function sortGqlSubRequests(a, b) {
    // return b.getPriority() - a.getPriority()
    //       || Number(b.mutation) - Number(a.mutation)
    //       || a.created - b.created
    //       || 0;
    const aPriority = a.getPriority();
    const bPriority = b.getPriority();
    if (aPriority > bPriority) return -1;
    if (bPriority > aPriority) return 1;
    if (a.mutation && !b.mutation) return -1;
    if (!a.mutation && b.mutation) return 1;
    if (a.created < b.created) return -1;
    if (b.created < a.created) return 1;
    return 0;
}
let GqlBatchedRequest = class GqlBatchedRequest {
    static createId() {
        GqlBatchedRequest.autoIncrement += 1;
        return GqlBatchedRequest.autoIncrement;
    }
    find(param) {
        let { id } = param;
        return this.mutations.find((item)=>item.id === id) || this.queries.find((item)=>item.id === id);
    }
    getPromises() {
        return this.getGqlSubRequests().map((param)=>{
            let { deferred } = param;
            return deferred.promise;
        });
    }
    whenFulfilled() {
        return Promise.allSettled(this.getPromises()).then();
    }
    getGqlSubRequests() {
        return [
            ...this.mutations,
            ...this.queries
        ];
    }
    /**
   * Get sorted list of requests.
   * 1) Sort by priority
   * 2) Sort by query type (mutation or not)
   * 3) Sort by created.
   */ getSortedGqlSubRequests() {
        return this.getGqlSubRequests().sort(sortGqlSubRequests);
    }
    update(param) {
        let { queries, mutations } = param;
        this.queries = queries ?? this.queries;
        this.mutations = mutations ?? this.mutations;
    }
    /**
   * @param {number} [size=Infinity] max size of new batch.
   * @param {function} [filter] filter batch items
   */ getBatch(param) {
        let { size = 1 / 0, doExtractBatch = false, filter = ()=>true } = param;
        const usedHeaders = {};
        let batchTimeout = 0;
        const gqlSubRequests = this.getSortedGqlSubRequests().reduce((accumulator, gqlSubRequest)=>{
            batchTimeout = Math.max(gqlSubRequest.getTimeout() ?? 0, batchTimeout);
            if (accumulator.length >= size) return accumulator;
            if (!filter(gqlSubRequest)) return accumulator;
            const headers = gqlSubRequest.getHeaders();
            const conflictingKeys = getConflictingKeys([
                usedHeaders,
                headers
            ]);
            // eslint-disable-next-line no-console
            Object.assign(usedHeaders, headers);
            // send subRequest with conflicting header in next batch
            if (0 === conflictingKeys.length) accumulator.push(gqlSubRequest);
            return accumulator;
        }, []);
        if (doExtractBatch) this.update({
            mutations: this.mutations.filter((gqlSubRequest)=>!gqlSubRequests.includes(gqlSubRequest)),
            queries: this.queries.filter((gqlSubRequest)=>!gqlSubRequests.includes(gqlSubRequest))
        });
        const batch = new GqlBatchedRequest();
        for (const gqlSubRequest of gqlSubRequests)batch.addSubRequest(gqlSubRequest);
        if (batchTimeout) batch.setTimeout(batchTimeout);
        return batch;
    }
    getOldestItem() {
        const item = this.getSortedGqlSubRequests()[0];
        return item;
    }
    getNewestItem() {
        const item = this.getSortedGqlSubRequests().pop();
        return item;
    }
    getMinCreated() {
        const item = this.getSortedGqlSubRequests()[0];
        return item.created;
    }
    getMaxCreated() {
        const item = this.getSortedGqlSubRequests().pop();
        return item.created;
    }
    addSubRequest(gqlSubRequest) {
        this.isLocked, gqlSubRequest.getName();
        this.getGqlSubRequests().includes(gqlSubRequest), gqlSubRequest.getName();
        if (gqlSubRequest.mutation) this.update({
            mutations: [
                ...this.mutations,
                gqlSubRequest
            ]
        });
        else this.update({
            queries: [
                ...this.queries,
                gqlSubRequest
            ]
        });
    }
    findCached(gqlSubRequest) {
        if (gqlSubRequest.mutation) return this.mutations.find((cachedItem)=>cachedItem.getCacheKey() === gqlSubRequest.getCacheKey());
        return this.queries.find((cachedItem)=>cachedItem.getCacheKey() === gqlSubRequest.getCacheKey());
    }
    size() {
        return this.queries.length + this.mutations.length;
    }
    getHeaders() {
        return this.getSortedGqlSubRequests().reduce((accumulator, gqlSubRequest)=>{
            const headers = gqlSubRequest.getHeaders();
            // eslint-disable-next-line no-console
            return {
                ...accumulator,
                ...headers
            };
        }, {});
    }
    setTimeout(timeout) {
        this.timeout = timeout;
    }
    getTimeout() {
        return this.timeout;
    }
    constructor(){
        _define_property(this, "mutations", []);
        _define_property(this, "queries", []);
        _define_property(this, "created", Date.now());
        _define_property(this, "id", GqlBatchedRequest.createId());
        _define_property(this, "isLocked", false);
        _define_property(this, "timeout", void 0);
    }
};
_define_property(GqlBatchedRequest, "autoIncrement", 0);
export { GqlBatchedRequest as default };
