import { getRootSettingsInternational } from '@leon-hub/api-sdk';
import { QueryParameterGroupName } from '@leon-hub/query-manager';
import { useQueryManager } from 'web/src/modules/core/composables';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
const makeRootSettingsRequest = (silent)=>{
    const queryManager = useQueryManager();
    const apiClient = useGraphqlClient();
    "1";
    return getRootSettingsInternational(apiClient, (node)=>node.queries.system.rootSettings, {
        options: {}
    }, {
        headers: silent ? void 0 : queryManager.getHeaderParameters({
            group: [
                QueryParameterGroupName.AFFILIATE,
                QueryParameterGroupName.MARKETING
            ]
        }),
        silent
    });
};
export default makeRootSettingsRequest;
