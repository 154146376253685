import { _ as _define_property } from "@swc/helpers/_/_define_property";
import { CustomError } from 'ts-custom-error';
import { getBootstrapTranslations } from '@leon-hub/bootstrap-translations';
let AbstractError = class AbstractError extends CustomError {
    addLogMetaData(key, value) {
        this.metaData[key] = value;
    }
    setLogLevel(logLevel) {
        this.logLevel = logLevel;
    }
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    toJSON() {
        return this.getSerializable();
    }
    toString() {
        // TODO: skip class name minification for errors
        const defaultMessage = `${this.name}: ${this.message}`;
        return defaultMessage;
    }
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    getSerializable() {
        return {
            name: this.name,
            message: this.message,
            code: this.code.toString(),
            meta: this.metaData,
            stack: this.stack,
            silent: this.silent,
            originalError: this.originalError,
            payload: this.payload
        };
    }
    clone() {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        return Object.create(Object.getPrototypeOf(this), Object.getOwnPropertyDescriptors(this));
    }
    constructor(options){
        super(options.message ?? options.cause?.message ?? getBootstrapTranslations().WEB2_TECHNICAL_ERROR);
        _define_property(this, "silent", void 0);
        _define_property(this, "logLevel", 'ERROR');
        _define_property(this, "code", void 0);
        _define_property(this, "payload", void 0);
        _define_property(this, "metaData", {});
        _define_property(this, "originalError", void 0);
        _define_property(this, "cause", void 0);
        const { code, log, silent = false, originalError, logLevel, payload, cause } = {
            ...options
        };
        if (cause) this.cause = cause;
        if (log) this.addLogMetaData('log', log);
        if (logLevel) this.logLevel = logLevel;
        this.code = code;
        this.silent = silent;
        this.payload = payload;
        if (originalError) {
            Error.captureStackTrace?.(this, this.constructor);
            this.originalError = originalError instanceof AbstractError ? originalError : Object.assign(originalError, {
                toJSON () {
                    return {
                        name: originalError.name,
                        message: originalError.message
                    };
                }
            });
        }
    }
};
export { AbstractError as default };
