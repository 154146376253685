import { _ as _define_property } from "@swc/helpers/_/_define_property";
import { _ as _ts_decorate } from "@swc/helpers/_/_ts_decorate";
import { _ as _ts_metadata } from "@swc/helpers/_/_ts_metadata";
/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */ // @ts-ignore
import { getBuiltinConfig } from '@leon-hub/service-locator-env';
import { Debounce } from '@leon-hub/utils';
import { doFetch } from '@leon-hub/fetch-client';
import { logger } from '@leon-hub/logging';
import { isArrayOf, isArrayOfStrings, isNumber, isObject, isOptionalString, isString, isValidObject } from '@leon-hub/guards';
import { AccessDeniedRemoteApiExceptionCode, G2SVRequiredExceptionCode, RemoteApiErrorExceptionCode, RequestOptionsPriority, SessionExceptionCode } from '@leon-hub/api-sdk';
import { normalizeError } from '@leon-hub/errors';
import GqlApiPromotionNotFoundError from './errors/GqlApiPromotionNotFoundError';
import GqlApiBatchUnknownHashError from './errors/GqlApiBatchUnknownHashError';
import GqlApiBatchHashingDisabledError from './errors/GqlApiBatchHashingDisabledError';
import BaseClient from '../BaseClient';
import getBaseHeaders from '../getBaseHeaders';
import getResponseDescription from './utils/getResponseDescription';
import GqlApiG2svRequiredError from './errors/GqlApiG2svRequiredError';
import { ApiServiceUnavailableError } from '../errors/ApiServiceUnavailableError';
import { ApiTechnicalError } from '../errors/ApiTechnicalError';
import { ApiConnectionError } from '../errors/ApiConnectionError';
import GqlApiBatchedSubRequestClientError from './errors/GqlApiBatchedSubRequestClientError';
import { GqlApiAccessDeniedError } from './errors/GqlApiAccessDeniedError';
import getRequestDescription from './utils/getRequestDescription';
import resolveApi1Url from '../../helpers/resolveApi1Url';
import GqlApiResponseErrorCode from './errors/GqlApiResponseErrorCode';
import GqlApiBatchedSubRequestError from './errors/GqlApiBatchedSubRequestError';
import GqlApiErrorCode from './errors/GqlApiErrorCode';
import GqlApiBatchMaxSizeError from './errors/GqlApiBatchMaxSizeError';
import GqlApiBatchError from './errors/GqlApiBatchError';
import GqlBatchedRequest from './GqlBatchedRequest';
import GqlBatchedSubRequest from './GqlBatchedSubRequest';
import { getDefaultSettings } from '../../settings';
import GqlApiCaptchaRequiredError from './errors/GqlApiCaptchaRequiredError';
import convertToGqlApiError from './errors/convertToGqlApiError';
import { ApiErrorCode } from '../errors/ApiErrorCode';
import { ApiIpBlockedError } from '../errors/ApiIpBlockedError';
import { GqlApiServiceSuspendedError } from './errors/GqlApiServiceSuspendedError';
import { GqlApiCustomerHistoryLimitExceededError } from './errors/GqlApiCustomerHistoryLimitExceededError';
import { getUrl, cleanGqlFragmentDuplication } from './utils';
function isServerApiResponseBaseError(errorData) {
    return isObject(errorData) && isString(errorData.errorCode) && isOptionalString(errorData.message);
}
function isServerApiErrorResponse(errorData) {
    return isServerApiResponseBaseError(errorData);
}
function createLogNamespace(namespace) {
    return logger.createNamespace(namespace);
}
/**
 * @TODO: Change strategy from white list to black list. Potentially any new error can stop app loading on startup.
 * @Deprecated
 */ function isRetryableApiError(error, isMutation) {
    if (error instanceof ApiConnectionError || error instanceof GqlApiBatchUnknownHashError || error instanceof GqlApiBatchHashingDisabledError) return true;
    return !isMutation && (error instanceof ApiTechnicalError || error instanceof ApiServiceUnavailableError);
}
function isIpBlockedError(errorData) {
    return isServerApiErrorResponse(errorData) && ApiErrorCode.API_IP_BLOCKED_ERROR.equals(errorData.errorCode);
}
function isServerApiUnknownHashError(error) {
    return isServerApiErrorResponse(error) && ApiErrorCode.API_UNKNOWN_HASH.equals(error.errorCode) && isString(error.requestId);
}
function isServerApiHashingDisabled(error) {
    return isServerApiErrorResponse(error) && ApiErrorCode.API_REQUEST_HASHING_IS_DISABLED.equals(error.errorCode);
}
function isServerApiResponseInvalidBatchSizeError(errorData) {
    return isServerApiErrorResponse(errorData) && 'INVALID_REQUESTS_NUMBER' === errorData.errorCode && isNumber(errorData.maxSize);
}
// TODO: solve this problem with another approach after LEONWEB-3829, LEONAPI-560
function isServerApiSubResponseError(item) {
    return isObject(item) && isOptionalString(item.message) && (void 0 === item.path || isArrayOfStrings(item.path)) && isObject(item.extensions) && isString(item.extensions.errorCode) && isOptionalString(item.extensions.classification) && isOptionalString(item.extensions.message) && (void 0 === item.locations || Array.isArray(item.locations) && item.locations.every((loc)=>isValidObject(loc, {
            line: [
                isNumber
            ],
            column: [
                isNumber
            ]
        })));
}
function isServerApiFailedSubResponse(value) {
    return isObject(value) && // TODO: ValidationError will skip data field, but it's required by interface.
    (null === value.data || void 0 === value.data) && Array.isArray(value.errors) && isArrayOf(isServerApiSubResponseError, value.errors);
}
function isServerApiSubResponseMutationResultNode(item) {
    return isObject(item) && void 0 !== item.data;
}
function isServerApiSubResponseQueryResultNode(item) {
    return isObject(item) && void 0 !== item.data && isNumber(item.ts);
}
function isServerApiSubResponseResultNode(value) {
    return isServerApiSubResponseQueryResultNode(value) || isServerApiSubResponseMutationResultNode(value);
}
function getCommonHeaders() {
    return {
        'content-type': 'application/json'
    };
}
function isServerApiOkSubResponse(value) {
    return isObject(value) && isObject(value.data);
}
function isServerApiSubResponse(value) {
    return isServerApiOkSubResponse(value) || isServerApiFailedSubResponse(value);
}
function isServerApiBatchedResponse(argument) {
    return isObject(argument) && Object.entries(argument).every((param)=>{
        let [, item] = param;
        return isServerApiSubResponse(item);
    });
}
let GqlBatchedClient = class GqlBatchedClient extends BaseClient {
    setupSettings(settings) {
        this.settings = {
            ...this.settings,
            ...settings
        };
    }
    setMaxMutationsPerBatch(count) {
        this.maxMutationsPerBatch = count;
    }
    setMaxBatchQueueSize(size) {
        this.settings.maxBatchQueueSize = size;
        this.debug('max batch queue size has been changed to %s', size);
        this.updateTimer();
    }
    isQueryHashingEnabled() {
        return this.isQueryHashingStateEnabled && getBuiltinConfig().isQueryHashingEnabled;
    }
    toggleQueryHashingState(state) {
        this.isQueryHashingStateEnabled = state;
    }
    getMaxAccumTime() {
        return this.settings.maxAccumTime ?? this.defaultMaxAccumTime;
    }
    getMinAccumTime() {
        return this.settings.minAccumTime ?? this.defaultMinAccumTime;
    }
    get maxBatchQueueSize() {
        return this.settings.maxBatchQueueSize ?? 0;
    }
    get requestTimeout() {
        return this.settings.requestTimeout || 16000;
    }
    // 15 sec server timeout + 1000ms network timeout
    get maxRequestRetriesCount() {
        return this.settings.maxRequestRetriesCount || 1;
    }
    logError(error) {
        if (!this.settings.silentErrors) this.logger.error(error);
    }
    getSentBatchesCount() {
        return this.sentBatches.length;
    }
    getAllBatches() {
        return [
            this.pendingBatch,
            ...this.sentBatches
        ];
    }
    findSameGqlSubRequest(item) {
        const allBatches = this.getAllBatches();
        for(let index = 0, { length } = allBatches; index < length; index += 1){
            const cachedItem = allBatches[index].findCached(item);
            if (cachedItem) return cachedItem;
        }
    }
    /**
   * Update timer immediately, and throttle next calls for 10ms(on each call), but 20ms max, then call again.
   * @private
   */ updateTimer() {
        const log = this.debug.extend('timer');
        log('has been called');
        if (this.isMutationInProcess()) log('mutation is in process');
        else {
            if (this.nextCheckTimer) {
                log('resetting previous timer');
                clearInterval(this.nextCheckTimer);
                this.nextCheckTimer = void 0;
            }
            const nextBatchSize = this.getNextBatch().size();
            if (0 === nextBatchSize) {
                log('nothing to do');
                return;
            }
            const { maxBatchQueueSize } = this;
            const isFilledBatch = nextBatchSize === maxBatchQueueSize;
            const now = Date.now();
            const nextCallTimestamp = isFilledBatch ? now : Math.max(now, // Batch default close time.
            Math.min(this.pendingBatch.getNewestItem().created + this.getMaxAccumTime(), this.pendingBatch.getOldestItem().created + this.getMinAccumTime()));
            const timeout = nextCallTimestamp - now;
            log(`set timer for ${timeout}ms`);
            this.nextCheckTimer = setTimeout(()=>{
                this.nextCheckTimer = void 0;
                log('starting routine');
                // eslint-disable-next-line promise/catch-or-return
                this.handlePendingBatch().then(()=>{
                    const hasTimer = !!this.nextCheckTimer;
                    // eslint-disable-next-line promise/always-return
                    if (!hasTimer && // Next batch is pending.
                    this.getNextBatch().size() > 0) this.updateTimer();
                }, (error)=>{
                    this.logError(convertToGqlApiError(error, 'handlePendingBatch error'));
                });
            }, timeout);
        }
    }
    async handlePendingBatch() {
        this.debug('prepare to handle next batch');
        const nextBatch = this.getNextBatch({
            doExtractBatch: true
        });
        this.debug(`next batch size=${nextBatch.size()}`);
        if (nextBatch.size() > 0) {
            const log = this.debug.extend(`batch#${nextBatch.id}`);
            log(`mutations=${nextBatch.mutations.length}, queries=${nextBatch.queries.length}`);
            this.sentBatches.push(nextBatch);
            log(`added to sentBatches(cur size=${this.sentBatches.length})`);
            try {
                log('before batched request');
                await this.doBatchedRequest(nextBatch);
            } finally{
                this.removeBatchFromSent(nextBatch);
                log(`rm batch#${nextBatch.id} from sentBatches(cur size=${this.sentBatches.length})`);
            }
        } else this.debug('unable to handle empty pending batch');
    }
    removeBatchFromSent(batch) {
        this.sentBatches = this.sentBatches.filter((sentBatch)=>sentBatch !== batch);
    }
    async requestGraphql(query, param, callback, requestOptions, /** @Deprecated id use `requestOptions.id` */ id) {
        let { options } = param;
        const subRequest = new GqlBatchedSubRequest({
            content: cleanGqlFragmentDuplication(query),
            options,
            resolver: callback,
            id: requestOptions?.id ?? id,
            silent: requestOptions?.silent,
            headers: requestOptions?.headers,
            priority: requestOptions?.priority ?? RequestOptionsPriority.NORMAL,
            timeout: requestOptions?.timeout,
            retry: requestOptions?.retry,
            group: requestOptions?.group
        });
        const log = this.debug.extend(`request:${subRequest.getName()}`);
        log('created');
        // if same request is already active, do not create new request.
        const sameGqlSubRequest = this.findSameGqlSubRequest(subRequest);
        if (sameGqlSubRequest) {
            log('is already processing');
            return sameGqlSubRequest.promise;
        }
        // if cache exists, then set cached `ts`, so server can tell client to reuse cached response.
        const cachedResponseNode = this.getFromCache(subRequest.getCacheKey());
        if (cachedResponseNode) subRequest.setCacheTimestamp(cachedResponseNode.ts);
        log('push to the pending batch');
        this.pendingBatch.addSubRequest(subRequest);
        this.updateTimer();
        try {
            return await subRequest.deferred.promise;
        } catch (error) {
            let apiError = convertToGqlApiError(error, this.bootstrapTranslations.WEB2_TECHNICAL_ERROR);
            const hasError = this.errorsCache.has(apiError);
            if (hasError) apiError = apiError.clone();
            else this.errorsCache.add(apiError);
            apiError.setOperationName(subRequest.getOperationName());
            throw Object.assign(apiError, {
                silent: subRequest.silent
            });
        }
    }
    saveToCache(cacheKey, node) {
        return this.responseNodeCache?.set(cacheKey, JSON.parse(JSON.stringify(node)));
    }
    removeCache(cacheKey) {
        this.responseNodeCache?.remove(cacheKey);
    }
    getFromCache(cacheKey) {
        return this.responseNodeCache?.get(cacheKey);
    }
    getRequestData(batch) {
        const requests = [];
        for (const item of [
            ...batch.mutations,
            ...batch.queries
        ]){
            let ts = item.getCacheTimestamp();
            const cacheItem = this.getFromCache(item.getCacheKey());
            if (cacheItem?.ts) ts = cacheItem.ts;
            const requestItem = {
                id: item.id,
                query: this.isQueryHashingEnabled() ? void 0 : item.getContent(),
                qKey: this.isQueryHashingEnabled() ? item.getContentHash() : void 0,
                operationName: item.getOperationName(),
                variables: {
                    options: item.mutation ? item.options : {
                        ...item.options,
                        // Client cache timestamp, to tell server.
                        ts
                    }
                }
            };
            requests.push(requestItem);
        }
        return requests;
    }
    failBatchedRequest(batch, batchApiError) {
        const log = this.debug.extend(`batch#${batch.id}`);
        log('has been failed');
        for (const gqlSubRequest of batch.getSortedGqlSubRequests())// Handle failed batch in the end of process queue.
        // TODO: required to rm batch with response from sentBatches.
        Promise.resolve().then(()=>{
            this.handleFailedBatchedSubRequest(gqlSubRequest, batchApiError);
        });
    }
    // TODO: replace to service
    // eslint-disable-next-line consistent-return,sonarjs/cognitive-complexity
    async doBatchedRequest(batch) {
        const log = this.debug.extend(`batch#${batch.id}`);
        log('preparing request data...');
        const requestData = this.getRequestData(batch);
        try {
            log(`
* requests count: ${requestData.length}
* requests names: ${batch.getSortedGqlSubRequests().map((item)=>item.getName()).join(', ')}
* headers: ${JSON.stringify(batch.getHeaders())}`);
            log('request start');
            const controller = new AbortController();
            this.abortSignals.set(batch, controller);
            const responseData = await this.request({
                abortController: controller,
                endpoint: '/',
                data: requestData,
                headers: batch.getHeaders(),
                timeout: batch.getTimeout(),
                query: {
                    ops: batch.getGqlSubRequests().map((req)=>req.getOperationName()).join(',')
                }
            });
            log('request end');
            if (isServerApiBatchedResponse(responseData)) {
                log('response is ok');
                try {
                    this.handleResponseData(batch, responseData);
                } catch (error) {
                    this.failBatchedRequest(batch, convertToGqlApiError(error, this.bootstrapTranslations.WEB2_TECHNICAL_ERROR));
                }
            } else if (isServerApiErrorResponse(responseData)) {
                log('server api error');
                if (isServerApiHashingDisabled(responseData) || isServerApiUnknownHashError(responseData)) {
                    this.toggleQueryHashingState(false);
                    const err = isServerApiUnknownHashError(responseData) ? new GqlApiBatchUnknownHashError({
                        requestId: responseData.requestId,
                        batch
                    }) : new GqlApiBatchHashingDisabledError({
                        message: responseData.message
                    });
                    this.failBatchedRequest(batch, err);
                } else if (isServerApiResponseInvalidBatchSizeError(responseData)) {
                    this.setMaxBatchQueueSize(responseData.maxSize);
                    this.failBatchedRequest(batch, new GqlApiBatchMaxSizeError({
                        batchMaxSize: this.maxBatchQueueSize,
                        batch
                    }));
                } else if (isIpBlockedError(responseData)) this.failBatchedRequest(batch, new ApiIpBlockedError());
                else this.failBatchedRequest(batch, new GqlApiBatchError({
                    code: GqlApiErrorCode.API_TECHNICAL_ERROR,
                    batch
                }));
            } else {
                log('unexpected api resp format');
                this.failBatchedRequest(batch, new GqlApiBatchError({
                    batch
                }));
            }
        } catch (error) {
            this.removeBatchFromSent(batch);
            log(`rm batch#${batch.id} from sentBatches(cur size=${this.sentBatches.length})`);
            this.failBatchedRequest(batch, convertToGqlApiError(error, this.bootstrapTranslations.WEB2_TECHNICAL_ERROR));
        } finally{
            this.abortSignals.delete(batch);
        }
    }
    handleBatchedSubRequest(subRequest, batch, responseItem) {
        const log = this.debug.extend('sub-req').extend(subRequest.getName());
        log('handling…');
        let responseNode;
        let responseNodeTs = 0;
        try {
            responseNode = subRequest.resolver(responseItem.data);
            responseNodeTs = responseNode.ts;
            isServerApiSubResponseResultNode(responseNode);
        } catch (rawError) {
            this.handleFailedBatchedSubRequest(subRequest, new GqlApiBatchedSubRequestClientError({
                batch,
                subRequest,
                responseItem,
                originalError: normalizeError(rawError)
            }));
            return;
        }
        if (!subRequest.mutation) {
            log('checking cache…');
            const isCacheUsed = responseNodeTs > 0;
            const cacheKey = subRequest.getCacheKey();
            const isCacheRequiredByServer = isCacheUsed && null === responseNode.data && subRequest.getCacheTimestamp() === responseNodeTs;
            if (isCacheRequiredByServer) {
                log(`cache(ts=${responseNodeTs}) is required by server`);
                const cachedNode = this.getFromCache(cacheKey);
                if (!cachedNode) {
                    // Cache miss.
                    log(`cache(ts=${responseNodeTs}) is missing`);
                    this.removeCache(cacheKey);
                    // Repeat request without client cache ts to force server to generate new resp.
                    subRequest.resetCacheTimestamp();
                    log('cache ts has been reset to 0');
                    this.pendingBatch.addSubRequest(subRequest);
                    this.updateTimer();
                    return;
                }
                // Cache hit. Update response with data copy.
                Object.assign(responseNode, {
                    data: JSON.parse(JSON.stringify(cachedNode.data))
                });
            } else if (isCacheUsed) {
                log('creating cache…');
                this.saveToCache(cacheKey, responseNode);
            }
        }
        log('resolving…');
        subRequest.deferred.resolve(responseNode.data);
    }
    handleResponseData(batch, subRequestResponses) {
        const log = this.debug.extend(`batch#${batch.id}`);
        log('resp is handling');
        const sortedList = batch.getSortedGqlSubRequests();
        const successCount = sortedList.reduce((accumulator, subRequest)=>{
            const subRequestResponse = subRequestResponses[subRequest.id];
            if (isServerApiFailedSubResponse(subRequestResponse)) {
                log(`failed response for ${subRequest.getName()}`);
                const { errors: [error] } = subRequestResponse;
                error.extensions;
                // TODO: refactor after error types will be generated within sdk ()
                if (error.extensions?.classification === 'ValidationError') {
                    // TODO: server bug(no error code), remove assert.
                    error.extensions.errorCode;
                    Object.assign(error.extensions, {
                        errorCode: GqlApiResponseErrorCode.GQL_API_SERVICE_VALIDATION_ERROR
                    });
                }
                const errorCode = error.extensions.errorCode || GqlApiResponseErrorCode.API_TECHNICAL_ERROR;
                isString(errorCode);
                const message = error.extensions.message || this.bootstrapTranslations.WEB2_TECHNICAL_ERROR;
                isString(message);
                if ('INVALID_CODE' === errorCode) {
                    isNumber(error.extensions.code);
                    if ([
                        502,
                        503
                    ].includes(error.extensions.code)) this.handleFailedBatchedSubRequest(subRequest, new ApiServiceUnavailableError());
                    else this.handleFailedBatchedSubRequest(subRequest, new ApiTechnicalError());
                    return accumulator;
                }
                let GqlApiErrorConstructor;
                switch(errorCode){
                    case G2SVRequiredExceptionCode.G2SV_REQUIRED:
                        GqlApiErrorConstructor = GqlApiG2svRequiredError;
                        break;
                    case RemoteApiErrorExceptionCode.INVALID_CAPTCHA:
                    case RemoteApiErrorExceptionCode.CAPTCHA_NEEDED:
                        GqlApiErrorConstructor = GqlApiCaptchaRequiredError;
                        break;
                    case RemoteApiErrorExceptionCode.SERVICE_SUSPENDED:
                        GqlApiErrorConstructor = GqlApiServiceSuspendedError;
                        break;
                    case RemoteApiErrorExceptionCode.PROMOTION_NOT_FOUND:
                        GqlApiErrorConstructor = GqlApiPromotionNotFoundError;
                        break;
                    case RemoteApiErrorExceptionCode.CUSTOMER_HISTORY_LIMIT_EXCEEDED:
                        GqlApiErrorConstructor = GqlApiCustomerHistoryLimitExceededError;
                        break;
                    case AccessDeniedRemoteApiExceptionCode.ACCESS_DENIED:
                    case SessionExceptionCode.SESSION:
                        GqlApiErrorConstructor = GqlApiAccessDeniedError;
                        break;
                    default:
                        GqlApiErrorConstructor = GqlApiBatchedSubRequestError;
                        break;
                }
                const apiError = new GqlApiErrorConstructor({
                    message,
                    batch,
                    subRequest,
                    // TODO: Refactor after LEONAPI-552
                    extensions: {
                        ...error.extensions,
                        errorCode,
                        message
                    }
                });
                this.handleFailedBatchedSubRequest(subRequest, apiError);
            } else if (isServerApiSubResponseResultNode(subRequestResponse)) {
                log(`response for ${subRequest.getName()} is successful`);
                this.handleBatchedSubRequest(subRequest, batch, subRequestResponse);
                return accumulator + 1;
            } else {
                log('response is invalid');
                this.handleFailedBatchedSubRequest(subRequest, new GqlApiBatchError({
                    batch,
                    subRequest
                }));
            }
            return accumulator;
        }, 0);
        log(`response has been handled (success: ${successCount}, errors: ${sortedList.length - successCount})`);
    }
    async getResponse(url, options) {
        let $return = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {
            request: void 0
        }, controller = arguments.length > 3 ? arguments[3] : void 0, timeout = arguments.length > 4 ? arguments[4] : void 0;
        return doFetch(url, options, timeout ?? this.requestTimeout, (originalError, request)=>new ApiConnectionError({
                request,
                originalError
            }), controller, (request)=>{
            Object.assign($return, {
                request
            });
        });
    }
    async request(options) {
        const { endpoint, data, headers, abortController, timeout, query = {} } = options;
        const fullUrl = `${this.getOrigin()}${this.getBaseUrl()}${endpoint}`.replace(/\/+$/, '');
        const requestInit = {
            body: null,
            headers: this.getHeaders(headers),
            method: this.getDefaultMethod(),
            credentials: this.getCredentials()
        };
        try {
            requestInit.body = JSON.stringify(data);
            const $return = {
                request: void 0
            };
            const url = getUrl(fullUrl, query);
            const response = await this.getResponse(url, requestInit, $return, abortController, timeout);
            const { request } = $return;
            const clonedResponse = process.env.VUE_APP_PRERENDER ? response.clone() : void 0;
            try {
                // Allow to handle outside success or failed batch with error data.
                // eslint-disable-next-line @typescript-eslint/return-await
                return await response.json();
            } catch (error) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                const apiError = [
                    502,
                    503
                ].includes(response.status) ? new ApiServiceUnavailableError({
                    request
                }) : new ApiTechnicalError({
                    originalError: error,
                    request
                });
                if (request) apiError.addLogMetaData('request', Object.assign(response, {
                    toJSON: ()=>getRequestDescription(request)
                }));
                apiError.addLogMetaData('response', Object.assign(response, {
                    toJSON: ()=>getResponseDescription(response)
                }));
                if (process.env.VUE_APP_PRERENDER) {
                    // eslint-disable-next-line no-console
                    console.info(`[Graphql] Parse response error - status: ${response.status}`, error);
                    try {
                        const responseText = await clonedResponse?.text();
                        // eslint-disable-next-line no-console
                        console.info(`[Graphql] Response text ${responseText}`);
                    } catch (bodyParseError) {
                        // eslint-disable-next-line no-console
                        console.info('[Graphql] Body parse error', bodyParseError);
                    }
                }
                return Promise.reject(apiError);
            }
        } catch (error) {
            if (process.env.VUE_APP_PRERENDER) // eslint-disable-next-line no-console
            console.info('[Graphql] Request error', error);
            throw convertToGqlApiError(error, this.bootstrapTranslations.WEB2_TECHNICAL_ERROR);
        }
    }
    abortBatch(batch) {
        const controller = this.abortSignals.get(batch);
        const batchName = `batch#${batch.id}`;
        const log = this.debug.extend(batchName);
        log('cancelling the request…');
        controller.abort();
        log('done');
    }
    clearQueue() {
        for (const batch of this.sentBatches)this.abortBatch(batch);
        this.pendingBatch = new GqlBatchedRequest();
    }
    setAccumTime(n) {
        n >= 0 && Number.isFinite(n);
        this.settings.maxAccumTime = n;
    }
    resetAccumTime() {
        this.setAccumTime(this.defaultMaxAccumTime);
    }
    isMutationInProcess() {
        return this.sentBatches.slice(0).some((batch)=>batch.mutations.length > 0);
    }
    async whenAllSentMutationsProcessed() {
        await Promise.all(this.sentBatches.filter((batch)=>batch.mutations.length > 0).map((batch)=>batch.whenFulfilled()));
    }
    getNextBatch(options) {
        const doExtractBatch = options?.doExtractBatch ?? false;
        const size = options?.size ?? this.maxBatchQueueSize;
        const firstSentBatch = this.sentBatches[0];
        this.isMutationInProcess();
        const sentBatchHasMutation = !!firstSentBatch?.mutations.length;
        let { maxMutationsPerBatch: leftMutations } = this;
        let firstGqlRequestInQueue;
        return this.pendingBatch.getBatch({
            size,
            doExtractBatch,
            filter: (gqlRequest)=>{
                if (gqlRequest.mutation) {
                    // Block next batch with mutation if mutation is already processing by server.
                    // TODO: add option to send non-blocking requests
                    if (sentBatchHasMutation || leftMutations <= 0) return false;
                    leftMutations -= 1;
                }
                if (!firstGqlRequestInQueue) {
                    firstGqlRequestInQueue = gqlRequest;
                    return true;
                }
                return firstGqlRequestInQueue.getGroup() === gqlRequest.getGroup();
            }
        });
    }
    /**
   * Flush pending requests. In parallel mode, we handle all pending batches at once.
   * IN serial mode, we handle available batches one by one.
   *
   * @param inParallel
   */ async flush() {
        let { inParallel = false } = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
        this.debug('flush');
        // send flushable requests first even if in the process other requests are added
        const gqlRequests = this.pendingBatch.getGqlSubRequests();
        for (const gqlRequest of gqlRequests)gqlRequest.setPriority(RequestOptionsPriority.HIGH);
        await this.whenAllSentMutationsProcessed();
        if (inParallel) {
            const promises = [];
            do promises.push(this.handlePendingBatch());
            while (this.getNextBatch({
                doExtractBatch: false
            }).size() > 0);
            await Promise.allSettled(promises);
            return Promise.resolve();
        }
        await Promise.allSettled([
            this.handlePendingBatch()
        ]);
        const nextBatch = this.getNextBatch({
            doExtractBatch: false
        });
        return nextBatch.size() > 0 ? this.flush() : Promise.resolve();
    }
    handleFailedBatchedSubRequest(item, error) {
        const log = this.debug.extend(`request:${item.getName()}`);
        const failedAttempts = item.getFailedAttempts();
        const maxRequestRetriesCount = item.getRetry() ?? this.maxRequestRetriesCount;
        log(`retries (${failedAttempts}/${maxRequestRetriesCount})`);
        if (isRetryableApiError(error, item.mutation) && failedAttempts < maxRequestRetriesCount) {
            item.incrementFailedAttempts();
            log('retry#%s', ()=>item.getFailedAttempts());
            this.pendingBatch.addSubRequest(item);
            this.updateTimer();
        } else {
            // Reset cache for failed request.
            this.removeCache(item.getCacheKey());
            log('BatchedSubRequest failed');
            error.addLogMetaData('retries', failedAttempts);
            item.deferred.reject(error);
        }
    }
    constructor(options = {}){
        super({
            baseUrl: resolveApi1Url(),
            method: 'POST',
            ...options,
            headers: {
                ...getCommonHeaders(),
                ...getBaseHeaders(),
                ...options?.headers
            }
        });
        _define_property(this, "logger", createLogNamespace('BatchedGraphqlClient'));
        _define_property(this, "responseNodeCache", null);
        _define_property(this, "settings", getDefaultSettings());
        _define_property(this, "defaultMaxAccumTime", 40);
        _define_property(this, "defaultMinAccumTime", 20);
        _define_property(this, "requestNumber", 1);
        _define_property(this, "maxMutationsPerBatch", 1);
        _define_property(this, "abortSignals", new WeakMap());
        _define_property(this, "isQueryHashingStateEnabled", true);
        _define_property(this, "pendingBatch", new GqlBatchedRequest());
        // sentBatches structure:
        // Array of:
        // * single optional batch with at least one mutation(s)
        // * zero to infinite "query only" batches.
        _define_property(this, "sentBatches", []);
        _define_property(this, "nextCheckTimer", void 0);
        _define_property(this, "errorsCache", new WeakSet());
    }
};
export { GqlBatchedClient as default };
_ts_decorate([
    Debounce({
        wait: 5,
        maxWait: 10
    }),
    _ts_metadata("design:type", Function),
    _ts_metadata("design:paramtypes", []),
    _ts_metadata("design:returntype", void 0)
], GqlBatchedClient.prototype, "updateTimer", null);
