/* eslint-disable */ function print(value) {
    return value;
}
/** All built-in and custom scalars, mapped to their actual values */ /** Raw scalar type, can be string, boolean or else, cannot be a complex type */ /** Raw any type, can be any object */ /** transaction type name */ /** nullable field - item may not have a human readable name */ /** defines a concrete subtype */ /** defines used widget in JS */ /** ## resetShown */ /** transaction type name */ export const AccessDeniedRemoteApiExceptionCode = {
    ACCESS_DENIED: 'ACCESS_DENIED'
};
/** in some cases betEvent, betMarket and betOutcome can be null */ export const AccountHistoryBetType = {
    SINGLE: 'SINGLE',
    EXPRESS: 'EXPRESS',
    SYSTEM: 'SYSTEM'
};
export const AccountHistoryBonusCursorStatus = {
    CREATED: 'CREATED',
    ACTIVATED: 'ACTIVATED',
    RESULTED: 'RESULTED'
};
export const AccountHistoryCloseReason = {
    SUCCESSFUL: 'SUCCESSFUL',
    NO_BALANCE: 'NO_BALANCE',
    REFUSED: 'REFUSED',
    EXPIRED: 'EXPIRED',
    FREESPIN_LOST: 'FREESPIN_LOST',
    ERROR: 'ERROR'
};
export const AccountHistoryDataGroup = {
    CASINO: 'CASINO',
    MARKETING: 'MARKETING',
    PAYMENT: 'PAYMENT',
    SPORTSBOOK: 'SPORTSBOOK',
    VSPORT: 'VSPORT'
};
/** ### Enums ### */ export const AccountHistorySource = {
    CASINO: 'CASINO',
    STAFF: 'STAFF',
    CUSTOMER: 'CUSTOMER',
    SYSTEM: 'SYSTEM',
    EXTERNAL_SYSTEM: 'EXTERNAL_SYSTEM'
};
export const AccountHistoryStatus = {
    UNKNOWN: 'UNKNOWN',
    BET_PENDING: 'BET_PENDING',
    BET_WAITING: 'BET_WAITING',
    BET_CALCULATED: 'BET_CALCULATED',
    BET_CALCULATED_CHECKED: 'BET_CALCULATED_CHECKED',
    BET_CALCULATED_CASHOUT: 'BET_CALCULATED_CASHOUT',
    MARKETING_CREATED: 'MARKETING_CREATED',
    MARKETING_PENDING_ACTIVATION: 'MARKETING_PENDING_ACTIVATION',
    MARKETING_ACTIVE: 'MARKETING_ACTIVE',
    MARKETING_CLOSED: 'MARKETING_CLOSED',
    PAY_PROCESSING: 'PAY_PROCESSING',
    PAY_EXECUTED: 'PAY_EXECUTED',
    PAY_CANCELLED: 'PAY_CANCELLED',
    PAY_STARTED: 'PAY_STARTED',
    PAY_BAD_EXECUTED: 'PAY_BAD_EXECUTED',
    PAY_WAITING: 'PAY_WAITING',
    PAY_ROLLBACK: 'PAY_ROLLBACK',
    MGC_LOST: 'MGC_LOST',
    MGC_WON: 'MGC_WON',
    MGC_REFUND: 'MGC_REFUND',
    MGC_WON_2: 'MGC_WON_2',
    EXG_PERFORMED: 'EXG_PERFORMED',
    PAY_REGULATOR_CANCELLED: 'PAY_REGULATOR_CANCELLED',
    PAY_REGULATOR_ROLLBACK: 'PAY_REGULATOR_ROLLBACK',
    LEONSHOP_ORDER_CREATED: 'LEONSHOP_ORDER_CREATED',
    LEONSHOP_ORDER_IN_PROGRESS: 'LEONSHOP_ORDER_IN_PROGRESS',
    LEONSHOP_ORDER_DONE: 'LEONSHOP_ORDER_DONE',
    LEONSHOP_ORDER_CANCELLED: 'LEONSHOP_ORDER_CANCELLED'
};
export const AccountHistoryType = {
    ENCASH: 'ENCASH',
    LEONSHOP: 'LEONSHOP',
    CORRECTION: 'CORRECTION',
    RESET: 'RESET',
    LOYALTY_PROGRAM: 'LOYALTY_PROGRAM',
    CREDIT: 'CREDIT',
    DEBIT: 'DEBIT'
};
/** deprecated, use balance from finance */ /** requiredHeaders: X_APP_SKIN */ /** requiredHeaders: X_APP_SKIN */ /** requiredHeaders: X_APP_SKIN */ /** @deprecated use getHistoryItem insead of this one. requiredHeaders: X_APP_SKIN */ export const AccountType = {
    MAIN: 'MAIN',
    CASINO: 'CASINO',
    BETTING: 'BETTING',
    LOCKED: 'LOCKED'
};
/** numeric days or calendar months like m09/2020 */ /** Additional filtering by this code */ export const AffiliatePartnerStatus = {
    YES: 'YES',
    NO: 'NO',
    CLOSED: 'CLOSED',
    DISABLED: 'DISABLED'
};
export const AllAccountHistoryDataType = {
    /** Marketing(bonuses), Payment */ LOYALTY_BONUS: 'LOYALTY_BONUS',
    CASINO_PROVIDER_REWARD: 'CASINO_PROVIDER_REWARD',
    CASINO_BONUS_TO_MAIN_WALLET_ACTIVATED: 'CASINO_BONUS_TO_MAIN_WALLET_ACTIVATED',
    CASINO_BONUS_TRANSFERRED_TO_BONUS_WALLET: 'CASINO_BONUS_TRANSFERRED_TO_BONUS_WALLET',
    CASINO_BONUS_FAILED: 'CASINO_BONUS_FAILED',
    CASINO_FREESPIN: 'CASINO_FREESPIN',
    CASINO_FREESPIN_RESULT_TRANSFERRED_TO_BONUS_WALLET: 'CASINO_FREESPIN_RESULT_TRANSFERRED_TO_BONUS_WALLET',
    CASINO_BONUS_RESULT_TRANSFERRED_TO_MAIN_ACCOUNT: 'CASINO_BONUS_RESULT_TRANSFERRED_TO_MAIN_ACCOUNT',
    SPORT_BONUS_TRANSFERRED_TO_BONUS_WALLET: 'SPORT_BONUS_TRANSFERRED_TO_BONUS_WALLET',
    SPORT_BONUS_FAILED: 'SPORT_BONUS_FAILED',
    SPORT_BONUS_RESULT_TRANSFERRED_TO_MAIN_ACCOUNT: 'SPORT_BONUS_RESULT_TRANSFERRED_TO_MAIN_ACCOUNT',
    SPORT_FREEBET: 'SPORT_FREEBET',
    DEPOSIT: 'DEPOSIT',
    WITHDRAWAL: 'WITHDRAWAL',
    CORRECTION: 'CORRECTION',
    MANUAL_BS_DEPOSIT_BONUS: 'MANUAL_BS_DEPOSIT_BONUS',
    LEADERBOARD_CASH_REWARD: 'LEADERBOARD_CASH_REWARD',
    MARKETING_CASINO_BONUS_DEPOSIT: 'MARKETING_CASINO_BONUS_DEPOSIT',
    LOYALTY_BONUS_ENCASH: 'LOYALTY_BONUS_ENCASH',
    MARKETING_SPORT_BONUS_DEPOSIT: 'MARKETING_SPORT_BONUS_DEPOSIT',
    /** Bet */ SPORT_BET: 'SPORT_BET',
    FAST_BET: 'FAST_BET'
};
export const AllAccountHistoryOutcome = {
    WIN: 'WIN',
    WIN_HALF: 'WIN_HALF',
    LOSE_HALF: 'LOSE_HALF',
    LOSE: 'LOSE',
    RETURN: 'RETURN',
    UNDEFINED: 'UNDEFINED'
};
export const AllAccountHistoryStatus = {
    /** Bet, sportsbook, vsport */ PLACED: 'PLACED',
    WON: 'WON',
    LOST: 'LOST',
    CASHED_OUT: 'CASHED_OUT',
    VOIDED: 'VOIDED',
    /** Marketing(bonuses), payments */ PENDING: 'PENDING',
    EXECUTED: 'EXECUTED',
    CANCELLED: 'CANCELLED'
};
export const AllTransactionHistoryTransactionType = {
    BET: 'BET',
    WIN: 'WIN',
    LOSE: 'LOSE',
    ROLLBACK_WIN: 'ROLLBACK_WIN',
    ROLLBACK_BET: 'ROLLBACK_BET'
};
/** devIp obfuscated */ /** devMode obfuscated */ export const AppflowUpdateMethod = {
    NONE: 'NONE',
    AUTO: 'AUTO',
    BACKGROUND: 'BACKGROUND'
};
/** is not provided from LeonBets anymore, is left only for schema compatibility */ /** is not provided from LeonBets anymore, is left only for schema compatibility */ export const AvailableBonusType = {
    BONUS_AMOUNT: 'BONUS_AMOUNT',
    MAX_BONUS_AMOUNT: 'MAX_BONUS_AMOUNT',
    FREEBET: 'FREEBET',
    FREESPIN: 'FREESPIN'
};
export const BannerAnimation = {
    MOVE_HORIZONTALLY: 'MOVE_HORIZONTALLY'
};
export const BannerType = {
    PROMO: 'PROMO',
    SPORT: 'SPORT'
};
/** ## getOffer */ /** ## getBet */ /** transaction type name */ export const BetLine = {
    PREMATCH: 'PREMATCH',
    LIVE: 'LIVE',
    VSPORTS: 'VSPORTS',
    OUTRIGHT: 'OUTRIGHT'
};
export const BetRequestStatus = {
    PLACED: 'PLACED',
    WON: 'WON',
    LOST: 'LOST',
    VOIDED: 'VOIDED'
};
/** transaction type name */ export const BetType = {
    SINGLE: 'SINGLE',
    EXPRESS: 'EXPRESS',
    SYSTEM: 'SYSTEM'
};
export const BonusCampaignType = {
    SPORT: 'SPORT',
    EGS: 'EGS'
};
export const BonusCategory = {
    CBC: 'CBC',
    SBC: 'SBC',
    FIRST_DEPOSIT: 'FIRST_DEPOSIT',
    ACCUMULATIVE: 'ACCUMULATIVE',
    UNKNOWN: 'UNKNOWN'
};
export const BonusCodeType = {
    CAMPAIGN: 'CAMPAIGN',
    DEPOSIT: 'DEPOSIT',
    REGISTRATION: 'REGISTRATION'
};
export const BonusesDataType = {
    LOYALTY_BONUS: 'LOYALTY_BONUS',
    CASINO_PROVIDER_REWARD: 'CASINO_PROVIDER_REWARD',
    CASINO_BONUS_TO_MAIN_WALLET_ACTIVATED: 'CASINO_BONUS_TO_MAIN_WALLET_ACTIVATED',
    CASINO_BONUS_TRANSFERRED_TO_BONUS_WALLET: 'CASINO_BONUS_TRANSFERRED_TO_BONUS_WALLET',
    CASINO_BONUS_FAILED: 'CASINO_BONUS_FAILED',
    CASINO_FREESPIN: 'CASINO_FREESPIN',
    CASINO_FREESPIN_RESULT_TRANSFERRED_TO_BONUS_WALLET: 'CASINO_FREESPIN_RESULT_TRANSFERRED_TO_BONUS_WALLET',
    CASINO_BONUS_RESULT_TRANSFERRED_TO_MAIN_ACCOUNT: 'CASINO_BONUS_RESULT_TRANSFERRED_TO_MAIN_ACCOUNT',
    SPORT_BONUS_TRANSFERRED_TO_BONUS_WALLET: 'SPORT_BONUS_TRANSFERRED_TO_BONUS_WALLET',
    SPORT_BONUS_FAILED: 'SPORT_BONUS_FAILED',
    SPORT_BONUS_RESULT_TRANSFERRED_TO_MAIN_ACCOUNT: 'SPORT_BONUS_RESULT_TRANSFERRED_TO_MAIN_ACCOUNT',
    SPORT_FREEBET: 'SPORT_FREEBET'
};
/** 
 * ### BonusCursor ###
 * SportBonusFailedDetailsDto - SPORT_BONUS_FAILED
 * CasinoBonusFailedDetailsDto - CASINO_BONUS_FAILED
 **/ /** 
 *  dataType - Casino_bonus_failed
 *  dataType - Sport_bonus_failed
 *  dataGroup - Marketing
 * MarketingCasinoBonusFailedOverviewDto_All
 * MarketingSportBonusFailedOverviewDto_All
 **/ /** 
 *  ### Bonuses ###
 * MarketingSportBonusFailedOverviewDto_Marketing - SPORT_BONUS_FAILED
 * MarketingCasinoBonusFailedOverviewDto_Marketing - CASINO_BONUS_FAILED
 **/ export const BonusGameRewardType = {
    FREEBET: 'FREEBET',
    FREESPIN: 'FREESPIN',
    BONUS: 'BONUS',
    MULTIPLIER: 'MULTIPLIER',
    SUPER_PRIZE: 'SUPER_PRIZE',
    EMPTY: 'EMPTY'
};
export const BonusState = {
    ACTIVE: 'ACTIVE',
    SUCCESSFUL: 'SUCCESSFUL',
    SUCCESSFUL_WITH_ACTIVE_BONUS: 'SUCCESSFUL_WITH_ACTIVE_BONUS',
    CANCELLED: 'CANCELLED',
    EMPTY: 'EMPTY'
};
export const BonusStep = {
    REQUIREMENTS: 'REQUIREMENTS',
    WAGER: 'WAGER',
    FREEBET: 'FREEBET',
    FREESPIN: 'FREESPIN',
    TRANSFER: 'TRANSFER'
};
/** 
 * CasinoBonusResultTransferredToMainWalletDetailsDto - CASINO_BONUS_RESULT_TRANSFERRED_TO_MAIN_ACCOUNT
 * CasinoBonusTransferredToBonusWalletDetailsDto - CASINO_BONUS_TRANSFERRED_TO_BONUS_WALLET
 * CasinoFreespinResultTransferredToBonusWalletDetailsDto - CASINO_FREESPIN_RESULT_TRANSFERRED_TO_BONUS_WALLET
 * SportBonusResultTransferredToMainWalletDetailsDto - SPORT_BONUS_RESULT_TRANSFERRED_TO_MAIN_ACCOUNT
 * SportBonusTransferredToBonusWalletDetailsDto - SPORT_BONUS_TRANSFERRED_TO_BONUS_WALLET
 **/ /** 
 *  dataType - Casino_bonus_transferred_to_bonus_wallet
 *  dataType - Casino_bonus_result_transferred_to_main_wallet
 *  dataType - Sport_bonus_transferred_to_bonus_wallet
 *  dataType - Sport_bonus_result_transferred_to_main_wallet
 *  dataType - Casino_freespin_result_transferred_to_bonus_wallet
 *  dataGroup - Marketing
 * MarketingCasinoFreespinResultTransferredToBonusWalletOverviewDto_All
 * MarketingCasinoBonusTransferredToBonusWalletOverviewDto_All
 * MarketingCasinoBonusResultTransferredToMainWalletOverviewDto_All
 * MarketingSportBonusTransferredToBonusWalletOverviewDto_All
 * MarketingSportBonusResultTransferredToMainWalletOverviewDto_All
 * MarketingCasinoFreespinResultTransferredToBonusWalletOverviewDto_All
 **/ /** 
 * MarketingCasinoBonusResultTransferredToMainWalletOverviewDto_Marketing - CASINO_BONUS_RESULT_TRANSFERRED_TO_MAIN_ACCOUNT
 * MarketingCasinoBonusTransferredToBonusWalletOverviewDto_Marketing - CASINO_BONUS_TRANSFERRED_TO_BONUS_WALLET
 * MarketingSportBonusResultTransferredToMainWalletOverviewDto_Marketing - SPORT_BONUS_RESULT_TRANSFERRED_TO_MAIN_ACCOUNT
 * MarketingSportBonusTransferredToBonusWalletOverviewDto_Marketing - SPORT_BONUS_TRANSFERRED_TO_BONUS_WALLET
 * MarketingCasinoFreespinResultTransferredToBonusWalletOverviewDto_Marketing -
 * CASINO_FREESPIN_RESULT_TRANSFERRED_TO_BONUS_WALLET
 **/ export const CampaignState = {
    ACTIVE: 'ACTIVE',
    AVAILABLE: 'AVAILABLE',
    CLOSED: 'CLOSED'
};
/** deprecated */ export const CaptchaMode = {
    ENFORCED: 'ENFORCED',
    ON: 'ON',
    OFF: 'OFF'
};
export const CaptchaStatus = {
    ENFORCED: 'ENFORCED',
    ON: 'ON',
    OFF: 'OFF'
};
export const CaptchaType = {
    DISTORTED_NUMBERS: 'DISTORTED_NUMBERS',
    RECAPTCHA: 'RECAPTCHA',
    INVISIBLE_RECAPTCHA: 'INVISIBLE_RECAPTCHA',
    ANDROID_RECAPTCHA: 'ANDROID_RECAPTCHA'
};
export const CashbackConfirmationState = {
    CONFIRMED: 'CONFIRMED',
    DECLINED: 'DECLINED',
    NOT_CONFIRMED: 'NOT_CONFIRMED',
    REFUSED: 'REFUSED'
};
export const CashOutReason = {
    DONE: 'DONE',
    WAITING: 'WAITING',
    VALIDATION_ERROR: 'VALIDATION_ERROR',
    BET_ALREADY_CALCULATED: 'BET_ALREADY_CALCULATED',
    AMOUNT_CHANGED: 'AMOUNT_CHANGED',
    DISABLED: 'DISABLED',
    BET_MISSING: 'BET_MISSING',
    CUSTOMER_NOT_IDENTIFIED: 'CUSTOMER_NOT_IDENTIFIED',
    SESSION_ERROR: 'SESSION_ERROR'
};
export const CashOutStatus = {
    OK: 'OK',
    ERROR: 'ERROR'
};
/** CASINO_BONUS_TO_MAIN_WALLET_ACTIVATED */ /** CASINO_FREESPIN */ /** CASINO_PROVIDER_REWARD */ /** 
 * ### All ###
 * dataGroup - Casino
 **/ /** ### Casino ### */ export const CategoryType = {
    ALL: 'ALL',
    ARCHIVED: 'ARCHIVED',
    CUSTOM: 'CUSTOM',
    CUSTOMER: 'CUSTOMER',
    BONUS_WALLET: 'BONUS_WALLET',
    CUSTOMER_ARCHIVED: 'CUSTOMER_ARCHIVED',
    COMPLEX: 'COMPLEX'
};
/** Current locale */ /** Language (`ru`) or an alias (`br)` of a full locale */ /** Set APP_LANGUAGE to filter only specific language. optionalHeaders: X_APP_LANGUAGE */ /** Set APP_LANGUAGE to filter only specific language. optionalHeaders: X_APP_LANGUAGE */ /** devIP obfuscated */ export const ContentPlace = {
    BETLINE_EVENT: 'BETLINE_EVENT',
    BETLINE_LEAGUE: 'BETLINE_LEAGUE',
    BETLINE_REGION: 'BETLINE_REGION',
    BETLINE_SPORT: 'BETLINE_SPORT',
    VIRTUAL_SPORT: 'VIRTUAL_SPORT',
    EGS_LOBBY: 'EGS_LOBBY',
    EGS_GAME: 'EGS_GAME',
    UNKNOWN: 'UNKNOWN'
};
export const CouponType = {
    BONUS_PACK: 'BONUS_PACK'
};
/** transaction type name */ export const CustomerBetType = {
    UNDEFINED: 'UNDEFINED',
    SPORT: 'SPORT',
    EGS: 'EGS'
};
/** Contents value wich type depends on valueType field: MONEY - Float (money amount), COUNT - Long, Empty - any sing */ export const CustomerBonusState = {
    AVAILABLE: 'AVAILABLE',
    ACTIVE: 'ACTIVE'
};
export const CustomerConfig = {
    HIDE_COMMISSION_HINT: 'HIDE_COMMISSION_HINT',
    HIDE_ON_BOARDING_PAGE: 'HIDE_ON_BOARDING_PAGE',
    HIDE_BALANCE: 'HIDE_BALANCE',
    HIDE_NICKNAME_PROMPT: 'HIDE_NICKNAME_PROMPT',
    HIDE_EGS_LOYALTY_ON_BOARDING_PAGE: 'HIDE_EGS_LOYALTY_ON_BOARDING_PAGE',
    HIDE_ON_BOARDING_ON_LEON_SHOP_PAGE: 'HIDE_ON_BOARDING_ON_LEON_SHOP_PAGE',
    CREATE_PIN_CODE_MODAL_COUNTER: 'CREATE_PIN_CODE_MODAL_COUNTER',
    IS_PIN_CODE_REQUESTED_ON_LOGIN: 'IS_PIN_CODE_REQUESTED_ON_LOGIN',
    BONUS_CHOOSER_SHOWN_BEFORE_DEPOSITS: 'BONUS_CHOOSER_SHOWN_BEFORE_DEPOSITS',
    HIDE_NO_SELECTED_BONUS_MODAL: 'HIDE_NO_SELECTED_BONUS_MODAL',
    HIDE_LIMIT_MODAL_ON_GAME_PAGE: 'HIDE_LIMIT_MODAL_ON_GAME_PAGE',
    IS_VIDEO_VERIFICATION_ONBOARDING_SHOWN: 'IS_VIDEO_VERIFICATION_ONBOARDING_SHOWN',
    IS_FORTUNE_WHEEL_WINDOW_SHOWN: 'IS_FORTUNE_WHEEL_WINDOW_SHOWN',
    IS_ASO_MAIN_ONBOARDING_SHOWN: 'IS_ASO_MAIN_ONBOARDING_SHOWN',
    IS_RESPONSIBLE_GAMBLING_ONBOARDING_SHOWN: 'IS_RESPONSIBLE_GAMBLING_ONBOARDING_SHOWN',
    IS_ODDS_TYPE_CLASSIC: 'IS_ODDS_TYPE_CLASSIC',
    IS_ODDS_TYPE_AMERICAN: 'IS_ODDS_TYPE_AMERICAN',
    IS_REFERRAL_PROGRAM_ONBOARDING_SHOWN: 'IS_REFERRAL_PROGRAM_ONBOARDING_SHOWN',
    IS_REFERRAL_PROGRAM_NOTIFICATION_CLICKED: 'IS_REFERRAL_PROGRAM_NOTIFICATION_CLICKED',
    IS_EKYC_POPUP_ON_FIRST_LOGIN_SHOWN: 'IS_EKYC_POPUP_ON_FIRST_LOGIN_SHOWN'
};
/** 
 * Possible phone leak is approved by head of support Oleg Mironov
   * https://jira.leoncorp.net/browse/LEONAPI-433?focusedCommentId=183601&page=com.atlassian.jira.plugin.system.issuetabpanels:comment-tabpanel#comment-183601
   * <p>
   * If it will be undesirable again, consider using logic like in payment systems with encrypted phones
 **/ /** 
 * Phone country numeric code
   * <p>
   * Null if the phone is in an unknown format
 **/ /** 
 * Phone country ISO 3166-1 alpha-2 code
   * <p>
   * Null if the phone is in an unknown format
 **/ /** not null if enabled */ /** not null if enabled */ /** Null if a user hasn't done any deposits before */ export const CustomerFieldType = {
    FIRST_NAME: 'FIRST_NAME',
    LAST_NAME: 'LAST_NAME',
    MIDDLE_NAME: 'MIDDLE_NAME',
    CITY: 'CITY',
    EMAIL: 'EMAIL',
    OLD_PASSWORD: 'OLD_PASSWORD',
    PASSWORD: 'PASSWORD',
    BONUS_CODE: 'BONUS_CODE',
    ADDRESS: 'ADDRESS',
    DOCUMENT_TYPE_SELECT: 'DOCUMENT_TYPE_SELECT',
    DOCUMENT_COUNTRY_SELECT: 'DOCUMENT_COUNTRY_SELECT',
    COUNTRY_SELECT: 'COUNTRY_SELECT',
    CURRENCY_SELECT: 'CURRENCY_SELECT',
    TERMS_CHECKBOX: 'TERMS_CHECKBOX',
    BIRTH_DATE: 'BIRTH_DATE',
    DOCUMENT_ISSUE_DATE: 'DOCUMENT_ISSUE_DATE',
    PHONE_INPUT: 'PHONE_INPUT',
    REGISTRATION_CODE: 'REGISTRATION_CODE',
    PERSONAL_NUMBER: 'PERSONAL_NUMBER',
    DOCUMENT_NUMBER: 'DOCUMENT_NUMBER',
    DOCUMENT_ISSUE_AGENCY: 'DOCUMENT_ISSUE_AGENCY',
    CPF: 'CPF',
    DAILY_DEPOSIT_LIMIT: 'DAILY_DEPOSIT_LIMIT',
    PROMO_CODE: 'PROMO_CODE',
    OPTIONAL_INSTANT: 'OPTIONAL_INSTANT',
    NICKNAME: 'NICKNAME',
    SUBSCRIBE: 'SUBSCRIBE',
    ACCEPTED_OFFER: 'ACCEPTED_OFFER',
    ACCEPTED_PDA: 'ACCEPTED_PDA'
};
export const CustomerHistoryFilterType = {
    HOUR: 'HOUR',
    DAY: 'DAY',
    MONTH: 'MONTH'
};
export const CustomerIdentificationStage = {
    PHONE_NOT_VERIFIED: 'PHONE_NOT_VERIFIED',
    FORM_NOT_INITIALIZED: 'FORM_NOT_INITIALIZED',
    FORM_NOT_SUBMITTED: 'FORM_NOT_SUBMITTED',
    FORM_IS_SUBMITTED: 'FORM_IS_SUBMITTED',
    ALREADY_IDENTIFIED: 'ALREADY_IDENTIFIED',
    EXISTING_IDENT_BINDING_PENDING: 'EXISTING_IDENT_BINDING_PENDING'
};
export const CustomerIdentificationStatus = {
    NEW: 'NEW',
    DOCUMENTS_REQUESTED: 'DOCUMENTS_REQUESTED',
    WAITING_CALL: 'WAITING_CALL',
    SUSPENDED: 'SUSPENDED',
    REJECTED_BY_SUPPORT: 'REJECTED_BY_SUPPORT',
    APPROVED_BY_SUPPORT: 'APPROVED_BY_SUPPORT',
    REJECTED_BY_TSUPIS: 'REJECTED_BY_TSUPIS',
    APPROVED_BY_TSUPIS: 'APPROVED_BY_TSUPIS',
    CANNOT_GAMBLE: 'CANNOT_GAMBLE',
    CAN_GAMBLE: 'CAN_GAMBLE',
    COMPLETE: 'COMPLETE'
};
export const CustomerLimitsExceptionCode = {
    CUSTOMER_LIMITS_ERROR: 'CUSTOMER_LIMITS_ERROR'
};
export const CustomerLimitsRange = {
    DAILY: 'DAILY',
    WEEKLY: 'WEEKLY',
    MONTHLY: 'MONTHLY'
};
/** validationSchema: PERSONAL_DATA */ /** validationSchema: CHANGE_PASSWORD */ /** deprecated, use history from accounts. requiredHeaders: X_APP_SKIN */ export const CustomerRegistrationType = {
    FULL: 'FULL',
    SIMPLE_EMAIL: 'SIMPLE_EMAIL',
    SIMPLE_SOCIAL: 'SIMPLE_SOCIAL',
    SIMPLE_PHONE: 'SIMPLE_PHONE',
    SIMPLE_REG_API: 'SIMPLE_REG_API',
    SIMPLE_REG_API_V2: 'SIMPLE_REG_API_V2',
    FULL_SMS_CONFIRMATION: 'FULL_SMS_CONFIRMATION',
    SIMPLE_NO_PHONE_CHECK: 'SIMPLE_NO_PHONE_CHECK',
    FULL_NO_PHONE_CHECK: 'FULL_NO_PHONE_CHECK',
    SLOTT_PHONE: 'SLOTT_PHONE',
    SLOTT_NO_PHONE_CHECK: 'SLOTT_NO_PHONE_CHECK',
    UNKNOWN: 'UNKNOWN'
};
export const CustomerVerificationStatus = {
    NONE: 'NONE',
    STARTED: 'STARTED',
    IN_PROCESS: 'IN_PROCESS',
    RETRY: 'RETRY',
    SUCCESS: 'SUCCESS',
    FAILED: 'FAILED'
};
export const CustomerVerificationType = {
    INTERNAL: 'INTERNAL',
    CUSTOMER_VERIFICATION_SERVICE: 'CUSTOMER_VERIFICATION_SERVICE',
    VIDEO_VERIFICATION_SERVICE: 'VIDEO_VERIFICATION_SERVICE'
};
/** field for Typescript generator tool. Do not select in queries */ export const DeleteCardExceptionCode = {
    DELETE_CARD: 'DELETE_CARD'
};
export const DepositOperationType = {
    DEPOSIT: 'DEPOSIT',
    DEPOSIT_CANCEL: 'DEPOSIT_CANCEL'
};
/** UI dynamic form representation */ /** Serialized json-schema dynamic form validating schema */ /** Validation schema id */ /** Last deposit payment system data. Can be null if user hasn't done deposits before */ export const DisplayType = {
    PUSH: 'PUSH',
    SILENT: 'SILENT',
    SNACKBAR: 'SNACKBAR'
};
export const DocumentType = {
    PASSPORT: 'PASSPORT',
    RESIDENCE_PERMIT: 'RESIDENCE_PERMIT',
    REFUGEE_CERTIFICATE: 'REFUGEE_CERTIFICATE',
    FOREIGNERS_PASSPORT: 'FOREIGNERS_PASSPORT'
};
export const EgsAvailabilityStatus = {
    AVAILABLE: 'AVAILABLE',
    BET_BEHIND: 'BET_BEHIND',
    NOT_AVAILABLE: 'NOT_AVAILABLE'
};
export const EgsBlockType = {
    GEO: 'GEO',
    UNKNOWN: 'UNKNOWN'
};
/** search */ export const EgsGameType = {
    SLOTS: 'SLOTS',
    LIVE: 'LIVE',
    JACKPOTS: 'JACKPOTS',
    FASTGAMES: 'FASTGAMES',
    ALL: 'ALL'
};
/** transaction type name */ /** not null if user is logged */ export const EgsTopWinnersFilter = {
    DAY: 'DAY',
    WEEK: 'WEEK',
    MONTH: 'MONTH',
    ALL_TIME: 'ALL_TIME'
};
export const EgsTopWinnersGameType = {
    SLOTS: 'SLOTS',
    LIVE: 'LIVE',
    BETGAMES: 'BETGAMES',
    SPORTGAMES: 'SPORTGAMES',
    ALL: 'ALL'
};
export const ErrorHandlerType = {
    SENTRY: 'SENTRY'
};
export const EsiaIdentificationType = {
    NATIVE: 'NATIVE',
    ETSUPIS: 'ETSUPIS'
};
/** if params will not be filled, nothing will happen on lb. */ export const EtsupisApiType = {
    BAPI: 'BAPI',
    IAPI: 'IAPI'
};
export const EventTimerMode = {
    STATIC: 'STATIC',
    LIVE: 'LIVE'
};
/** in some cases betEvent, betMarket and betOutcome can be null */ export const FeatureVisibility = {
    ANONYMOUS: 'ANONYMOUS',
    REGISTERED: 'REGISTERED',
    VIP: 'VIP',
    BOTH: 'BOTH'
};
export const FeedbackChannel = {
    DEFAULT: 'DEFAULT',
    SELF_EXCLUSION: 'SELF_EXCLUSION',
    PROMOACTION: 'PROMOACTION'
};
export const FormType = {
    STRING: 'STRING',
    BOOLEAN: 'BOOLEAN',
    OBJECT: 'OBJECT'
};
export const FrontNotificationType = {
    CBC_BONUS_ACTIVATED: 'CBC_BONUS_ACTIVATED',
    ALCR_NOTIFICATION: 'ALCR_NOTIFICATION',
    VIDEO_VERIFICATION: 'VIDEO_VERIFICATION',
    ON_SITE: 'ON_SITE',
    REFPRG: 'REFPRG',
    PLAY_TIME_ENDED: 'PLAY_TIME_ENDED',
    UNKNOWN: 'UNKNOWN',
    BONUS_GAME_REWARD: 'BONUS_GAME_REWARD',
    BONUS_GAME_LAST_ROUND_FEEDBACK: 'BONUS_GAME_LAST_ROUND_FEEDBACK',
    BONUS_EXPIRED_CASHBACK: 'BONUS_EXPIRED_CASHBACK',
    BONUS_CASHBACK: 'BONUS_CASHBACK',
    SUSPENDED_CASHBACK: 'SUSPENDED_CASHBACK',
    RESUMED_CASHBACK: 'RESUMED_CASHBACK',
    CRYPTO_DEPOSIT_STATUS: 'CRYPTO_DEPOSIT_STATUS',
    BS_DEPOSIT: 'BS_DEPOSIT'
};
export const G2SVRequiredExceptionCode = {
    G2SV_REQUIRED: 'G2SV_REQUIRED'
};
export const GambleStatus = {
    NONE: 'NONE',
    APPROVED_BY_REGULATOR: 'APPROVED_BY_REGULATOR',
    APPROVED_BY_STAFF: 'APPROVED_BY_STAFF',
    TEMPORARY_BLOCKED: 'TEMPORARY_BLOCKED',
    DECLINED_BY_REGULATOR: 'DECLINED_BY_REGULATOR',
    CAN_GAMBLE: 'CAN_GAMBLE'
};
export const GameOpenMethod = {
    WINDOW: 'WINDOW',
    IFRAME: 'IFRAME'
};
export const GameUnavailableExceptionCode = {
    GAME_UNAVAILABLE: 'GAME_UNAVAILABLE'
};
/** ## getBanner */ /** If not null, filter promotions by this category. Show live promotions otherwise */ /** ## verification */ export const HelpButtonMode = {
    HELP: 'HELP',
    EXT_CHAT: 'EXT_CHAT'
};
export const HistoryFilter = {
    ALL: 'ALL',
    ALL_BETS: 'ALL_BETS',
    ALL_PAYMENTS: 'ALL_PAYMENTS',
    ALL_DEPOSITS: 'ALL_DEPOSITS',
    ALL_WITHDRAWALS: 'ALL_WITHDRAWALS',
    ALL_EGS: 'ALL_EGS',
    ALL_SBC: 'ALL_SBC',
    ALL_BONUS: 'ALL_BONUS',
    ALL_CBC: 'ALL_CBC',
    LEONSHOP: 'LEONSHOP',
    PENDING: 'PENDING',
    PENDING_BETS: 'PENDING_BETS',
    PENDING_EGS: 'PENDING_EGS',
    PENDING_WITHDRAWALS: 'PENDING_WITHDRAWALS',
    COMPLETED: 'COMPLETED',
    COMPLETED_BETS: 'COMPLETED_BETS',
    COMPLETED_EGS: 'COMPLETED_EGS',
    COMPLETED_PAYMENTS: 'COMPLETED_PAYMENTS',
    COMPLETED_DEPOSITS: 'COMPLETED_DEPOSITS',
    COMPLETED_WITHDRAWALS: 'COMPLETED_WITHDRAWALS'
};
export const HomeAway = {
    HOME: 'HOME',
    AWAY: 'AWAY'
};
export const HomePageType = {
    SPORTS: 'SPORTS',
    SLOTS: 'SLOTS',
    LIVE_GAMES: 'LIVE_GAMES',
    CUSTOM: 'CUSTOM'
};
export const HttpMethod = {
    GET: 'GET',
    HEAD: 'HEAD',
    POST: 'POST',
    PUT: 'PUT',
    PATCH: 'PATCH',
    DELETE: 'DELETE',
    OPTIONS: 'OPTIONS',
    TRACE: 'TRACE'
};
export const IdentificationDocumentType = {
    PASSPORT: 'PASSPORT',
    RESIDENCE: 'RESIDENCE',
    THIRD_PAGE: 'THIRD_PAGE',
    FACEPASS: 'FACEPASS',
    VIDEO: 'VIDEO'
};
export const IdentificationLevel = {
    NONE: 'NONE',
    SIMPLE: 'SIMPLE',
    FULL: 'FULL'
};
/** ## resetDisposableKeys */ /** ## resetDisposableKeys */ /** ## resetDisposableKeys */ /** ## resetDisposableKeys */ /** ## resetDisposableKeys */ /** ## resetDisposableKeys */ /** ## resetDisposableKeys */ /** ## resetDisposableKeys */ /** ## resetDisposableKeys */ /** ## resetDisposableKeys */ /** ## resetDisposableKeys */ /** ## resetDisposableKeys */ /** ## resetDisposableKeys */ /** ## resetDisposableKeys */ /** ## resetDisposableKeys */ /** ## resetDisposableKeys */ export const IdentificationProvider = {
    ESIA_SIMPLE: 'ESIA_SIMPLE',
    TINKOFF: 'TINKOFF',
    SBER: 'SBER'
};
/** form draft. Not null only if a user uploaded any document and until the user will send form to the server */ export const IgnoredErrorType = {
    TEXT: 'TEXT',
    REGEXP: 'REGEXP'
};
export const IllegalMutationsExceptionCode = {
    INVALID_MUTATIONS_NUMBER: 'INVALID_MUTATIONS_NUMBER'
};
export const IncorrectCustomerType = {
    NEW: 'NEW',
    SUSPEND: 'SUSPEND',
    LOCKED: 'LOCKED'
};
export const InlineChatAnimation = {
    SPEECH_BUBBLE: 'SPEECH_BUBBLE',
    ICON_SHAKE: 'ICON_SHAKE',
    ICON: 'ICON'
};
export const InlineChatHideCondition = {
    SECONDS_3: 'SECONDS_3',
    SECONDS_5: 'SECONDS_5',
    SECONDS_10: 'SECONDS_10',
    ON_OUTSIDE_CLICK: 'ON_OUTSIDE_CLICK'
};
export const InvalidBatchGraphQLRequestBodyIdExceptionCode = {
    INVALID_BATCH_ID_ERROR: 'INVALID_BATCH_ID_ERROR'
};
export const InvalidBusinessHeaderValueExceptionCode = {
    INVALID_HEADER_VALUE: 'INVALID_HEADER_VALUE'
};
export const InvalidCodeExceptionCode = {
    INVALID_CODE: 'INVALID_CODE'
};
export const InvalidGraphqlRequestsCountExceptionCode = {
    INVALID_GRAPHQL_REQUESTS_NUMBER: 'INVALID_GRAPHQL_REQUESTS_NUMBER'
};
export const InvalidPhoneExceptionCode = {
    INVALID_PHONE: 'INVALID_PHONE'
};
export const InvalidRequestsCountExceptionCode = {
    INVALID_REQUESTS_NUMBER: 'INVALID_REQUESTS_NUMBER'
};
export const InvalidTsExceptionCode = {
    INVALID_TS: 'INVALID_TS'
};
export const InvalidUpdatedFromExceptionCode = {
    INVALID_UPDATED_FROM: 'INVALID_UPDATED_FROM'
};
export const JurisdictionNotSupportedActionExceptionCode = {
    JURISDICTION_NOT_SUPPORTED_ACTION: 'JURISDICTION_NOT_SUPPORTED_ACTION'
};
/** transaction type name */ /** validationSchema: CUSTOMER_LIMITS */ /** validationSchema: CUSTOMER_LIMITS */ /** GAMES_CATEGORY */ export const LobbyGameType = {
    SLOTS: 'SLOTS',
    LIVE: 'LIVE',
    BETGAMES: 'BETGAMES',
    FASTGAMES: 'FASTGAMES',
    ALL: 'ALL'
};
export const LobbyGridType = {
    DEFAULT: 'DEFAULT',
    SMALL: 'SMALL',
    LARGE: 'LARGE'
};
/** GROUPS */ export const LobbyItemType = {
    GAMES_CATEGORY: 'GAMES_CATEGORY',
    PROMOTIONS: 'PROMOTIONS',
    GROUPS: 'GROUPS',
    JACKPOTS: 'JACKPOTS',
    TOP_WINNERS: 'TOP_WINNERS',
    SPORTBET_TOP_WINNERS: 'SPORTBET_TOP_WINNERS',
    ALL_TOP_WINNERS: 'ALL_TOP_WINNERS',
    BANNERS: 'BANNERS',
    SPORTLINE_TOP_MATCHES: 'SPORTLINE_TOP_MATCHES'
};
/** mapping between configurable widget type and frontend widget */ export const LobbyItemWidget = {
    SQUARE_LIST: 'SQUARE_LIST',
    LIST: 'LIST',
    SWIPE_LIST: 'SWIPE_LIST',
    JACKPOT_LIST: 'JACKPOT_LIST',
    JACKPOT_SWIPE_LIST: 'JACKPOT_SWIPE_LIST',
    SQUARE_SWIPE_LIST: 'SQUARE_SWIPE_LIST',
    SQUARE_ROWS_LIST: 'SQUARE_ROWS_LIST',
    BIG_SMALL_ROWS_LIST: 'BIG_SMALL_ROWS_LIST'
};
/** JACKPOTS */ /** PROMOTIONS */ /** sorted list of items */ export const LobbyTitleType = {
    DEFAULT: 'DEFAULT',
    SMALL: 'SMALL',
    HIDDEN: 'HIDDEN'
};
/** PROMOTIONS */ export const LobbyType = {
    SLOTS: 'SLOTS',
    LIVE_CASINO: 'LIVE_CASINO',
    SEARCH: 'SEARCH',
    SLOTS_GAME_PAGE: 'SLOTS_GAME_PAGE',
    LIVE_GAME_PAGE: 'LIVE_GAME_PAGE',
    LOYALTY_PROGRAM: 'LOYALTY_PROGRAM',
    FASTGAMES: 'FASTGAMES',
    HOME: 'HOME',
    ALL: 'ALL'
};
/** ## loginFingerprint */ /** captcha fingerprint */ /** ## loginG2sv */ /** ## loginPinCode */ /** captcha fingerprint */ /** ## loginSso */ export const LoginType = {
    EMAIL: 'EMAIL',
    PHONE: 'PHONE',
    ACCOUNT: 'ACCOUNT'
};
export const LogLevel = {
    ERROR: 'ERROR',
    WARN: 'WARN',
    INFO: 'INFO',
    DEBUG: 'DEBUG'
};
/** LOYALTY_BONUS */ /** timestamp must be the time of the first bet's request or refresh requests will be handled as the new one */ export const ManualStatus = {
    INIT: 'INIT',
    UPDATED: 'UPDATED',
    API_FOUND: 'API_FOUND',
    ERROR: 'ERROR',
    DRAFT: 'DRAFT',
    DRAFT_INIT: 'DRAFT_INIT',
    SUCCESS: 'SUCCESS'
};
/** 
 * MarketingSportFreebetOverviewDto_Marketing - SPORT_FREEBET
 * MarketingCasinoBonusToMainWalletActivatedOverviewDto_Marketing - CASINO_BONUS_TO_MAIN_WALLET_ACTIVATED
 **/ /** 
 * dataType - Casino_bonus_to_main_wallet_activated
 * dataGroup - Marketing
 **/ /** 
 * dataType - Casino_freespin
 * dataGroup - Marketing
 **/ /** CASINO_FREESPIN */ /** 
 * dataType - Casino_provider_reward
 * dataGroup - Marketing
 **/ /** CASINO_PROVIDER_REWARD */ /** 
 * dataType - Loyalty_bonus
 * dataGroup - Marketing
 **/ /** LOYALTY_BONUS */ /** 
 * dataType - Sport_freebet
 * dataGroup - Marketing
 **/ export const MarketStatus = {
    CLOSED: 'CLOSED',
    MISSING: 'MISSING',
    PRICE_UP: 'PRICE_UP',
    PRICE_DOWN: 'PRICE_DOWN',
    SUSPENDED: 'SUSPENDED',
    LIMIT: 'LIMIT',
    DUPLICATE: 'DUPLICATE'
};
export const MatchPhase = {
    PRE_GAME: 'PRE_GAME',
    IN_PLAY: 'IN_PLAY',
    GAME_ABANDONED: 'GAME_ABANDONED',
    BREAK_IN_PLAY: 'BREAK_IN_PLAY',
    OVERTIME: 'OVERTIME',
    POST_GAME: 'POST_GAME'
};
export const MatchStreamAvailabilityType = {
    ALL: 'ALL',
    NORMAL: 'NORMAL',
    VIP: 'VIP',
    UNKNOWN: 'UNKNOWN'
};
export const MetricsToolType = {
    YANDEX: 'YANDEX',
    INCOME_ACCESS: 'INCOME_ACCESS',
    GOOGLE_ANALYTICS: 'GOOGLE_ANALYTICS'
};
export const NavigationSection = {
    HEADER_NAVIGATION: 'HEADER_NAVIGATION',
    FOOTER_NAVIGATION: 'FOOTER_NAVIGATION',
    SIDE_NAVIGATION: 'SIDE_NAVIGATION',
    PROMOACTIONS_NAVIGATION: 'PROMOACTIONS_NAVIGATION'
};
export const NoCashbackExceptionCode = {
    NO_CAMPAIGN: 'NO_CAMPAIGN'
};
export const NoRegisterStrategyExceptionCode = {
    NO_REGISTRATION_STRATEGY_FOUND: 'NO_REGISTRATION_STRATEGY_FOUND'
};
export const NoSchemaExceptionCode = {
    NO_SCHEMA_FOUND: 'NO_SCHEMA_FOUND'
};
export const NoSuchContentExceptionCode = {
    NO_CONTENT: 'NO_CONTENT'
};
export const NoSuchHeaderExceptionCode = {
    NO_SUCH_HEADER: 'NO_SUCH_HEADER'
};
export const NoSuchTranslationExceptionCode = {
    NO_TRANSLATION: 'NO_TRANSLATION'
};
export const NoValidationSchemaFoundExceptionCode = {
    NO_VALIDATION_SCHEMA: 'NO_VALIDATION_SCHEMA'
};
/** field for Typescript generator tool. Do not select in queries */ export const OpenIn = {
    SAME_WINDOW: 'SAME_WINDOW',
    NEW_WINDOW: 'NEW_WINDOW',
    IFRAME: 'IFRAME'
};
export const OperationStatus = {
    OK: 'OK',
    FAIL: 'FAIL'
};
export const OutcomeState = {
    UNDEFINED: 'UNDEFINED',
    WIN: 'WIN',
    LOSE: 'LOSE',
    RETURN: 'RETURN',
    WIN_HALF: 'WIN_HALF',
    LOSE_HALF: 'LOSE_HALF',
    PRE_GAME: 'PRE_GAME',
    IN_PLAY: 'IN_PLAY'
};
export const PackListState = {
    UPCOMING: 'UPCOMING',
    ACTIVE: 'ACTIVE',
    CLOSED: 'CLOSED'
};
export const PackReward = {
    EMPTY: 'EMPTY',
    COLLECTIBLE_CARD: 'COLLECTIBLE_CARD',
    FREEBET: 'FREEBET',
    FREESPIN: 'FREESPIN',
    BONUS: 'BONUS'
};
export const PackState = {
    NOT_AVAILABLE: 'NOT_AVAILABLE',
    AVAILABLE_FOR_CALCULATION: 'AVAILABLE_FOR_CALCULATION',
    CALCULATED: 'CALCULATED'
};
export const ParticipationType = {
    NONE: 'NONE',
    PROMO: 'PROMO',
    LEON_BONUS: 'LEON_BONUS'
};
export const PathType = {
    INTERNAL_PATH: 'INTERNAL_PATH',
    EXTERNAL_PATH: 'EXTERNAL_PATH',
    MARKETING_LEONSHOP: 'MARKETING_LEONSHOP'
};
/** Key-value list to do the action */ /** Validation schema id */ /** 
 * dataType - Casino_provider_reward
 * dataGroup - Payment
 **/ /** 
 * ### Payment ###
 * dataType = CASINO_PROVIDER_REWARD
 **/ /** 
 * ### Payment Cursor ###
 * dataType = CASINO_PROVIDER_REWARD
 **/ /** 
 * dataType - Correction
 * dataGroup - Payment
 **/ /** dataType = CORRECTION */ /** dataType = CORRECTION */ export const PaymentDataType = {
    DEPOSIT: 'DEPOSIT',
    WITHDRAWAL: 'WITHDRAWAL',
    CORRECTION: 'CORRECTION',
    CASINO_PROVIDER_REWARD: 'CASINO_PROVIDER_REWARD',
    MANUAL_BS_DEPOSIT_BONUS: 'MANUAL_BS_DEPOSIT_BONUS',
    LEADERBOARD_CASH_REWARD: 'LEADERBOARD_CASH_REWARD',
    MARKETING_CASINO_BONUS_DEPOSIT: 'MARKETING_CASINO_BONUS_DEPOSIT',
    LOYALTY_BONUS_ENCASH: 'LOYALTY_BONUS_ENCASH',
    MARKETING_SPORT_BONUS_DEPOSIT: 'MARKETING_SPORT_BONUS_DEPOSIT'
};
/** 
 * dataType - Deposit
 * dataGroup - Payment
 **/ /** dataType = DEPOSIT */ /** dataType = DEPOSIT */ export const PaymentExceptionCode = {
    PAYMENT_ERROR: 'PAYMENT_ERROR'
};
/** transaction type name */ /** 
 * dataType - Leaderboard_cash_reward
 * dataGroup - Payment
 **/ /** dataType = LEADERBOARD_CASH_REWARD */ /** dataType = LEADERBOARD_CASH_REWARD */ /** 
 * dataType - Loyalty_bonus_encash
 * dataGroup - Payment
 **/ /** dataType = LOYALTY_BONUS_ENCASH */ /** dataType = LOYALTY_BONUS_ENCASH */ /** 
 * dataType - Manual_bs_deposit_bonus
 * dataGroup - Payment
 **/ /** dataType = MANUAL_BS_DEPOSIT_BONUS */ /** 
 * duplicate
 * dataType = MANUAL_BS_DEPOSIT_BONUS
 **/ /** 
 * dataType - Marketing_casino_bonus_deposit
 * dataGroup - Payment
 **/ /** dataType = MARKETING_CASINO_BONUS_DEPOSIT */ /** dataType = MARKETING_CASINO_BONUS_DEPOSIT */ /** 
 * dataType - Marketing_sport_bonus_deposit
 * dataGroup - Payment
 **/ /** dataType = MARKETING_SPORT_BONUS_DEPOSIT */ /** dataType = MARKETING_SPORT_BONUS_DEPOSIT */ export const PaymentStatuses = {
    PENDING: 'PENDING',
    EXECUTED: 'EXECUTED',
    CANCELLED: 'CANCELLED'
};
/** UI dynamic form representation */ /** Serialized json-schema dynamic form validating schema */ /** Validation schema id */ /** transaction type name */ export const PaymentType = {
    DEPOSIT: 'DEPOSIT',
    WITHDRAWAL: 'WITHDRAWAL'
};
/** 
 * dataType - Withdrawal
 * dataGroup - Payment
 **/ /** dataType = WITHDRAWAL */ /** dataType = WITHDRAWAL */ /** ## getPendingBets */ export const PersonalDomainType = {
    CONDITIONAL: 'CONDITIONAL',
    PERSONAL: 'PERSONAL'
};
export const PhoneCodeMethod = {
    SMS: 'SMS',
    CALL_PASSWORD_CODE: 'CALL_PASSWORD_CODE',
    EXTERNAL_SMS: 'EXTERNAL_SMS'
};
export const PhoneIdentificationRequired = {
    UNKNOWN: 'UNKNOWN',
    NO: 'NO',
    YES: 'YES'
};
export const PinCodePlace = {
    WITHDRAWAL: 'WITHDRAWAL',
    SELF_EXCLUSION: 'SELF_EXCLUSION',
    LOGIN: 'LOGIN',
    PIN_CODE_AT_LOGIN_SETTING: 'PIN_CODE_AT_LOGIN_SETTING',
    PLAY_TIME_CONTROL: 'PLAY_TIME_CONTROL',
    UNKNOWN: 'UNKNOWN'
};
export const PinCodeRequiredExceptionCode = {
    PIN_CODE_VERIFICATION_NEEDED: 'PIN_CODE_VERIFICATION_NEEDED'
};
export const PinCodeState = {
    INITIAL: 'INITIAL',
    SET: 'SET',
    RESET: 'RESET',
    BANNED: 'BANNED',
    UNKNOWN: 'UNKNOWN'
};
export const PreviousRequestHasNotExpiredExceptionCode = {
    PREVIOUS_REQUEST_HAS_NOT_EXPIRED: 'PREVIOUS_REQUEST_HAS_NOT_EXPIRED'
};
export const PriceChangePolicy = {
    DENY_CHANGES: 'DENY_CHANGES',
    ALLOW_INCREASE: 'ALLOW_INCREASE',
    ALLOW_CHANGES: 'ALLOW_CHANGES',
    UNDEFINED: 'UNDEFINED'
};
export const ProgramParticipationStatus = {
    PENDING: 'PENDING',
    AVAILABLE: 'AVAILABLE',
    COMPLETED: 'COMPLETED'
};
export const ProgramRequirementType = {
    REGISTRATION: 'REGISTRATION',
    DEPOSIT: 'DEPOSIT',
    BET: 'BET',
    CONFIRMATION: 'CONFIRMATION',
    IDENTIFICATION: 'IDENTIFICATION'
};
export const ProgramRewardType = {
    FREESPIN: 'FREESPIN',
    FREEBET: 'FREEBET'
};
export const PromoActionType = {
    NO_PARTICIPATION: 'NO_PARTICIPATION',
    STATIC: 'STATIC',
    LEADERBOARD_SPORTS: 'LEADERBOARD_SPORTS',
    LEADERBOARD_EG: 'LEADERBOARD_EG',
    PLAIN_CONFIRM: 'PLAIN_CONFIRM',
    CBC: 'CBC',
    SBC: 'SBC',
    EGS_CASHBACK: 'EGS_CASHBACK',
    SPORT: 'SPORT',
    EGS: 'EGS',
    FORTUNE_WHEEL: 'FORTUNE_WHEEL',
    COUPON: 'COUPON',
    BONUS_PACK: 'BONUS_PACK'
};
export const PromotionActionButtonState = {
    ACTION: 'ACTION',
    INFO: 'INFO'
};
export const PromotionActionButtonType = {
    LOGIN: 'LOGIN',
    PARTICIPATE: 'PARTICIPATE',
    PARTICIPATING: 'PARTICIPATING',
    PARTICIPATION_PROCESSING: 'PARTICIPATION_PROCESSING',
    ON_BONUS_WALLET: 'ON_BONUS_WALLET',
    LEADERBOARD: 'LEADERBOARD',
    EXCHANGE: 'EXCHANGE',
    EXCHANGED: 'EXCHANGED',
    CUSTOM: 'CUSTOM',
    NONE: 'NONE',
    FREESPIN: 'FREESPIN',
    PLAY: 'PLAY',
    IDENTIFICATION_NEEDED: 'IDENTIFICATION_NEEDED'
};
export const PushNotificationProvider = {
    INTERNAL: 'INTERNAL',
    FIREBASE: 'FIREBASE'
};
export const PwaCookieFixMode = {
    AUTO: 'AUTO',
    REDIRECT: 'REDIRECT',
    OPENTAB: 'OPENTAB',
    OFF: 'OFF'
};
/** validationSchema: RESTORE_PASSWORD_SEND_LINK_TO_EMAIL */ /** validationSchema: RESTORE_PASSWORD_BY_EMAIL */ /** validationSchema: RESTORE_PASSWORD_SEND_SMS_TO_PHONE */ /** validationSchema: RESTORE_PASSWORD_CHECK_SMS */ /** validationSchema: RESTORE_PASSWORD_BY_PHONE */ /** validationSchema: RESTORE_PASSWORD_SEND_SMS_TO_PHONE */ /** QR code in Base64 URI */ export const RateLimiterExceptionCode = {
    RATE_LIMITER: 'RATE_LIMITER'
};
export const Rating = {
    POSITIVE: 'POSITIVE',
    NEGATIVE: 'NEGATIVE'
};
export const RecaptchaTheme = {
    DARK: 'DARK',
    LIGHT: 'LIGHT',
    UI_INVERT: 'UI_INVERT',
    UI_MATCH: 'UI_MATCH'
};
export const RefProgramNotificationType = {
    PROGRAM_AVAILABLE: 'PROGRAM_AVAILABLE',
    PROGRAM_UNAVAILABLE: 'PROGRAM_UNAVAILABLE',
    REFERRER_REQUIREMENT_SATISFIED: 'REFERRER_REQUIREMENT_SATISFIED',
    REFERRER_REWARD_ASSIGNED: 'REFERRER_REWARD_ASSIGNED',
    REFERRAL_REQUIREMENT_SATISFIED: 'REFERRAL_REQUIREMENT_SATISFIED',
    REFERRAL_REWARD_ASSIGNED: 'REFERRAL_REWARD_ASSIGNED',
    REFERRAL_REWARD_ASSIGNED_REFERRER_NOTIFICATION: 'REFERRAL_REWARD_ASSIGNED_REFERRER_NOTIFICATION',
    BONUS_ELIGIBILITY_RULES: 'BONUS_ELIGIBILITY_RULES'
};
export const RefuseBonusType = {
    NONE: 'NONE',
    ONLY_WRITEOFF: 'ONLY_WRITEOFF',
    WRITEOFF_AND_DEPOSIT_RETURN: 'WRITEOFF_AND_DEPOSIT_RETURN',
    FREEBET_CANCEL: 'FREEBET_CANCEL',
    FREESPIN_CANCEL: 'FREESPIN_CANCEL'
};
export const RegistrationStateExceptionCode = {
    REGISTRATION_STATE_ERROR: 'REGISTRATION_STATE_ERROR'
};
export const RemoteApiErrorExceptionCode = {
    INVALID_CAPTCHA: 'INVALID_CAPTCHA',
    CAPTCHA_NEEDED: 'CAPTCHA_NEEDED',
    INVALID_PHONE_NUMBER: 'INVALID_PHONE_NUMBER',
    PROMOTION_NOT_FOUND: 'PROMOTION_NOT_FOUND',
    PIN_CODE_IS_BANNED: 'PIN_CODE_IS_BANNED',
    SERVICE_SUSPENDED: 'SERVICE_SUSPENDED',
    CUSTOMER_HISTORY_LIMIT_EXCEEDED: 'CUSTOMER_HISTORY_LIMIT_EXCEEDED',
    WITHDRAWAL_AMOUNT_EXCEEDED: 'WITHDRAWAL_AMOUNT_EXCEEDED'
};
export const RequestHashingDisabledExceptionCode = {
    REQUEST_HASHING_IS_DISABLED: 'REQUEST_HASHING_IS_DISABLED'
};
/** In requests use only lower-case and dashes instead of underscore due to nginx configuration */ export const RequestHeader = {
    X_APP_PLATFORM: 'X_APP_PLATFORM',
    X_APP_RENDERING: 'X_APP_RENDERING',
    X_APP_MODERNITY: 'X_APP_MODERNITY',
    X_APP_ENV: 'X_APP_ENV',
    X_APP_SKIN: 'X_APP_SKIN',
    X_APP_OS: 'X_APP_OS',
    X_APP_LAYOUT: 'X_APP_LAYOUT',
    X_APP_BROWSER: 'X_APP_BROWSER',
    X_APP_THEME: 'X_APP_THEME',
    X_APP_VERSION: 'X_APP_VERSION',
    X_APP_LANGUAGE: 'X_APP_LANGUAGE',
    /** Has a priority over x-app-language. Can be an alias of a full locale, e.g. `br` */ X_APP_PREFERRED_LANG: 'X_APP_PREFERRED_LANG',
    X_APP_REFERRER: 'X_APP_REFERRER',
    X_APP_BUILD_ID: 'X_APP_BUILD_ID',
    X_APP_STORE: 'X_APP_STORE',
    X_APP_DEBUG: 'X_APP_DEBUG',
    /** affiliate headers */ X_WM: 'X_WM',
    X_SUBID: 'X_SUBID',
    X_SUBID2: 'X_SUBID2',
    X_SUBID3: 'X_SUBID3',
    X_SUBID4: 'X_SUBID4',
    X_SUBID5: 'X_SUBID5',
    X_CLICK_ID: 'X_CLICK_ID',
    X_WMZONE: 'X_WMZONE',
    X_BTAG: 'X_BTAG',
    X_IABTAG: 'X_IABTAG',
    X_IALOCALE: 'X_IALOCALE',
    X_LOCALE: 'X_LOCALE',
    X_QTAG: 'X_QTAG',
    X_REFTAG: 'X_REFTAG',
    /** retention metric headers */ X_RETENTION_ID: 'X_RETENTION_ID',
    X_UTM_SOURCE: 'X_UTM_SOURCE',
    X_UTM_MEDIUM: 'X_UTM_MEDIUM',
    X_UTM_CAMPAIGN: 'X_UTM_CAMPAIGN',
    X_UTM_TERM: 'X_UTM_TERM',
    X_UTM_CONTENT: 'X_UTM_CONTENT'
};
export const RequirementType = {
    DEPOSIT: 'DEPOSIT',
    BET_COUNT: 'BET_COUNT',
    BET_AMOUNT: 'BET_AMOUNT',
    IDENTIFICATION: 'IDENTIFICATION',
    CLOSE_ACTIVE_BONUS: 'CLOSE_ACTIVE_BONUS',
    CONFIRMATION: 'CONFIRMATION',
    BONUS_CODE_CONFIRMATION: 'BONUS_CODE_CONFIRMATION',
    DEPOSIT_BONUS_CODE: 'DEPOSIT_BONUS_CODE',
    COMPLEX: 'COMPLEX'
};
export const RequirementValueType = {
    MONEY: 'MONEY',
    COUNT: 'COUNT'
};
export const ReserveMode = {
    IFRAME: 'IFRAME',
    REDIRECT: 'REDIRECT'
};
/** validationSchema: "EXAMPLE" */ /** validationSchema: "EXAMPLE" */ /** isDevIP obfuscated */ /** Current locale */ /** Language (`ru`) or an alias (`br)` of a full locale */ export const SchemaValidationExceptionCode = {
    SCHEMA_VALIDATION_ERROR: 'SCHEMA_VALIDATION_ERROR'
};
export const SelfExclusionTimeUnitType = {
    MONTHS: 'MONTHS',
    HOURS: 'HOURS'
};
export const SessionExceptionCode = {
    SESSION: 'SESSION'
};
/** Null to reset the limit */ /** devIp obfuscated */ /** devMode obfuscated */ export const ShopOrderStatus = {
    CREATED: 'CREATED',
    IN_PROGRESS: 'IN_PROGRESS',
    DONE: 'DONE',
    CANCELLED: 'CANCELLED'
};
export const SimpleIdentMethod = {
    ESIA: 'ESIA',
    MANUAL: 'MANUAL',
    UPLOAD: 'UPLOAD',
    TINKOFF_ID: 'TINKOFF_ID',
    ESIA_V2: 'ESIA_V2',
    SBER_ID: 'SBER_ID'
};
/** Time stamp of when a user added the event to the slip */ /** Time stamp of when a user added the event to the slip */ /** Time stamp of when a user added the event to the slip */ export const SlipStatus = {
    OK: 'OK',
    MAX_LIMIT: 'MAX_LIMIT',
    EMPTY: 'EMPTY',
    MARKETS_CHANGED: 'MARKETS_CHANGED',
    MANY_ENTRIES: 'MANY_ENTRIES',
    ODDS_LIMIT: 'ODDS_LIMIT',
    INCOMPATIBLE_BETLINES: 'INCOMPATIBLE_BETLINES',
    INCOMPATIBLE_SPORTS: 'INCOMPATIBLE_SPORTS',
    INCOMPATIBLE_LEAGUES: 'INCOMPATIBLE_LEAGUES',
    DUPLICATE_SPORT: 'DUPLICATE_SPORT',
    DUPLICATE_LEAGUE: 'DUPLICATE_LEAGUE',
    DUPLICATE_EVENT: 'DUPLICATE_EVENT',
    DUPLICATE_COMPETITOR: 'DUPLICATE_COMPETITOR',
    SINGLE_BETTING_ONLY: 'SINGLE_BETTING_ONLY'
};
export const SlipTypeId = {
    SINGLE: 'SINGLE',
    EXPRESS: 'EXPRESS',
    SYSTEM: 'SYSTEM'
};
export const SportBetStatus = {
    PLACED: 'PLACED',
    WON: 'WON',
    LOST: 'LOST',
    CASHED_OUT: 'CASHED_OUT',
    VOIDED: 'VOIDED'
};
export const SportBonusState = {
    ACTIVE: 'ACTIVE',
    CLOSED: 'CLOSED'
};
export const SportbookDataType = {
    SPORT_BET: 'SPORT_BET',
    FAST_BET: 'FAST_BET'
};
/** deprecated */ /** deprecated */ export const SportFamily = {
    UNKNOWN: 'UNKNOWN',
    Soccer: 'Soccer',
    Basketball: 'Basketball',
    Baseball: 'Baseball',
    IceHockey: 'IceHockey',
    Tennis: 'Tennis',
    Handball: 'Handball',
    Floorball: 'Floorball',
    Golf: 'Golf',
    Boxing: 'Boxing',
    Motorsport: 'Motorsport',
    Rugby: 'Rugby',
    AustralianRules: 'AustralianRules',
    WinterSports: 'WinterSports',
    Bandy: 'Bandy',
    AmericanFootball: 'AmericanFootball',
    Cycling: 'Cycling',
    Specials: 'Specials',
    Snooker: 'Snooker',
    TableTennis: 'TableTennis',
    Cricket: 'Cricket',
    Darts: 'Darts',
    Volleyball: 'Volleyball',
    FieldHockey: 'FieldHockey',
    WaterPolo: 'WaterPolo',
    Curling: 'Curling',
    Futsal: 'Futsal',
    Olympics: 'Olympics',
    Badminton: 'Badminton',
    Bowls: 'Bowls',
    GaelicFootball: 'GaelicFootball',
    BeachVolleyball: 'BeachVolleyball',
    Athletics: 'Athletics',
    Squash: 'Squash',
    BeachSoccer: 'BeachSoccer',
    Pesapallo: 'Pesapallo',
    Sailing: 'Sailing',
    WorldLottery: 'WorldLottery',
    ESport: 'ESport',
    MMA: 'MMA',
    SoccerMythical: 'SoccerMythical',
    VirtualSoccer: 'VirtualSoccer',
    VirtualSoccerV2: 'VirtualSoccerV2',
    VirtualBasketball: 'VirtualBasketball',
    VirtualTennis: 'VirtualTennis',
    VirtualTennisInplay: 'VirtualTennisInplay',
    VirtualCricket: 'VirtualCricket',
    AlpineSkiing: 'AlpineSkiing',
    SkiJumping: 'SkiJumping',
    Biathlon: 'Biathlon',
    CrossCountrySkiing: 'CrossCountrySkiing',
    FormulaE: 'FormulaE',
    HorseRacing: 'HorseRacing',
    NordicCombined: 'NordicCombined',
    Bobsleigh: 'Bobsleigh',
    Luge: 'Luge',
    Skeleton: 'Skeleton',
    FreestyleSkiing: 'FreestyleSkiing',
    ShortTrackSpeedSkating: 'ShortTrackSpeedSkating',
    SpeedSkating: 'SpeedSkating',
    FigureSkating: 'FigureSkating',
    Snowboard: 'Snowboard',
    Miscellaneous: 'Miscellaneous',
    RollerHockey: 'RollerHockey',
    Archery: 'Archery',
    Diving: 'Diving',
    Equestrian: 'Equestrian',
    Fencing: 'Fencing',
    Judo: 'Judo',
    ModernPentathlon: 'ModernPentathlon',
    Canoe: 'Canoe',
    Gymnastics: 'Gymnastics',
    Rowing: 'Rowing',
    Shooting: 'Shooting',
    SwimmingSynchronised: 'SwimmingSynchronised',
    Swimming: 'Swimming',
    Taekwondo: 'Taekwondo',
    Trampoline: 'Trampoline',
    Triathlon: 'Triathlon',
    Weightlifting: 'Weightlifting',
    Wrestling: 'Wrestling',
    GaelicHurling: 'GaelicHurling',
    Pool: 'Pool',
    Chess: 'Chess',
    GreyhoundRacing: 'GreyhoundRacing',
    HarnessRacing: 'HarnessRacing',
    Politics: 'Politics',
    Entertainment: 'Entertainment',
    RinkHockey: 'RinkHockey',
    Kabaddi: 'Kabaddi',
    RugbyLeague: 'RugbyLeague',
    RugbyUnion: 'RugbyUnion',
    Basketball3x3: 'Basketball3x3',
    Canoeing: 'Canoeing',
    MarathonSwimming: 'MarathonSwimming',
    ModernPenthatlon: 'ModernPenthatlon',
    Skateboarding: 'Skateboarding',
    Softball: 'Softball',
    Surfing: 'Surfing',
    Lacrosse: 'Lacrosse'
};
export const SportFilterKickoffPeriod = {
    ALL: 'ALL',
    TODAY: 'TODAY',
    TOMORROW: 'TOMORROW',
    AFTERTOMORROW: 'AFTERTOMORROW',
    CUSTOM: 'CUSTOM',
    ONEHOUR: 'ONEHOUR',
    TWOHOURS: 'TWOHOURS',
    THREEHOURS: 'THREEHOURS',
    SIXHOURS: 'SIXHOURS',
    TWELVEHOURS: 'TWELVEHOURS',
    TWENTYFOURHOURS: 'TWENTYFOURHOURS'
};
/** SPORT_FREEBET */ export const SportFreebetState = {
    ACTIVATED: 'ACTIVATED',
    WON: 'WON',
    LOST: 'LOST',
    LOST_EXPIRED: 'LOST_EXPIRED',
    LOST_REFUSED: 'LOST_REFUSED'
};
/** 
 * dataType - Fast_bet
 * dataGroup - Sportsbook
 **/ /** ### Sportsbook ### */ /** 
 * dataType - Sport_bet
 * dataGroup - Sportsbook
 **/ export const SportsbookUIVersion = {
    DEFAULT: 'DEFAULT',
    V1: 'V1'
};
export const SportWagerResult = {
    SUCCESSFUL: 'SUCCESSFUL',
    CANCELED_EXPIRED: 'CANCELED_EXPIRED',
    CANCELED_REFUSED: 'CANCELED_REFUSED',
    CANCELED_EMPTY_BALANCE: 'CANCELED_EMPTY_BALANCE',
    CANCELED_BY_ERROR: 'CANCELED_BY_ERROR'
};
export const StartCodeType = {
    REDIRECT: 'REDIRECT',
    HTML_INJECTION: 'HTML_INJECTION'
};
export const Status = {
    INIT: 'INIT',
    ERROR: 'ERROR',
    SUCCESS: 'SUCCESS',
    DATA_REQUESTED: 'DATA_REQUESTED',
    WAITING: 'WAITING'
};
export const StatusColor = {
    DEFAULT: 'DEFAULT',
    PENDING: 'PENDING',
    POSITIVE: 'POSITIVE',
    NEGATIVE: 'NEGATIVE',
    DEBIT_CREDIT: 'DEBIT_CREDIT'
};
/** Validation schema id */ /** UI dynamic form representation of the second and other steps if required */ /** Serialized json-schema dynamic form validating schema of the second and other steps if required */ /** Validation schema id */ /** if not null, then use this charset when redirecting */ /** if not null, then use this http method when redirecting */ /** 
 * if not null, then use Content-Type application/x-www-form-urlencoded and field's body
   * e.g. {"PAYMENT_URL": "https://pay.piastrix.com/result/qXcUpinMtmitsZvesx", ... }
 **/ /** remove the payment, previously called cancel because of LeonBets naming, in reality it's just remove the payment */ /** Resend SMS */ /** null if no tax is applied */ /** null if no tax is applied */ /** null if no tax is applied */ /** MAIN balance after withdrawal */ export const SupportType = {
    CHAT: 'CHAT',
    CALLBACK: 'CALLBACK'
};
/** requiredHeaders: X_APP_SKIN */ /** requiredHeaders: X_APP_LAYOUT, X_APP_PLATFORM */ export const Theme = {
    LIGHT: 'LIGHT',
    DARK: 'DARK',
    AUTO: 'AUTO',
    MIDNIGHT: 'MIDNIGHT'
};
export const ThirdPartyErrorCode = {
    TINKOFF_ACCESS_DENIED: 'TINKOFF_ACCESS_DENIED'
};
export const TimerMode = {
    MINUTES: 'MINUTES',
    SECONDS: 'SECONDS'
};
export const TopWinnersTabType = {
    EGS: 'EGS',
    SPORT: 'SPORT'
};
export const TradingStatus = {
    OPEN: 'OPEN',
    SUSPENDED: 'SUSPENDED',
    CLOSED: 'CLOSED'
};
export const TransactionGroup = {
    BET: 'BET',
    PAYMENT: 'PAYMENT',
    LEONSHOP: 'LEONSHOP',
    MG: 'MG',
    EGSOW: 'EGSOW',
    EGS: 'EGS',
    MARKETING: 'MARKETING'
};
export const TransactionStatusColor = {
    WHITE: 'WHITE',
    YELLOW: 'YELLOW',
    GREEN: 'GREEN',
    RED: 'RED'
};
export const TransferType = {
    WIN: 'WIN',
    BONUS: 'BONUS',
    WIN_PLUS_BONUS: 'WIN_PLUS_BONUS'
};
export const Tsupis1BindCustomerError = {
    TECHNICAL: 'TECHNICAL',
    OVERBOUND: 'OVERBOUND',
    ALREADY_BOUND: 'ALREADY_BOUND',
    INCORRECT_PERSONAL_DATA: 'INCORRECT_PERSONAL_DATA',
    LOCKED_CUSTOMER: 'LOCKED_CUSTOMER',
    INTERNAL: 'INTERNAL',
    TIMEOUT: 'TIMEOUT'
};
export const TsupisAuthResult = {
    AUTHENTICATION_SUCCESS: 'AUTHENTICATION_SUCCESS',
    APPLICATION_DISABLED: 'APPLICATION_DISABLED',
    IDENTIFICATION_REQUIRED: 'IDENTIFICATION_REQUIRED',
    SMS_CODE_INCORRECT: 'SMS_CODE_INCORRECT',
    SMS_CODE_EXPIRED: 'SMS_CODE_EXPIRED',
    PHONE_NUMBER_INCORRECT: 'PHONE_NUMBER_INCORRECT',
    MAX_ATTEMPTS_REACHED: 'MAX_ATTEMPTS_REACHED',
    ACCESS_TO_DEVICE_DISABLED: 'ACCESS_TO_DEVICE_DISABLED',
    BETTING_DISABLED: 'BETTING_DISABLED',
    USER_IS_NOT_AUTHORIZED: 'USER_IS_NOT_AUTHORIZED',
    PHONE_NUMBER_BLOCKED: 'PHONE_NUMBER_BLOCKED',
    INCORRECT_IDENT_DATA: 'INCORRECT_IDENT_DATA',
    IDENT_DATA_IS_NOT_VERIFIED: 'IDENT_DATA_IS_NOT_VERIFIED',
    PURSES_LIMIT_EXCEEDED: 'PURSES_LIMIT_EXCEEDED',
    PHONE_NUMBER_ALREADY_USED_BY_USER: 'PHONE_NUMBER_ALREADY_USED_BY_USER',
    DOCUMENT_EXPIRED: 'DOCUMENT_EXPIRED',
    INVALID_CITIZENSHIP: 'INVALID_CITIZENSHIP',
    INVALID_INN: 'INVALID_INN',
    INN_PROVIDER_IS_BUSY: 'INN_PROVIDER_IS_BUSY',
    INVALID_AUTH_DETAILS: 'INVALID_AUTH_DETAILS',
    WRONG_API_USAGE: 'WRONG_API_USAGE',
    REQUIRED_PARAMETER_IN_WRONG_FORMAT: 'REQUIRED_PARAMETER_IN_WRONG_FORMAT',
    TECH_ERROR: 'TECH_ERROR',
    UNKNOWN_ERROR: 'UNKNOWN_ERROR',
    CUSTOMER_CABIN_REGISTRATION_REQUIRED: 'CUSTOMER_CABIN_REGISTRATION_REQUIRED',
    CUSTOMER_PHONE_CHANGE_FORBIDDEN: 'CUSTOMER_PHONE_CHANGE_FORBIDDEN',
    CUSTOMER_NOT_UPDATED: 'CUSTOMER_NOT_UPDATED',
    CUSTOMER_REGPHONE_EXISTS: 'CUSTOMER_REGPHONE_EXISTS'
};
export const TsupisBindingRequestStatus = {
    NOT_CALLED: 'NOT_CALLED',
    PENDING: 'PENDING',
    DONE: 'DONE'
};
export const TsupisProviderType = {
    TSUPIS_2: 'TSUPIS_2',
    TSUPIS_1: 'TSUPIS_1'
};
export const UiAccountType = {
    MAIN: 'MAIN',
    CBC: 'CBC',
    SBC: 'SBC'
};
/** UI representation of a dynamic field */ /** Named id of the field. Currency for example */ /** Default value type */ /** UI field type */ /** Is the field hidden from a user, but frontend will still send this field to backend */ /** Translated title of the field */ /** Default value of the field */ /** 
 * Meaning nonEditable - disabled for the user. If the field is completely
   * disabled, then it should not appear in the list of shared fields
 **/ /** Additional translated text for the field */ /** Field defaultValue is encrypted, so encrypted symbols should be replaced by * when showing to user */ /** Meaning that the field must not be send to the backend from the frontend */ /** If previously filled value has encountered error and cannot be used in this field */ /** UI dynamic form representation */ export const UnknownCardTokenExceptionCode = {
    UNKNOWN_CARD_TOKEN_ERROR: 'UNKNOWN_CARD_TOKEN_ERROR'
};
export const UnknownRequestHashExceptionCode = {
    UNKNOWN_REQUEST_HASH: 'UNKNOWN_REQUEST_HASH'
};
export const UnsupportedBannerExceptionCode = {
    UNSUPPORTED_BANNER: 'UNSUPPORTED_BANNER'
};
/** ## updateIncompleteRegistrationFields */ export const ValidationSchema = {
    EXAMPLE: 'EXAMPLE',
    LOGIN: 'LOGIN',
    REGISTRATION_FULL_LEON_RU: 'REGISTRATION_FULL_LEON_RU',
    REGISTRATION_FULL_LRU: 'REGISTRATION_FULL_LRU',
    RESTORE_PASSWORD_SEND_SMS_TO_PHONE: 'RESTORE_PASSWORD_SEND_SMS_TO_PHONE',
    RESTORE_PASSWORD_SEND_LINK_TO_EMAIL: 'RESTORE_PASSWORD_SEND_LINK_TO_EMAIL',
    RESTORE_PASSWORD_BY_EMAIL: 'RESTORE_PASSWORD_BY_EMAIL',
    RESTORE_PASSWORD_BY_PHONE: 'RESTORE_PASSWORD_BY_PHONE',
    RESTORE_PASSWORD_CHECK_SMS: 'RESTORE_PASSWORD_CHECK_SMS',
    CUSTOMER_LIMITS: 'CUSTOMER_LIMITS',
    PERSONAL_DATA: 'PERSONAL_DATA',
    CHANGE_PASSWORD: 'CHANGE_PASSWORD',
    SUPPORT_CALLBACK: 'SUPPORT_CALLBACK',
    SUPPORT_FEEDBACK: 'SUPPORT_FEEDBACK'
};
export const VideoVerificationStatus = {
    NONE: 'NONE',
    PENDING: 'PENDING',
    IGNORED: 'IGNORED',
    INVITED: 'INVITED',
    NO_ANSWER: 'NO_ANSWER',
    SCHEDULED: 'SCHEDULED',
    ON_REVIEW: 'ON_REVIEW',
    PASSED: 'PASSED',
    NOT_PASSED: 'NOT_PASSED',
    REFUSED: 'REFUSED',
    MISSED: 'MISSED',
    ACCEPT_IGNORE: 'ACCEPT_IGNORE',
    ACCEPT_REFUSAL: 'ACCEPT_REFUSAL',
    UNKNOWN: 'UNKNOWN'
};
export const VirtualSportTournament = {
    FOOTBALL_WORLD_CUP: 'FOOTBALL_WORLD_CUP',
    BASKETBALL: 'BASKETBALL',
    FOOTBALL_EUROCUP: 'FOOTBALL_EUROCUP',
    FOOTBALL_LEAGUE_MODE: 'FOOTBALL_LEAGUE_MODE',
    FOOTBALL_NATIONS_CUP: 'FOOTBALL_NATIONS_CUP',
    TENNIS_INPLAY: 'TENNIS_INPLAY',
    FOOTBALL_CHAMPIONS_CUP: 'FOOTBALL_CHAMPIONS_CUP',
    FOOTBALL_BUNDESLIGA: 'FOOTBALL_BUNDESLIGA',
    CRICKET: 'CRICKET'
};
export const VpnProvider = {
    OPENVPN: 'OPENVPN',
    SHADOWSOCKS: 'SHADOWSOCKS'
};
/** dataGroup - Vsport */ /** ### VSport ### */ export const Web2FontFamily = {
    DEFAULT: 'DEFAULT',
    MONTSERRAT: 'MONTSERRAT'
};
/** deprecated */ /** This whatsnew popup was already shown to the user */ /** Only not shown keys are returned. requiredHeaders: X_APP_LAYOUT */ export const WidgetType = {
    BETRADAR: 'BETRADAR',
    LSPORTS: 'LSPORTS',
    BETGENIUS: 'BETGENIUS'
};
/** cancel the payment */ /** cancel all requested withdrawals */ /** Count of withdrawals in requested status that can be cancelled by a customer */ /** Total amount of withdrawals in requested status that can be cancelled by a customer */ /** Id of withdrawal payment in requested status that can be cancelled by a customer if there is the only one such payment */ export const ZetaDcpConfigType = {
    MAIN: 'MAIN',
    ALL: 'ALL',
    LANDING: 'LANDING',
    REGISTRATION: 'REGISTRATION',
    DEPOSIT: 'DEPOSIT',
    FIRST_TIME_DEPOSIT: 'FIRST_TIME_DEPOSIT'
};
export const LeagueStatisticsTeamFieldsFragmentDoc = `
    fragment LeagueStatisticsTeamFields on League24Team {
  id
  name
  logo
  sport
  country
}
    `;
export const LeagueStandingFieldsFragmentDoc = `
    fragment LeagueStandingFields on LeagueStanding {
  leagueTableId
  leagueTableName
  leagueTableRound
  leagueTableType
  seasonName
  seasonYear
  drawTotal
  winTotal
  lossTotal
  matchesTotal
  pointsTotal
  goalsTotal
  promotionId
  promotionName
  promotionIdLive
  promotionNameLive
  sortPositionTotal
  team {
    ...LeagueStatisticsTeamFields
  }
}
    ${LeagueStatisticsTeamFieldsFragmentDoc}`;
export const MatchStatisticsTeamFieldsFragmentDoc = `
    fragment MatchStatisticsTeamFields on MatchStatisticsEventTeam {
  id
  name
  type
  country
  logo
}
    `;
export const MatchStatisticsMatchFieldsFragmentDoc = `
    fragment MatchStatisticsMatchFields on MatchStatisticsEventData {
  id
  gameScore
  resultScore
  resultScores {
    type
    value
  }
  tournamentName
  winner
  status
  matchDate
  teams {
    ...MatchStatisticsTeamFields
  }
}
    ${MatchStatisticsTeamFieldsFragmentDoc}`;
export const MatchStatisticsPlayerFieldsFragmentDoc = `
    fragment MatchStatisticsPlayerFields on MatchStatisticsPlayer {
  shirtNumber
  playerName
  playerShortName
}
    `;
export const Scores24ScorerFieldsFragmentDoc = `
    fragment Scores24ScorerFields on Scores24Scorer {
  matchesPlayed
  ranking
  position
  positionFull
  positionShort
  name
  value
  player {
    logo
    playerName
    playerShortName
    shirtNumber
  }
  team {
    name
    logo
    sport
    country
  }
}
    `;
export const Scores24TeamFieldsFragmentDoc = `
    fragment Scores24TeamFields on MatchStatisticsTeam {
  id
  name
  logo
  sport
  country
}
    `;
export const EgsCustomerBonusFragmentDoc = `
    fragment EgsCustomerBonus on CustomerBonus {
  campaignId
  campaignType
  campaignName
  currency
  bonusId
  bonusState
  availableBonusType
  bonusAmount
  maxBonusAmount
  transferAmount
  refuseAvailable
  pendingRefuse
  step
  stepNumber
  stepCount
  availableBefore
  bonusNumber
  maxBonusCount
  actionUrl
  categoryId
  termsKey
  requirements {
    name
    totalPlacedBets
    progressBarValue
    type
    requiredValue
    currentValue
    valueType
    satisfied
    waitingLastBetResult
  }
  freebet {
    amount
    totalAvailable
    totalActivated
    totalResulted
  }
  freespin {
    count
    usedCount
    nominal
    totalCount
    provider
    gameIds
  }
}
    `;
export const SportCustomerBonusFragmentDoc = `
    fragment SportCustomerBonus on CustomerBonus {
  campaignId
  campaignType
  campaignName
  currency
  bonusId
  bonusState
  availableBonusType
  bonusAmount
  maxBonusAmount
  transferAmount
  refuseAvailable
  pendingRefuse
  step
  stepNumber
  stepCount
  availableBefore
  bonusNumber
  maxBonusCount
  actionUrl
  categoryId
  termsKey
  requirements {
    name
    totalPlacedBets
    progressBarValue
    type
    requiredValue
    currentValue
    valueType
    satisfied
    waitingLastBetResult
  }
  freebet {
    amount
    totalAvailable
    totalActivated
    totalResulted
  }
  freespin {
    count
    usedCount
    nominal
    totalCount
  }
}
    `;
export const Web2ImageFieldsFragmentDoc = `
    fragment Web2ImageFields on Web2Image {
  src
  x1
  x2
  x3
  x1webp
  x2webp
  x3webp
}
    `;
export const BannerContentFieldsFragmentDoc = `
    fragment BannerContentFields on PromoBannerData {
  headline
  secondaryText
  buttonText
  image {
    ...Web2ImageFields
  }
  imageMiddle {
    ...Web2ImageFields
  }
  bgImage {
    ...Web2ImageFields
  }
  bgImageMiddle {
    ...Web2ImageFields
  }
  money {
    currency
    value
  }
  url
  bgColor
  animation
  buttonIconName
}
    ${Web2ImageFieldsFragmentDoc}`;
export const BannerSportEventFragmentDoc = `
    fragment BannerSportEvent on BetlineEvent {
  betline
  id
  kickoff
  status
  url
  matchPhase
  name
  open
  markets {
    id
    name
    cols
    handicap
    hasZeroMargin
    marketTypeId
    open
    primary
    runners {
      open
      handicap
      name
      id
      c
      price
      priceStr
      r
      tags
    }
  }
  liveStatus {
    stage
    score
    detailedPhase
    setScores
    progress
    progressSetupEnabled
  }
  competitors {
    homeAway
    id
    name
  }
}
    `;
export const BannerDataFragmentDoc = `
    fragment BannerData on Banner {
  id
  bannerId
  pathType
  location
  customLocationId
  content {
    ...BannerContentFields
  }
  bannerType
  event {
    ...BannerSportEvent
  }
  isNT
  isTextShadowHidden
  textColor
  isNewBannerView
}
    ${BannerContentFieldsFragmentDoc}
${BannerSportEventFragmentDoc}`;
export const CustomerDataPhoneFieldsFragmentDoc = `
    fragment CustomerDataPhoneFields on CustomerData {
  phone
  phoneCountryIsoCode
}
    `;
export const CustomerDataFieldsFragmentDoc = `
    fragment CustomerDataFields on CustomerData {
  ...CustomerDataPhoneFields
  lastDepositedSystemId
  customerLogin
  language
  country
  countryName
  exAllowedCountry
  forbiddenCountry
  city
  address
  isCCUseAllowed
  registrationType
  isRegistrationFinish
  unreadMessagesCount
  balance
  currency
  currencyFormatted {
    key
    value
  }
  priceChangePolicy
  hasFavGames
  email
  emailSubscription
  verificationNeeded
  verificationActive
  isSimpleRegistrant
  isSelfExcluded
  selfExclusionUntil
  selfExclusionMessage
  fullName
  isVip
  vipStatus
  useStandardBet
  standardBetAmount
  sameStakeForSingleByDefault
  showSlipOnFirstAdded
  fastBets {
    enabled
    values
    maxValue
    minValue
    showMax
  }
  historyFilterOptions {
    order
    dateType
    dateRange
    btrTitle
    isDefault
    title
  }
  customSettings {
    settingName
    settingValue
  }
  ident {
    canGamble
  }
  g2svFeatureStatus
  g2svCustomerStatus
  affiliatePartnerStatus
  isCountryConfirmed
  additionalRegistrationNeeded
  personalAppDomain
  personalDomainData {
    name
    type
    priority
    isShowPopup
  }
  showVerification
  fastTrackToken
  verificationType
  verificationStatus
  verificationLevel
  additionalVerificationNeeded
  isEkycRequestable
  isEmailConfirmed
  isAppsFlyerEnabled
  isAppsFlyerDepositQualified
  isHiddenBalance
  nickname
  userLanguageTag
  createPinCodeModalCounter
  isPinCodeRequestedOnLogin
  videoVerificationStatus
  videoVerificationStatusExpiration
  isVideoVerificationOnboardingShown
  latestRequestedVerificationType
  customerConfig {
    hideCommissionHint {
      value
      key
    }
    isHideOnBoardingPage {
      value
      key
    }
    isHideEgsLoyaltyOnboardingPage {
      value
      key
    }
    isHiddenBalance {
      value
      key
    }
    isNicknamePromptHidden {
      value
      key
    }
    isHideOnBoardingOnLeonShopPage {
      value
      key
    }
    isBonusChooserShownBeforeDeposits {
      value
      key
    }
    hideNoSelectedBonusModal {
      value
      key
    }
    isHideLimitModalOnGamePage {
      value
      key
    }
    createPinCodeModalCounter {
      value
      key
    }
    isPinCodeRequestedOnLogin {
      value
      key
    }
    isVideoVerificationOnboardingShown {
      value
      key
    }
    isReferralProgramOnboardingShown {
      value
      key
    }
    isFortuneWheelWindowShown {
      value
      key
    }
    isReferralProgramNotificationClicked {
      value
      key
    }
    isResponsibleGamblingOnboardingShown {
      value
      key
    }
    isOddsTypeAmerican {
      value
      key
    }
    isOddsTypeClassic {
      value
      key
    }
    isEkycPopupOnFirstLoginShown {
      value
      key
    }
  }
  isAsoTermsConfirmationNeeded
  customerBetType
  customerOddsType
  shortComments
}
    ${CustomerDataPhoneFieldsFragmentDoc}`;
export const EgsSkeletonConfigFieldsFragmentDoc = `
    fragment EgsSkeletonConfigFields on EgsSkeletonConfig {
  typeId
  widgetId
  gridType
  swiperRowsNumber
  isSwiperBlock
  titleType
}
    `;
export const SlipInfoFieldsFragmentDoc = `
    fragment SlipInfoFields on SlipInfo {
  status
  maxSlipSize
  maxSystemSize
  maxBankers
  minStake
  maxStake
  totalOdds
  totalOddsStr
  totalOddsWithoutAccumulatorBoost
  totalOddsWithoutAccumulatorBoostStr
  accumulatorBoost
  entries {
    event
    market
    runner
    odds
    oddsStr
    banker
    marketStatus
    betline
    zeroMargin
    ts
  }
  message
  slipType
  slipTypes {
    code
    typeId
    shortName
    name
  }
  freeBets {
    name
    amount
    oddsValid
    freeBetId
    promoActionUrl
    promoActionTermsPage
    promoActionCategoryId
    transferType
  }
  trace {
    lines {
      timestamp
      error
      contents {
        typeId
        data
        name
      }
    }
  }
}
    `;
export const IdentStateFieldsFragmentDoc = `
    fragment IdentStateFields on IdentState {
  identificationLevel
  identificationStage
  identificationStatus
  identificationStatusOrder
  isIdentBarVisible
  canGamble
  gambleStatus
  form {
    passport {
      src
      fileName
      status
    }
  }
  esiaLoginData {
    status
    loginError
  }
  manualCustomerData {
    lastName
    firstName
    middleName
    docType
    docNr
    birthDate
    inn
    status
    innApiAttemptsCounter {
      totalAttempts
      attemptsMade
      lockTimeout
      isLocked
    }
  }
  blocked
  incorrectType
  identHubConfig {
    defaultMethod
    orderedMethods
  }
  simpleIdentDefaultType
  confirmedPhoneAuth
  phoneChangeAllowed
  phoneIdentificationRequired
  tsupis1CustomerError
  tsupis2ErrorCode
  tsupisBindingRequestStatus
  esiaResultPending
  isPassportExpired
  thirdPartyErrorCode
}
    `;
export const AbstractLobbyItemFieldsFragmentDoc = `
    fragment AbstractLobbyItemFields on AbstractLobbyItem {
  name
  typeId
  widgetId
  allButtonText
  widgetOptions {
    swiperRowsNumber
    gridType
    isSwiperAuto
    isSwiperBlock
  }
  iconUrl {
    dark
    light
  }
  titleType
  redirectPath
}
    `;
export const EgsGroupFieldsFragmentDoc = `
    fragment EgsGroupFields on EgsGroup {
  id
  name
  url
  iconUrls {
    dark
    light
  }
  metaDescription
  type
  ntBadge
  blockType
  color
}
    `;
export const EgsImagesFieldsFragmentDoc = `
    fragment EgsImagesFields on EgsImages {
  preview {
    ...Web2ImageFields
  }
  background {
    ...Web2ImageFields
  }
  phoneGamePage {
    ...Web2ImageFields
  }
  squareImage {
    ...Web2ImageFields
  }
  jackpot {
    ...Web2ImageFields
  }
}
    ${Web2ImageFieldsFragmentDoc}`;
export const EgsGameCategoriesFieldsFragmentDoc = `
    fragment EgsGameCategoriesFields on EgsCategory {
  id
  name
  iconUrl
  url
  type
  nameEn
}
    `;
export const EgsGameFieldsFragmentDoc = `
    fragment EgsGameFields on EgsGame {
  id
  name
  url
  group {
    ...EgsGroupFields
  }
  description
  metaDescription
  isFavorite
  hasDemo
  isNew
  images {
    ...EgsImagesFields
  }
  categories {
    ...EgsGameCategoriesFields
  }
  statistics {
    name
    value
  }
  isOneWalletSystem
  typeId
  openMethod
  isUnavailable
  blockType
  jackpot {
    amount {
      currency
      value
    }
  }
  availability {
    status
    players {
      current
      total
    }
  }
  limits {
    min {
      currency
      value
    }
    max {
      currency
      value
    }
  }
  providerId
  isVpnAllowed
  shortDescription
  ntBadge
  exclusive
}
    ${EgsGroupFieldsFragmentDoc}
${EgsImagesFieldsFragmentDoc}
${EgsGameCategoriesFieldsFragmentDoc}`;
export const EgsTopWinnerFieldsFragmentDoc = `
    fragment EgsTopWinnerFields on EgsTopWinner {
  egsGame {
    ...EgsGameFields
  }
  winAmount {
    currency
    value
  }
  player {
    name
    login
  }
}
    ${EgsGameFieldsFragmentDoc}`;
export const PaginationCursorItemFieldsFragmentDoc = `
    fragment PaginationCursorItemFields on PaginationCursorItem {
  limit
  offset
  total
}
    `;
export const LobbyAllTopWinnersItemFieldsFragmentDoc = `
    fragment LobbyAllTopWinnersItemFields on LobbyAllTopWinnersItem {
  egsTopWinners {
    winners {
      ...EgsTopWinnerFields
    }
    next {
      ...PaginationCursorItemFields
    }
  }
  sportTopWinners {
    winners {
      odds
      sportBetEvents {
        eventId
        eventName
      }
      winAmount {
        currency
        value
      }
      player {
        name
        login
      }
    }
    next {
      ...PaginationCursorItemFields
    }
  }
  primaryTab
}
    ${EgsTopWinnerFieldsFragmentDoc}
${PaginationCursorItemFieldsFragmentDoc}`;
export const LobbyBannerItemFieldsFragmentDoc = `
    fragment LobbyBannerItemFields on LobbyBannersItem {
  banners {
    ...BannerContentFields
  }
}
    ${BannerContentFieldsFragmentDoc}`;
export const LobbyGamesCategoryItemFieldsFragmentDoc = `
    fragment LobbyGamesCategoryItemFields on LobbyGamesCategoryItem {
  category {
    ...EgsGameCategoriesFields
  }
  games {
    ...EgsGameFields
  }
  next {
    ...PaginationCursorItemFields
  }
}
    ${EgsGameCategoriesFieldsFragmentDoc}
${EgsGameFieldsFragmentDoc}
${PaginationCursorItemFieldsFragmentDoc}`;
export const LobbyGroupsItemFieldsFragmentDoc = `
    fragment LobbyGroupsItemFields on LobbyGroupsItem {
  groups {
    ...EgsGroupFields
  }
}
    ${EgsGroupFieldsFragmentDoc}`;
export const LobbyPromotionsItemFieldsFragmentDoc = `
    fragment LobbyPromotionsItemFields on LobbyPromotionsItem {
  url
  promotions {
    ...BannerContentFields
  }
}
    ${BannerContentFieldsFragmentDoc}`;
export const LobbySportlineTopMatchesItemFieldsFragmentDoc = `
    fragment LobbySportlineTopMatchesItemFields on LobbySportlineTopMatchesItem {
  events
  flags
  ctag
  vtag
}
    `;
export const LobbyTopWinnersItemFieldsFragmentDoc = `
    fragment LobbyTopWinnersItemFields on LobbyTopWinnersItem {
  category {
    ...EgsGameCategoriesFields
  }
  filter
  gameType
  winners {
    ...EgsTopWinnerFields
  }
  next {
    ...PaginationCursorItemFields
  }
}
    ${EgsGameCategoriesFieldsFragmentDoc}
${EgsTopWinnerFieldsFragmentDoc}
${PaginationCursorItemFieldsFragmentDoc}`;
export const AlcrNotificationFieldsFragmentDoc = `
    fragment AlcrNotificationFields on AlcrNotification {
  title
  message
  ctaButtonLink
  ctaButtonText
  imageUrl
  displayType
}
    `;
export const BonusActivationNotificationFieldsFragmentDoc = `
    fragment BonusActivationNotificationFields on BonusActivationNotification {
  amount
  depositAmount
  depositTransferAmount
  currency
  actionUrl
  categoryId
}
    `;
export const CryptoDepositStatusNotificationFieldsFragmentDoc = `
    fragment CryptoDepositStatusNotificationFields on CryptoDepositStatusNotification {
  amountStr
  currency
  initialTimestamp
}
    `;
export const DepositNotificationFieldsFragmentDoc = `
    fragment DepositNotificationFields on DepositNotification {
  amount
  currency
  date
}
    `;
export const FrontNotificationFieldsFragmentDoc = `
    fragment FrontNotificationFields on FrontNotification {
  id
  timestamp
  type
  isClicked
  isDelivered
  isRead
  delay
}
    `;
export const RefProgramNotificationFieldsFragmentDoc = `
    fragment RefProgramNotificationFields on RefProgramNotification {
  payload {
    type
    ... on ProgramAvailable {
      programId
      referrerId
    }
    ... on BonusEligibilityRules {
      currency
      rewards {
        depositAmount
        rewardAmount {
          playerReward {
            count
            nominalValue
            amount
            rewardType
          }
          referralReward {
            count
            nominalValue
            amount
            rewardType
          }
        }
      }
      referralRequirements {
        type
        satisfied
        requiredValue
        currency
      }
    }
    ... on ReferralRewardAssigned {
      referrerId
      referralId
      programId
      reward {
        count
        nominalValue
        amount
        rewardType
      }
    }
    ... on ReferralRewardAssignedReferrerNotification {
      programId
      reward {
        count
        nominalValue
        amount
        rewardType
      }
    }
  }
}
    `;
export const VideoNotificationFieldsFragmentDoc = `
    fragment VideoNotificationFields on VideoVerificationNotification {
  status
  message
}
    `;
export const getBetlineLeagueStandingsDocument = `
    query getBetlineLeagueStandings($options: LeagueStandingsRequest!) {
  queries {
    betLine {
      getLeagueStandings(input: $options) {
        ts
        data {
          standings {
            ...LeagueStandingFields
          }
        }
      }
    }
  }
}
    ${LeagueStandingFieldsFragmentDoc}`;
export const getBetlineLeagueStatusDocument = `
    query getBetlineLeagueStatus($options: LeagueIdRequest!) {
  queries {
    betLine {
      getLeagueStatus(input: $options) {
        ts
        data {
          enabledEntities
        }
      }
    }
  }
}
    `;
export const getBetlineLeagueTopPlayersDocument = `
    query getBetlineLeagueTopPlayers($options: LeagueIdRequest!) {
  queries {
    betLine {
      getLeagueTopPlayersByLeagueId(input: $options) {
        ts
        data {
          assists {
            ...Scores24ScorerFields
          }
          scorers {
            ...Scores24ScorerFields
          }
        }
      }
    }
  }
}
    ${Scores24ScorerFieldsFragmentDoc}`;
export const getBetlineMatchEventStatisticsDocument = `
    query getBetlineMatchEventStatistics($options: MatchStatisticsEventRequest!) {
  queries {
    betLine {
      getMatchStatisticsEvent(input: $options) {
        ts
        data {
          id
          matchDate
          family
          teams {
            ...MatchStatisticsTeamFields
          }
          joinMatches {
            ...MatchStatisticsMatchFields
          }
          homeMatches {
            ...MatchStatisticsMatchFields
          }
          awayMatches {
            ...MatchStatisticsMatchFields
          }
          scores24Head2Head {
            firstMatchYear
            draws
            total
            goals
            wins
          }
        }
      }
    }
  }
}
    ${MatchStatisticsTeamFieldsFragmentDoc}
${MatchStatisticsMatchFieldsFragmentDoc}`;
export const getBetlineMatchStatisticsDocument = `
    query getBetlineMatchStatistics($options: MatchStatisticsRequest!) {
  queries {
    betLine {
      getMatchStatistics(input: $options) {
        ts
        data {
          id
          matchDate
          family
          resultScore
          teams {
            ...MatchStatisticsTeamFields
          }
          cards {
            team
            time
            color
            playerDto {
              ...MatchStatisticsPlayerFields
            }
          }
          goals {
            type
            awayTeamScore
            homeTeamScore
            time
            team
            assists
            disabled
            playerDto {
              ...MatchStatisticsPlayerFields
            }
          }
          scores {
            type
            value
          }
          substitutions {
            team
            time
            incoming {
              ...MatchStatisticsPlayerFields
            }
            outgoing {
              ...MatchStatisticsPlayerFields
            }
          }
          periods {
            type
            groups {
              type
              items {
                type
                name
                firstTeamPercent
                firstTeamValue
                firstTeamTotal
                secondTeamPercent
                secondTeamTotal
                secondTeamValue
              }
            }
          }
        }
      }
    }
  }
}
    ${MatchStatisticsTeamFieldsFragmentDoc}
${MatchStatisticsPlayerFieldsFragmentDoc}`;
export const getLeagueDataDocument = `
    query getLeagueData($options: LeagueIdRequest!) {
  queries {
    betLine {
      getLeagueData(input: $options) {
        ts
        data {
          id
          family
          country {
            logo
            name
            iso
          }
          currentSeasonStart
          currentSeasonEnd
          currentSeasonYear
          logoUrl
          background {
            src {
              ...Web2ImageFields
            }
          }
        }
      }
    }
  }
}
    ${Web2ImageFieldsFragmentDoc}`;
export const getLeaguePlayoffByLeagueIdDocument = `
    query getLeaguePlayoffByLeagueId($options: LeagueIdRequest!) {
  queries {
    betLine {
      getLeaguePlayoffByLeagueId(input: $options) {
        ts
        data {
          id
          name
          currentCupRound
          rounds {
            order
            description
            blocks {
              blockId
              blockName
              order
              matchesInRound
              result
              matches {
                ...MatchStatisticsMatchFields
              }
              participants {
                winner
                team {
                  ...Scores24TeamFields
                }
              }
            }
          }
        }
      }
    }
  }
}
    ${MatchStatisticsMatchFieldsFragmentDoc}
${Scores24TeamFieldsFragmentDoc}`;
export const getLeagueStandingsByLeagueIdDocument = `
    query getLeagueStandingsByLeagueId($options: LeagueIdRequest!) {
  queries {
    betLine {
      getLeagueStandingsByLeagueId(input: $options) {
        ts
        data {
          standings {
            ...LeagueStandingFields
          }
        }
      }
    }
  }
}
    ${LeagueStandingFieldsFragmentDoc}`;
export const getLeagueStatisticsByLeagueIdDocument = `
    query getLeagueStatisticsByLeagueId($options: LeagueIdRequest!) {
  queries {
    betLine {
      getLeagueStatisticsByLeagueId(input: $options) {
        ts
        data {
          average
          sum
          overallGoals
          overallResults
        }
      }
    }
  }
}
    `;
export const doCalculatePackDocument = `
    query doCalculatePack($options: CalculatePackRequest!) {
  mutations {
    bonusGame {
      calculatePack(input: $options) {
        ts
        data {
          state
        }
      }
    }
  }
}
    `;
export const doCalculateRoundDocument = `
    query doCalculateRound($options: CalculateRoundRequest!) {
  mutations {
    bonusGame {
      calculateRound(input: $options) {
        ts
        data {
          roundId
          calculatedAt
          rewardId
          details {
            rewardType
            freespinCount
            bonusAmount
            customerCurrency
            bonusPointMultiplier
            game {
              id
              name
            }
          }
        }
      }
    }
  }
}
    `;
export const doCollectPackRewardDocument = `
    query doCollectPackReward($options: CollectPackRewardRequest!) {
  mutations {
    bonusGame {
      collectPackReward(input: $options) {
        ts
        data {
          state
        }
      }
    }
  }
}
    `;
export const getAllPacksDocument = `
    query getAllPacks($options: CustomerPackRequest!) {
  queries {
    bonusGame {
      getAllPacks(input: $options) {
        ts
        data {
          packs {
            calculated
            closedAt
            collected
            day
            dayNumber
            startedAt
            state
            visualDetails {
              image {
                src
                x1
                x1webp
                x2
                x2webp
                x3
                x3webp
              }
              text
              title
            }
          }
        }
      }
    }
  }
}
    `;
export const getPacksDocument = `
    query getPacks($options: CustomerPackRequest!) {
  queries {
    bonusGame {
      getPacks(input: $options) {
        ts
        data {
          campaignClosedAt
          closedAt
          day
          nextPackStartedAt
          state
          rewards {
            collected
            rewardId
            type
            bonusDetails {
              bonusAmount
              bonusAmountPercent
              currency
              freespinCount
              freespinGameIds
              maxBonusAmount
              termsOfActions
            }
            visualDetails {
              image {
                src
                x1
                x1webp
                x2
                x2webp
                x3
                x3webp
              }
              text
              title
            }
          }
        }
      }
    }
  }
}
    `;
export const getRoundsDocument = `
    query getRounds($options: RoundsRequest!) {
  queries {
    bonusGame {
      getRounds(input: $options) {
        ts
        data {
          key
          round {
            roundId
            campaignStartDate
            campaignEndDate
            actualRoundStartDate
            actualRoundNumber
            roundsCount
            deposit
            canPurchaseAdditionalRound
            dayNumber
            active
            rewards {
              rewardId
              order
              type
              imageData {
                src
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const doConfirmCashbackDocument = `
    query doConfirmCashback {
  mutations {
    cashback {
      confirmCashback {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
export const doRefuseCashbackDocument = `
    query doRefuseCashback {
  mutations {
    cashback {
      refuseCashback {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
export const getCashbackDocument = `
    query getCashback {
  queries {
    cashback {
      getCashback {
        ts
        data {
          cashback {
            campaignStartDate
            campaignEndDate
            actualRoundEndDate
            actualRoundStartDate
            confirmation {
              confirmationState
              confirmedAt
              canceled_at
              declineReason
            }
            rounds {
              roundStartDate
              roundEndDate
              cashbackAmount
            }
            currency
            actionUrl
            categoryId
            suspended
            wagerProgress {
              bonusId
              currentAmount
              requiredAmount
            }
          }
        }
      }
    }
  }
}
    `;
export const addFavoriteGameDocument = `
    query addFavoriteGame($options: ChangeFavoriteGamesRequest!) {
  mutations {
    externalGames {
      favoriteGames {
        add(input: $options) {
          ts
          data {
            result
          }
        }
      }
    }
  }
}
    `;
export const getCasinoLoyaltyStatusDocument = `
    query getCasinoLoyaltyStatus {
  queries {
    bonuses {
      getCasinoLoyaltyStatus {
        ts
        data {
          id
          name
          currentPoints
          multiplier
          pointsUntilNextLevel
          levelSettings {
            id
            name
            description
            imageUrl
            background
            settingsBackground
            order
            requiredPoints
            multiplier
            freespinCount
            bonusPointsCount
            bonusAmount
            image {
              ...Web2ImageFields
            }
            freespinAvailable
            freespinSpent
            freespinGameId
            freespinProvider
          }
          hideEgsLoyaltyOnboardingPage
        }
      }
    }
  }
}
    ${Web2ImageFieldsFragmentDoc}`;
export const getPromotionsByEgsGameDocument = `
    query getPromotionsByEgsGame($options: GetPromotionsByEgsGameRequest!) {
  queries {
    promotions {
      getPromotionsByEgsGame(input: $options) {
        ts
        data {
          result
          promotions {
            actionUrl
            endDateNumber
          }
        }
      }
    }
  }
}
    `;
export const getVpnConfigDocument = `
    query getVpnConfig($options: GetEgsGameRequest!) {
  queries {
    externalGames {
      getVpnConfigs(input: $options) {
        ts
        data {
          vpnConfigs {
            configuration
            login
            password
            provider
          }
        }
      }
    }
  }
}
    `;
export const removeFavoriteGameDocument = `
    query removeFavoriteGame($options: ChangeFavoriteGamesRequest!) {
  mutations {
    externalGames {
      favoriteGames {
        remove(input: $options) {
          ts
          data {
            result
          }
        }
      }
    }
  }
}
    `;
export const checkPushTokenExistsDocument = `
    query checkPushTokenExists($options: NotificationCheckQueryRequest!) {
  queries {
    customer {
      notifications {
        check(input: $options) {
          ts
          data {
            result
          }
        }
      }
    }
  }
}
    `;
export const clearCookiesDocument = `
    query clearCookies($options: ClearCookieRequest!) {
  mutations {
    cookies {
      clearCookies(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
export const doMarkDeviceUntrustedDocument = `
    query doMarkDeviceUntrusted($options: MarkDeviceUntrustedRequest!) {
  mutations {
    customer {
      markDeviceUntrusted(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
export const doModifyTimeZoneDocument = `
    query doModifyTimeZone($options: ModifyTimeZoneRequest!) {
  mutations {
    customer {
      modifyTimeZone(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
export const doRefuseBonusDocument = `
    query doRefuseBonus($options: RefuseBonusRequest!) {
  mutations {
    bonuses {
      refuseBonus(input: $options) {
        ts
        data {
          result
          refuseType
          writeOffAmount
          returnDepositAmount
          freebetAmount
          freespinCount
          currency
        }
      }
    }
  }
}
    `;
export const getBonusMatrixDocument = `
    query getBonusMatrix($options: BonusMatrixRequest!) {
  queries {
    bonuses {
      getBonusMatrix(input: $options) {
        ts
        data {
          data {
            requirementType
            name
            requiredValue
            currentValue
            valueType
            satisfied
            bonusAmount
            freespin {
              count
              nominal
            }
          }
        }
      }
    }
  }
}
    `;
export const getCustomerBonusesDocument = `
    query getCustomerBonuses($options: CustomerBonusesRequest!) {
  queries {
    bonuses {
      getCustomerBonuses(input: $options) {
        ts
        data {
          sport {
            ...SportCustomerBonus
          }
          egs {
            ...EgsCustomerBonus
          }
          hideOnBoardingPage
        }
      }
    }
  }
}
    ${SportCustomerBonusFragmentDoc}
${EgsCustomerBonusFragmentDoc}`;
export const saveBetSlipConfigurationDocument = `
    query saveBetSlipConfiguration($options: SaveBetSlipConfigurationRequest!) {
  mutations {
    customer {
      slip {
        saveBetSlipConfiguration(input: $options) {
          ts
          data {
            result
          }
        }
      }
    }
  }
}
    `;
export const customerIdentityCheckDocument = `
    query customerIdentityCheck($options: CustomerIdentityCheckRequest!) {
  queries {
    customerIdentity {
      customerIdentityCheck(input: $options) {
        ts
        data {
          result
          isTrusted
        }
      }
    }
  }
}
    `;
export const disableCustomerModeDocument = `
    query disableCustomerMode($options: G2SVCodeRequest!) {
  mutations {
    g2sv {
      disableCustomerMode(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
export const doActivateAffiliateProgramDocument = `
    query doActivateAffiliateProgram($options: ActivateAffiliateProgramRequest!) {
  mutations {
    affiliate {
      activateAffiliateProgram(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
export const doActivateBonusCodeDocument = `
    query doActivateBonusCode($options: JoinByBonusCodeRequest!) {
  mutations {
    bonuses {
      joinByBonusCode(input: $options) {
        ts
        data {
          result
          campaign {
            actionUrl
            name
            categoryId
          }
        }
      }
    }
  }
}
    `;
export const doAddLoginDeviceDocument = `
    query doAddLoginDevice($options: AddLoginDeviceRequest!) {
  mutations {
    customer {
      addLoginDevice(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
export const doAppsFlyerQualifiedDepositsReportedDocument = `
    query doAppsFlyerQualifiedDepositsReported($options: EmptyMutationRequest!) {
  mutations {
    finance {
      setDepositMetricsNotified(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
export const doCashOutDocument = `
    query doCashOut($options: BetCashOutRequest!) {
  mutations {
    bet {
      doCashOut(input: $options) {
        ts
        data {
          status
          amount
          msg
          newAmount
          reason
          remainingDelay
        }
      }
    }
  }
}
    `;
export const doChangeCustomerNicknameDocument = `
    query doChangeCustomerNickname($options: ChangeNicknameRequest!) {
  mutations {
    customer {
      changeNickname(input: $options) {
        ts
        data {
          msg
          result
        }
      }
    }
  }
}
    `;
export const doChangeCustomerSettingsDocument = `
    query doChangeCustomerSettings($options: ChangeCustomSettingsRequest!) {
  mutations {
    customer {
      changeCustomerSettings(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
export const doChangeLanguageDocument = `
    query doChangeLanguage($options: ChangeLanguageRequest!) {
  mutations {
    system {
      changeLanguage(input: $options) {
        ts
        data {
          currentLanguageTag
          currentLang
          urlLocale
        }
      }
    }
  }
}
    `;
export const doChangePasswordDocument = `
    query doChangePassword($options: ChangePasswordRequest!) {
  mutations {
    customer {
      changePassword(input: $options) {
        ts
        data {
          msg
          result
        }
      }
    }
  }
}
    `;
export const doConfirmBonusDocument = `
    query doConfirmBonus {
  mutations {
    bonuses {
      confirm {
        ts
        data {
          confirmed
        }
      }
    }
  }
}
    `;
export const doCreateBonusCodeDocument = `
    query doCreateBonusCode($options: CreateBonusCodeRequest!) {
  mutations {
    affiliate {
      createBonusCode(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
export const doEncashBonusDocument = `
    query doEncashBonus($options: EncashBonusRequest!) {
  mutations {
    finance {
      encashBonus(input: $options) {
        ts
        data {
          msg
          result
        }
      }
    }
  }
}
    `;
export const doEventSubscribeDocument = `
    query doEventSubscribe($options: BetLineChangeSubscribeRequest!) {
  mutations {
    betLine {
      change {
        eventSubscribe(input: $options) {
          ts
          data {
            result
          }
        }
      }
    }
  }
}
    `;
export const doEventUnsubscribeDocument = `
    query doEventUnsubscribe($options: BetLineChangeUnsubscribeRequest!) {
  mutations {
    betLine {
      change {
        eventUnsubscribe(input: $options) {
          ts
          data {
            result
          }
        }
      }
    }
  }
}
    `;
export const doFavoriteSportEventRemoveDocument = `
    query doFavoriteSportEventRemove($options: ChangeCustomerMatchRequest!) {
  mutations {
    customer {
      matches {
        removeMatch(input: $options) {
          ts
          data {
            count
          }
        }
      }
    }
  }
}
    `;
export const doFavoriteSportEventsAddDocument = `
    query doFavoriteSportEventsAdd($options: ChangeCustomerMatchesRequest!) {
  mutations {
    customer {
      matches {
        addMatches(input: $options) {
          ts
          data {
            count
          }
        }
      }
    }
  }
}
    `;
export const doLoginDocument = `
    query doLogin($options: LoginInput!) {
  mutations {
    auth {
      login(loginInput: $options) {
        ts
        data {
          phoneIdentificationRequired
          showFortuneWheelWindow
        }
      }
    }
  }
}
    `;
export const doLoginCasDocument = `
    query doLoginCas($options: LoginSsoInput!) {
  mutations {
    auth {
      loginSso(loginInput: $options) {
        ts
        data {
          phoneIdentificationRequired
          showFortuneWheelWindow
        }
      }
    }
  }
}
    `;
export const doLoginFingerprintDocument = `
    query doLoginFingerprint($options: LoginFingerprintInput!) {
  mutations {
    auth {
      loginFingerprint(loginInput: $options) {
        ts
        data {
          phoneIdentificationRequired
          showFortuneWheelWindow
        }
      }
    }
  }
}
    `;
export const doLoginG2svDocument = `
    query doLoginG2sv($options: LoginG2SVInput!) {
  mutations {
    auth {
      loginG2sv(loginInput: $options) {
        ts
        data {
          phoneIdentificationRequired
          showFortuneWheelWindow
        }
      }
    }
  }
}
    `;
export const doLoginPinCodeDocument = `
    query doLoginPinCode($options: LoginPinCodeInput!) {
  mutations {
    auth {
      loginPinCode(loginInput: $options) {
        ts
        data {
          customerData {
            customerLogin
          }
        }
      }
    }
  }
}
    `;
export const doPostponeWebPushTokenDocument = `
    query doPostponeWebPushToken($options: EmptyMutationRequest!) {
  mutations {
    webPushNotifications {
      postpone(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
export const doReadCustomerNotificationDocument = `
    query doReadCustomerNotification($options: UpdateMessageStatusRequest!) {
  mutations {
    customer {
      updateMessageStatus(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
export const doRefuseMeetingDocument = `
    query doRefuseMeeting($options: EmptyMutationRequest!) {
  mutations {
    verification {
      refuse(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
export const doRegisterCustomerOrderDocument = `
    query doRegisterCustomerOrder($options: RegisterShopOrderRequest!) {
  mutations {
    leonShop {
      registerCustomerOrder(input: $options) {
        ts
        data {
          orderId
          order {
            campaignType
            freespinGameIds
          }
        }
      }
    }
  }
}
    `;
export const doRegisterPushTokenDocument = `
    query doRegisterPushToken($options: NotificationRegisterV2Request!) {
  mutations {
    customer {
      notifications {
        registerV2(input: $options) {
          ts
          data {
            result
          }
        }
      }
    }
  }
}
    `;
export const doRequestCallbackDocument = `
    query doRequestCallback($options: RequestCallbackRequest!) {
  mutations {
    callback {
      request(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
export const doSaveFastBetsDocument = `
    query doSaveFastBets($options: [Int!]!) {
  mutations {
    customer {
      saveFastBets(fastBets: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
export const doSaveLogDocument = `
    query doSaveLog($options: SaveLogInput!) {
  mutations {
    logging {
      saveLog(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
export const doSavePriceChangePolicyDocument = `
    query doSavePriceChangePolicy($options: PriceChangePolicy!) {
  mutations {
    customer {
      savePriceChangePolicy(priceChangePolicy: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
export const doSaveShowMaxFastBetDocument = `
    query doSaveShowMaxFastBet($options: SaveShowMaxFastBetRequest!) {
  mutations {
    customer {
      saveShowMaxFastBet(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
export const doSaveSportFiltersDocument = `
    query doSaveSportFilters($options: SportFiltersRequest!) {
  mutations {
    customer {
      saveSportFilters(sportFilters: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
export const doScheduleMeetingDocument = `
    query doScheduleMeeting($options: ScheduleMeetingRequest!) {
  mutations {
    verification {
      scheduleMeeting(input: $options) {
        ts
        data {
          roomUrl
        }
      }
    }
  }
}
    `;
export const doSelfExclusionDocument = `
    query doSelfExclusion($options: DoSelfExclusionRequest!) {
  mutations {
    limits {
      doSelfExclusion(input: $options) {
        ts
        data {
          result
          selfExclusion {
            until
            from
            isSelfExcluded
          }
        }
      }
    }
  }
}
    `;
export const doSendFeedbackDocument = `
    query doSendFeedback($options: SendFeedbackRequest!) {
  mutations {
    feedback {
      send(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
export const doSendWeb2FeedbackDocument = `
    query doSendWeb2Feedback($options: Web2FeedbackRequest!) {
  mutations {
    feedback {
      sendWeb2Feedback(input: $options) {
        data {
          result
        }
        ts
      }
    }
  }
}
    `;
export const doToggleCustomerLeagueDocument = `
    query doToggleCustomerLeague($options: ToggleLeagueRequest!) {
  mutations {
    customer {
      leagues {
        toggleLeague(input: $options) {
          ts
          data {
            result
          }
        }
      }
    }
  }
}
    `;
export const doTsupisCardDeleteDocument = `
    query doTsupisCardDelete($options: TsupisCardDeleteRequest!) {
  mutations {
    finance {
      tsupisCardDelete(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
export const doUpdateCustomerDataDocument = `
    query doUpdateCustomerData($options: UpdateUserDataRequest!) {
  mutations {
    customer {
      updateUserData(input: $options) {
        ts
        data {
          msg
          result
        }
      }
    }
  }
}
    `;
export const doUpdateNotificationConsentsDocument = `
    query doUpdateNotificationConsents($options: UpdateNotificationConsentsRequest!) {
  mutations {
    customer {
      updateNotificationConsents(input: $options) {
        ts
        data {
          msg
          result
        }
      }
    }
  }
}
    `;
export const doWithdrawAllSportBonusesDocument = `
    query doWithdrawAllSportBonuses($options: EmptyMutationRequest) {
  mutations {
    bonuses {
      withdrawAllSportBonuses(input: $options) {
        ts
        data {
          withdrawnAmount
          unwithdrawnAmount
        }
      }
    }
  }
}
    `;
export const doWithdrawSportBonusDocument = `
    query doWithdrawSportBonus($options: WithdrawSportBonusRequest!) {
  mutations {
    bonuses {
      withdrawSportBonus(input: $options) {
        ts
        data {
          withdrawnAmount
          unwithdrawnAmount
        }
      }
    }
  }
}
    `;
export const doWithdrawalCancelDocument = `
    query doWithdrawalCancel($options: WithdrawalCancelRequest!) {
  mutations {
    finance {
      withdrawal {
        cancel(input: $options) {
          ts
          data {
            result
            msg
          }
        }
      }
    }
  }
}
    `;
export const getEgsCategoriesDocument = `
    query getEgsCategories($options: EgsCategoriesRequest!) {
  queries {
    externalGames {
      getCategories(input: $options) {
        ts
        data {
          result
          categories {
            ...EgsGameCategoriesFields
          }
        }
      }
    }
  }
}
    ${EgsGameCategoriesFieldsFragmentDoc}`;
export const getEgsGameByIdDocument = `
    query getEgsGameById($options: GetEgsGameRequest!) {
  queries {
    externalGames {
      getGame(input: $options) {
        ts
        data {
          game {
            ...EgsGameFields
          }
        }
      }
    }
  }
}
    ${EgsGameFieldsFragmentDoc}`;
export const getEgsGameByUrlDocument = `
    query getEgsGameByUrl($options: GetEgsGameByUrlRequest!) {
  queries {
    externalGames {
      getGameByUrl(input: $options) {
        ts
        data {
          game {
            ...EgsGameFields
          }
        }
      }
    }
  }
}
    ${EgsGameFieldsFragmentDoc}`;
export const getEgsGamesDocument = `
    query getEgsGames($options: EgsGamesRequest!) {
  queries {
    externalGames {
      getGames(input: $options) {
        ts
        data {
          games {
            ...EgsGameFields
          }
          next {
            ...PaginationCursorItemFields
          }
        }
      }
    }
  }
}
    ${EgsGameFieldsFragmentDoc}
${PaginationCursorItemFieldsFragmentDoc}`;
export const getEgsGroupsDocument = `
    query getEgsGroups($options: EgsGroupsRequest!) {
  queries {
    externalGames {
      getGroups(input: $options) {
        ts
        data {
          result
          groups {
            ...EgsGroupFields
          }
        }
      }
    }
  }
}
    ${EgsGroupFieldsFragmentDoc}`;
export const getEgsTopWinnersDocument = `
    query getEgsTopWinners($options: EgsTopWinnersRequest!) {
  queries {
    externalGames {
      getTopWinners(input: $options) {
        ts
        data {
          winners {
            ...EgsTopWinnerFields
          }
          next {
            limit
            offset
          }
        }
      }
    }
  }
}
    ${EgsTopWinnerFieldsFragmentDoc}`;
export const startEgsGameDocument = `
    query startEgsGame($options: EgsStartGameRequest!) {
  mutations {
    externalGames {
      startGame(input: $options) {
        ts
        data {
          gameUrl
          startCode
          maxBetLimit {
            amount
            currency
          }
          freeSpinInfo {
            amount
            currency
            count
            left
          }
          hideLimitModalOnGamePage
          showBonusMaxBetLimitPopup
        }
      }
    }
  }
}
    `;
export const enableCustomerModeDocument = `
    query enableCustomerMode($options: G2SVEnableCustomerModeRequest!) {
  mutations {
    g2sv {
      enableCustomerMode(input: $options) {
        ts
        data {
          disposableKeys
        }
      }
    }
  }
}
    `;
export const enableFingerprintLoginDocument = `
    query enableFingerprintLogin($options: EnableFingerprintLoginRequest!) {
  mutations {
    customer {
      enableFingerprintLogin(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
export const generateNicknameDocument = `
    query generateNickname($options: GenerateNicknameRequest!) {
  queries {
    customer {
      generateNickname(input: $options) {
        ts
        data {
          nickname
        }
      }
    }
  }
}
    `;
export const getAccountTransactionDocument = `
    query getAccountTransaction($options: AccountTransactionRequest!) {
  queries {
    accountTransaction {
      getTransactionInfo(input: $options) {
        ts
        data {
          transaction {
            status
            transactionGroup
            typeName
            statusColor
            ... on BetTx {
              status
              transactionGroup
              acceptDate
              lName
              eventId
              isCalculated
              cashoutAmount
              sName
              type
              oddsType
              odd
              winVal
              winValStr
              stake
              bonusPoints
              isLive
              maxWin
              name
              id
              startDate
              outcome
              sportRulesInterval
              freeBetId
              liveStatus {
                score
                stage
                setScores
                progress
                stageCode
              }
              items {
                winStatusClass
                winStatus
                startDate
                sName
                lName
                name
                runnerName
                oddsType
                odd
                isBanker
                isLive
                score
              }
              combiType
              combiStatus
              statusColor
              currency
            }
            ... on PaymentTx {
              transactionGroup
              amount
              date
              currency
              purse
              status
              isWithdrawCancellable
              statusColor
              currency
              statusId
            }
            ... on CustomerAccountHistoryTx {
              id
              timestamp
              credit
              debit
              currency
            }
          }
        }
      }
    }
  }
}
    `;
export const getAffiliatedCustomersDocument = `
    query getAffiliatedCustomers($options: AffiliatedLoginsRequest!) {
  queries {
    affiliate {
      getAffiliatedCustomers(input: $options) {
        ts
        data {
          affiliatedCustomers {
            login
            bonusCode
            createdAt
          }
        }
      }
    }
  }
}
    `;
export const getApiSettingsDocument = `
    query getApiSettings {
  queries {
    system {
      apiSettings {
        getApiSettings {
          ts
          data {
            graphqlBatchRequestsMaxSize
          }
        }
      }
    }
  }
}
    `;
export const getAuthenticatorUriDocument = `
    query getAuthenticatorUri {
  queries {
    g2sv {
      getAuthenticatorUri {
        ts
        data {
          authenticatorUri
          secret
        }
      }
    }
  }
}
    `;
export const getAuthenticatorUriQRCodeDocument = `
    query getAuthenticatorUriQRCode($options: QRCodeRequest!) {
  queries {
    g2sv {
      getQRCode(input: $options) {
        ts
        data {
          qrCode
        }
      }
    }
  }
}
    `;
export const getAvailableBonusesForTransferDocument = `
    query getAvailableBonusesForTransfer($options: EmptyQueryRequest) {
  queries {
    bonuses {
      getAvailableBonusesForTransfer(input: $options) {
        ts
        data {
          frozen {
            type
            balance
          }
          totalTransferAmount
          totalBonusAmount
          currencyCode
        }
      }
    }
  }
}
    `;
export const getAvailableFormsDocument = `
    query getAvailableForms {
  queries {
    registration {
      getAvailableForms {
        ts
        data {
          isExcludedCountry
          forms {
            uiFormSchema {
              order
              fields {
                isFrontOnly
                id
                widget
                hidden
                title
                defaultValue
                disabled
                hintMessage
                type
                encrypted
                labels {
                  key
                  value
                }
                options {
                  key
                  value
                }
              }
            }
            formValidationSchema
            registrationType
            trigger
            formError
            formErrorCaption
            formSuccess
            validated
            hasFieldsErrors
            duplicateUser
            formName
            affiliateId
            stage
            priority
            failsCount
            schemaId
            formStep
            formProgress
            sendSms {
              formParams {
                key
                value
              }
              schemaId
            }
            goBack {
              formParams {
                key
                value
              }
              schemaId
            }
          }
          regBannerUrl
        }
      }
    }
  }
}
    `;
export const getAvailableMeetingSlotsDocument = `
    query getAvailableMeetingSlots($options: AvailableMeetingSlotsRequest) {
  queries {
    verification {
      getAvailableMeetingSlots(input: $options) {
        ts
        data {
          availableMeetingSlots {
            id
            endTime
            startTime
            roomUrl
          }
        }
      }
    }
  }
}
    `;
export const getBalanceDocument = `
    query getBalance($options: EmptyQueryRequest) {
  queries {
    finance {
      getBalance(input: $options) {
        ts
        data {
          balance
          lastModifiedAt
          cbcBalance
          currency
          depositMetrics {
            status
            firstDeposit
            paymentSystemId
            systemAmount
            paymentId
          }
          cashbackBalance
          cashbackEndDate
        }
      }
    }
  }
}
    `;
export const getBannerDocument = `
    query getBanner($options: GetBannerRequest!) {
  queries {
    banners {
      getBanner(input: $options) {
        ts
        data {
          result
          banner {
            ...BannerData
          }
        }
      }
    }
  }
}
    ${BannerDataFragmentDoc}`;
export const getBannersDocument = `
    query getBanners {
  queries {
    banners {
      getBanners {
        ts
        data {
          bannerGroups {
            id
            name
            weight
            isSlider
            displayRoutes {
              name
              parameters {
                name
                availableValues
              }
            }
            autoplayDelay
            isNT
            banners {
              ...BannerData
            }
          }
        }
      }
    }
  }
}
    ${BannerDataFragmentDoc}`;
export const getBatchSlipInfoDocument = `
    query getBatchSlipInfo($options: BatchSlipInfoRequest!) {
  queries {
    betSlip {
      getBatchSlipInfo(input: $options) {
        ts
        data {
          slipEntries {
            ...SlipInfoFields
          }
          combiAvailable
          accumulatorBoostMinOddsStr
        }
      }
    }
  }
}
    ${SlipInfoFieldsFragmentDoc}`;
export const getBetDocument = `
    query getBet($options: BetDetailsRequest!) {
  queries {
    bet {
      getBet(input: $options) {
        ts
        data {
          skksInfo {
            id
            hash
            operationDate
            operationType
          }
          amount {
            bet {
              betId
              sportcatalogBet
              curAbbrev
              acceptDate
              calculationDate
              statusNameServer
              betLine
              sport
              league
              sportId
              eventId
              marketId
              eventName
              startDate
              oddsType
              outcomeStr
              odds
              maxWin
              stake
              winning
              combiStatus
              webshopCancelable
              items {
                sportId
                marketId
                sportName
                marketName
                leagueName
                runnerName
                eventName
                winStatus
                eventStartTime
                oddsType
                betId
                price
                isBanker
              }
              bonusPoints
              hasPincode
              runnerId
              asianId
              oddsTypeId
              oddsTypeOutcome
            }
            statusCode
            status
            isLive
            cashout
            bankers
            isReturn
            isWin
            isCalculated
            isVSport
            winStr
            vsportStatLinks {
              key
              value
            }
            combiTypeText
          }
          status
        }
      }
    }
  }
}
    `;
export const getBetLimitsDocument = `
    query getBetLimits($options: CustomerLimitsRequest) {
  queries {
    limits {
      getBetLimits(input: $options) {
        ts
        data {
          limits {
            dailyLimit
            weeklyLimit
            monthlyLimit
            dailyPercent
            dailyLeft
            dailyEndAt
            weeklyPercent
            weeklyLeft
            weeklyEndAt
            monthlyPercent
            monthlyLeft
            monthlyEndAt
          }
          limitsUpdatableAt {
            dailyUpdatableAt
            weeklyUpdatableAt
            monthlyUpdatableAt
          }
        }
      }
    }
  }
}
    `;
export const getBetgeniusWidgetConfigurationDocument = `
    query getBetgeniusWidgetConfiguration($options: EmptyQueryRequest!) {
  queries {
    configContent {
      getBetgeniusWidgetConfiguration(input: $options) {
        ts
        data {
          active
          productionUrl
          customLang
          activeSports
          widgetStyles
        }
      }
    }
  }
}
    `;
export const getBonusCodesDocument = `
    query getBonusCodes($options: EmptyQueryRequest) {
  queries {
    affiliate {
      getBonusCodes(input: $options) {
        ts
        data {
          bonusCodes {
            id
            bonusCode
            link
          }
          maxCount
        }
      }
    }
  }
}
    `;
export const getBonusLoyaltyDocument = `
    query getBonusLoyalty {
  queries {
    bonuses {
      getBonusLoyalty {
        ts
        data {
          loyalty {
            amount
            amountNumber
            progress
            progressPercentString
            remainingAmount
            remainingAmountNumber
            isParticipating
          }
          hideOnBoardingOnLeonShopPage
        }
      }
    }
  }
}
    `;
export const getBonusTransferAmountDocument = `
    query getBonusTransferAmount($options: BonusTransferAmountRequest!) {
  queries {
    bonuses {
      getBonusTransferAmount(input: $options) {
        ts
        data {
          transferAmount
          currencyCode
        }
      }
    }
  }
}
    `;
export const getBonusWagersDocument = `
    query getBonusWagers($options: BonusWagersRequest!) {
  queries {
    bonuses {
      getBonusWagers(input: $options) {
        ts
        data {
          bonuses {
            category
            currency
            progress
            state
            walletBalanceNumber
            widgetName
            ... on Bonus {
              amountNumber
              availableAmountNumber
              requiredAmountNumber
              campaignName
              expiredAt
              expiringThrough
              expiredTimestamp
              isExpired
              bonusId
              actionUrl
              categoryId
            }
            ... on BonusAccumulative {
              isLeonshopShown
              bonusCurrency
              remainingAmountNumber
              bonusAmountNumber
            }
            ... on BonusFirstDeposit {
              isLeonshopShown
              bonusCurrency
              requiredAmountNumber
              bonusAmountNumber
            }
          }
        }
      }
    }
  }
}
    `;
export const getBonusesDocument = `
    query getBonuses($options: EmptyQueryRequest) {
  queries {
    bonuses {
      getBonuses(input: $options) {
        ts
        data {
          anchorIgnore
          bonuses {
            bonusCode
            campaignId
            campaignName
            campaignType
            minDepositAmount {
              amount
              currency
            }
            maxDepositAmount {
              amount
              currency
            }
            depositAmountPercent
            description
            terms
            imgUrl
            actionUrl
            categoryId
            image {
              ...Web2ImageFields
            }
          }
          isBonusChooserShownBeforeDeposits
        }
      }
    }
  }
}
    ${Web2ImageFieldsFragmentDoc}`;
export const getCampaignCouponsDocument = `
    query getCampaignCoupons($options: CampaignCouponsRequest!) {
  queries {
    bonusGame {
      getCampaignCoupons(input: $options) {
        ts
        data {
          total
          type
          coupons
        }
      }
    }
  }
}
    `;
export const getCashoutOfferDocument = `
    query getCashoutOffer($options: BetCashOutRequest!) {
  queries {
    bet {
      getOffer(input: $options) {
        ts
        data {
          status
          msg
          amount
        }
      }
    }
  }
}
    `;
export const getCmsContentSpintaxBatchTranslationsDocument = `
    query getCmsContentSpintaxBatchTranslations($options: SpintaxBatchContentRequest!) {
  queries {
    system {
      cms {
        getSpintaxBatch(input: $options) {
          ts
          data {
            absent
            url
            key
            type
            title
            translations {
              lang
              html
            }
            updatedAt
            deleted
          }
        }
      }
    }
  }
}
    `;
export const getCmsContentTranslationsDocument = `
    query getCmsContentTranslations($options: ContentInput!) {
  queries {
    system {
      cms {
        content(input: $options) {
          ts
          data {
            translations {
              html
              lang
            }
          }
        }
      }
    }
  }
}
    `;
export const getConsentsDocument = `
    query getConsents($options: EmptyQueryRequest!) {
  queries {
    customer {
      notifications {
        getConsents(input: $options) {
          ts
          data {
            consents {
              pushNotification
              email
              sms
              telephone
              siteNotification
            }
          }
        }
      }
    }
  }
}
    `;
export const getCookieDocument = `
    query getCookie($options: InitInput!) {
  queries {
    cookies {
      getCookies(input: $options) {
        ts
        data {
          cookies {
            name
            value
          }
        }
      }
    }
  }
}
    `;
export const getCustomerHistoryDocument = `
    query getCustomerHistory($options: CustomerHistoryRequest!) {
  queries {
    customer {
      getHistory(input: $options) {
        ts
        data {
          count
          items {
            credit
            debet
            id
            kind
            eventStartDateGMT
            status
            transactionGroup
            transactionType
            transactionTypeId
            comments
            rowId
            accountType
            typeName
            ... on BetHistoryItem {
              coefficient
              isBetSold
              isCombiOrSystem
              isWin
              combiTypeText
              isEnabledForCashout
            }
            ... on PaymentHistoryItem {
              isWithdrawCancellable
            }
          }
          result
          message
        }
      }
    }
  }
}
    `;
export const getCustomerHistoryItemDocument = `
    query getCustomerHistoryItem($options: CustomerHistoryItemRequest!) {
  queries {
    account {
      getHistoryItemV2(input: $options) {
        ts
        data {
          item {
            accountType
            credit
            date
            debit
            objectId
            objectType
            rootTxId
            status
            statusName
            txTypeKind
            txTypeName
            transactionTypeId
            itemStatusColor
            cardOrder
            ... on ExtendedAccountLeonShopHistoryItem {
              placeholders {
                value
                type
                name
                currency
              }
            }
            ... on ExtendedAccountBetHistoryItem {
              betEventParts
              accumulatorBoost
              betType
              betClientPrice
              betClientPriceStr
              combiStatus
              cashoutAmount
              combiStatusText
              countItems
              betMarket
              betOutcome
              bonusPoints
              betSystemN
              betSystemM
              freebetId
              betEventId
              betLine
              betLeague
              liveStatus {
                penaltyScore
                progress
                score
                setScores
                stage
                stageCode
              }
              maxWin
              kickoff
              outcomeState
              outcomeStateText
              outcomeStateColor
              eventUrl {
                eventId
                event
                league
                region
                sport
              }
              betItems {
                banker
                betLine
                event
                eventParts
                eventUrl {
                  eventId
                  event
                  league
                  region
                  sport
                }
                kickoff
                league
                liveStatus {
                  penaltyScore
                  progress
                  score
                  setScores
                  stage
                  stageCode
                }
                matchResult {
                  score {
                    away
                    home
                  }
                }
                market
                outcome
                outcomeState
                outcomeStateColor
                outcomeStateText
                price
                priceStr
              }
              matchResult {
                phaseScores {
                  score {
                    away
                    home
                  }
                }
                score {
                  away
                  home
                }
                placeBetMatchScore {
                  away
                  home
                }
                penaltyScore {
                  away
                  home
                }
                extraTimeMatchScore {
                  away
                  home
                }
                yellowCards {
                  away
                  home
                }
                redCards {
                  away
                  home
                }
                yellowRedCards {
                  away
                  home
                }
                cornersScore {
                  away
                  home
                }
              }
            }
            ... on ExtendedAccountPaymentHistoryItem {
              withdrawCancellable
              fromAccountType
              toAccountType
              taxAmount
              errorCode
              errorMessage
              ndflAmounts {
                origAmount
                taxedAmnt
                tax
              }
            }
            ... on ExtendedAccountEgsHistoryItem {
              odds
              egsGameName
            }
          }
        }
      }
    }
  }
}
    `;
export const getCustomerHistoryListDocument = `
    query getCustomerHistoryList($options: CustomerHistoryRequest!) {
  queries {
    account {
      getHistory(input: $options) {
        ts
        data {
          count
          items {
            accountType
            credit
            date
            debit
            objectId
            objectType
            rootTxId
            status
            statusName
            txTypeKind
            txTypeName
            transactionTypeId
            itemStatusColor
            cardOrder
            ... on AccountBetHistoryItem {
              betEventParts
              betType
              betClientPrice
              betClientPriceStr
              combiStatus
              cashoutAmount
              combiStatusText
              countItems
              betMarket
              betOutcome
              outcomeState
            }
            ... on AccountPaymentHistoryItem {
              errorCode
              errorMessage
              withdrawCancellable
            }
            ... on AccountLeonShopHistoryItem {
              placeholders {
                value
                type
                name
                currency
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const getCustomerLeagueDocument = `
    query getCustomerLeague($options: GetLeagueRequest!) {
  queries {
    customer {
      leagues {
        getLeague(input: $options) {
          ts
          data {
            leaguesId
          }
        }
      }
    }
  }
}
    `;
export const getCustomerLeaguesDocument = `
    query getCustomerLeagues($options: LeaguesRequest!) {
  queries {
    customer {
      leagues {
        getLeagues(input: $options) {
          ts
          data {
            myLeagues {
              leagues {
                leaguesId
              }
            }
            result
          }
        }
      }
    }
  }
}
    `;
export const getCustomerMatchStreamEventsDocument = `
    query getCustomerMatchStreamEvents {
  queries {
    matchStream {
      getCustomerEvents {
        ts
        data {
          customerStreams
          result
        }
      }
    }
  }
}
    `;
export const getCustomerMatchesDocument = `
    query getCustomerMatches($options: CustomerMatchesIdRequest!) {
  queries {
    customer {
      matches {
        listMatchesIds(input: $options) {
          ts
          data {
            ids
          }
        }
      }
    }
  }
}
    `;
export const getCustomerNotificationsDocument = `
    query getCustomerNotifications($options: OperatorMessagesRequest!) {
  queries {
    customer {
      listOperatorMessages(input: $options) {
        ts
        data {
          allOperatorMessages {
            id
            title
            message
            timestampCreation
            status
          }
        }
      }
    }
  }
}
    `;
export const getCustomerNotificationsCountDocument = `
    query getCustomerNotificationsCount($options: CountUnreadMessagesRequest!) {
  queries {
    customer {
      countUnreadMessages(input: $options) {
        ts
        data {
          unreadMessagesCount
        }
      }
    }
  }
}
    `;
export const getCustomerOffersDocument = `
    query getCustomerOffers {
  queries {
    leonShop {
      getCustomerOffers {
        ts
        data {
          offers {
            id
            name
            price
            imageUrl
            description
            image {
              ...Web2ImageFields
            }
            placeholders {
              name
              type
              value
              currency
            }
          }
        }
      }
    }
  }
}
    ${Web2ImageFieldsFragmentDoc}`;
export const getCustomerOrdersDocument = `
    query getCustomerOrders($options: PaginationRequest!) {
  queries {
    leonShop {
      getCustomerOrders(input: $options) {
        ts
        data {
          orders {
            content {
              id
              price
              status
              createdAt
            }
            empty
            first
            last
            number
            numberOfElements
            size
            sort {
              empty
              sorted
              unsorted
            }
            totalElements
            totalPages
          }
        }
      }
    }
  }
}
    `;
export const getCustomerProfileFieldsDocument = `
    query getCustomerProfileFields {
  queries {
    registration {
      getCustomerProfileFields {
        ts
        data {
          uiFormSchema {
            order
            fields {
              isFrontOnly
              id
              widget
              hidden
              title
              defaultValue
              disabled
              hintMessage
              type
              labels {
                key
                value
              }
              options {
                key
                value
              }
            }
          }
          formValidationSchema
          schemaId
          formStep
        }
      }
    }
  }
}
    `;
export const getCustomerVerifyDataDocument = `
    query getCustomerVerifyData {
  queries {
    customer {
      getDocVerifyData {
        ts
        data {
          verifyData {
            supportedFormats
            maxExtensionSize
            maxFilesNumber
            documentReasons {
              id
              localizedName
            }
          }
        }
      }
    }
  }
}
    `;
export const getDepositLimitsDocument = `
    query getDepositLimits($options: CustomerLimitsRequest) {
  queries {
    limits {
      getDepositLimits(input: $options) {
        ts
        data {
          limits {
            dailyLimit
            weeklyLimit
            monthlyLimit
            dailyPercent
            dailyLeft
            dailyEndAt
            weeklyPercent
            weeklyLeft
            weeklyEndAt
            monthlyPercent
            monthlyLeft
            monthlyEndAt
          }
          limitsUpdatableAt {
            dailyUpdatableAt
            weeklyUpdatableAt
            monthlyUpdatableAt
          }
        }
      }
    }
  }
}
    `;
export const getEncashBonusDataDocument = `
    query getEncashBonusData {
  queries {
    finance {
      encashBonusData {
        ts
        data {
          max
          min
          oneCoin
          result
        }
      }
    }
  }
}
    `;
export const getFAQCategoriesDocument = `
    query getFAQCategories($options: EmptyQueryRequest) {
  queries {
    faq {
      getCategories(input: $options) {
        data {
          categories {
            urlId
            title
            items {
              urlId
              title
              content
            }
          }
        }
        ts
      }
    }
  }
}
    `;
export const getFAQItemsDocument = `
    query getFAQItems($options: FAQListRequest!) {
  queries {
    faq {
      getList(input: $options) {
        data {
          items {
            id
            order
            title
            text
            categories
          }
        }
        ts
      }
    }
  }
}
    `;
export const getFeedbackTypesDocument = `
    query getFeedbackTypes($options: FeedbackDetailsRequest!) {
  queries {
    feedback {
      getDetails(input: $options) {
        data {
          feedbackTypes {
            key
            title
          }
        }
        ts
      }
    }
  }
}
    `;
export const getFormSchemaDocument = `
    query getFormSchema($options: ValidationSchemaInput!) {
  queries {
    system {
      schemaContent(input: $options) {
        data {
          content
        }
        ts
      }
    }
  }
}
    `;
export const getFreebetDataDocument = `
    query getFreebetData($options: RegisterFormsInput!) {
  queries {
    registration {
      getAvailableForms(input: $options) {
        ts
        data {
          freeBetActionId: freeBetActionClass
          freeBetActionBannerUrl
          freeBetTermsPage
          freeBetBcode
          regBannerUrl
        }
      }
    }
  }
}
    `;
export const getGeneralStatsDocument = `
    query getGeneralStats($options: AffiliateGeneralStatsRequest!) {
  queries {
    affiliate {
      getGeneralStats(input: $options) {
        ts
        data {
          operations {
            date
            debit
            credit
            pendingDebit
            pendingCredit
            balance
            pendingBalance
            typeId
          }
          stats {
            balance
            pendingBalance
            percent
            affiliatedCustomersCount
            profitForPeriod
            profit
          }
          totalCount
        }
      }
    }
  }
}
    `;
export const getInlineChatsDocument = `
    query getInlineChats($options: LiveChatInlineChatsRequest) {
  queries {
    liveChat {
      getInlineChats(input: $options) {
        ts
        data {
          items {
            id
            urls
            text
            description
            subject
            timeout
            hideCondition
            animations
            supportTypes
          }
        }
      }
    }
  }
}
    `;
export const getIntercomUserHashDocument = `
    query getIntercomUserHash($options: EmptyQueryRequest!) {
  queries {
    faq {
      getIntercomConfig(input: $options) {
        ts
        data {
          userHash
          appId
          apiUrl
        }
      }
    }
  }
}
    `;
export const getLSportsWidgetConfigurationDocument = `
    query getLSportsWidgetConfiguration($options: EmptyQueryRequest!) {
  queries {
    configContent {
      getLSportsWidgetConfiguration(input: $options) {
        ts
        data {
          active
          productionUrl
          customLang
          activeSports
        }
      }
    }
  }
}
    `;
export const getLastBetResultDocument = `
    query getLastBetResult($options: EmptyQueryRequest) {
  queries {
    betSlip {
      getLastResult(input: $options) {
        ts
        data {
          duration
          results {
            accepted
            betId
            errorCode
            errorMessage
            readyForCashout
            slipInfo {
              ...SlipInfoFields
            }
            betTransfer {
              items {
                sportId
                marketId
                sportName
                leagueName
                marketName
                runnerName
                eventName
                regionName
                winStatus
                isBanker
                eventId
              }
            }
          }
        }
      }
    }
  }
}
    ${SlipInfoFieldsFragmentDoc}`;
export const getLeaderBoardDocument = `
    query getLeaderBoard($options: LeaderBoardWinnersRequest!) {
  queries {
    promotions {
      getLeaderBoard(input: $options) {
        ts
        data {
          winners {
            login
            name
            place
            prize
            score
          }
          page
          nextPage
          customerPosition {
            login
            name
            place
            prize
            score
          }
        }
      }
    }
  }
}
    `;
export const getLexGuardSessionDocument = `
    query getLexGuardSession {
  queries {
    system {
      getLexGuardSession {
        ts
        data {
          id
          expiresAt
        }
      }
    }
  }
}
    `;
export const getLiveChatSettingsDocument = `
    query getLiveChatSettings($options: LiveChatDetailsRequest) {
  queries {
    liveChat {
      getDetails(input: $options) {
        ts
        data {
          chatData {
            code
            session
            endpoint
          }
          chatEnabled
        }
      }
    }
  }
}
    `;
export const getLiveChatStatusDocument = `
    query getLiveChatStatus($options: LiveChatDetailsRequest) {
  queries {
    liveChat {
      getDetails(input: $options) {
        ts
        data {
          chatEnabled
        }
      }
    }
  }
}
    `;
export const getMatchStreamUrlDocument = `
    query getMatchStreamUrl($options: MatchStreamInput!) {
  queries {
    matchStream {
      getMatchStreamUrl(input: $options) {
        ts
        data {
          src
          result
        }
      }
    }
  }
}
    `;
export const getNavigationConfigDocument = `
    query getNavigationConfig {
  queries {
    system {
      getNavigation(input: {list: []}) {
        ts
        data {
          navigation
        }
      }
    }
  }
}
    `;
export const getOnboardingDocument = `
    query getOnboarding($options: OnboardingsRequest) {
  queries {
    onboarding {
      getItems(input: $options) {
        ts
        data {
          items {
            id
            autoShow
            slides {
              content
            }
          }
        }
      }
    }
  }
}
    `;
export const getPartnersDocument = `
    query getPartners($options: EmptyQueryRequest) {
  queries {
    partner {
      getPartners(input: $options) {
        ts
        data {
          isDetailsLinkEnabled
          link {
            key
            value
          }
          items {
            url
            name
            description
            logo {
              ...Web2ImageFields
            }
            backgroundImage {
              ...Web2ImageFields
            }
            backgroundImageMobile {
              ...Web2ImageFields
            }
            bannerImage {
              ...Web2ImageFields
            }
            buttonTitle
            contentPage
            navigation
            showLogotypeInFooter
            isFakePartner
            isBanner
            images {
              dark {
                ...Web2ImageFields
              }
              light {
                ...Web2ImageFields
              }
              alt
            }
          }
        }
      }
    }
  }
}
    ${Web2ImageFieldsFragmentDoc}`;
export const getPendingBetsDocument = `
    query getPendingBets($options: PendingBetsRequest!) {
  queries {
    customer {
      getPendingBets(input: $options) {
        ts
        data {
          totalCount
          bets {
            betId
            combiStatus
            eventId
            eventNameParts
            isEnabledForCashout
            itemsQuantity
            odds
            oddsStr
            oddsType
            outcomeSr
            stake
            acceptDate
            freeBetId
            isLive
          }
        }
      }
    }
  }
}
    `;
export const getPopularQueriesDocument = `
    query getPopularQueries($options: EmptyQueryRequest) {
  queries {
    search {
      getPopularQueries(input: $options) {
        data {
          list
        }
        ts
      }
    }
  }
}
    `;
export const getPushSettingsDocument = `
    query getPushSettings {
  queries {
    customer {
      getPushNotificationSettings {
        ts
        data {
          isBetOutcomePushEnabled
          isWinOnlyBetOutcomePushEnabled
        }
      }
    }
  }
}
    `;
export const getRootSettingsDocument = `
    query getRootSettings($options: RootSettingsInput!) {
  queries {
    system {
      rootSettings(input: $options) {
        ts
        data {
          ctag
          imageCdnUrl
          defaultModuleUpdateInterval
          defaultUICurrency
          ssoServerUrl
          isCustomerLoggedIn
          moduleUpdateIntervals {
            name
            interval
          }
          isNT
          countryCode
          skin
          formatMoneyLocale
          desktopScreenMinWidth
          slipDefaultAmount
        }
      }
    }
  }
}
    `;
export const getRootSettingsInternationalDocument = `
    query getRootSettingsInternational($options: RootSettingsInput!) {
  queries {
    system {
      rootSettings(input: $options) {
        ts
        data {
          ctag
          imageCdnUrl
          defaultUICurrency
          ssoServerUrl
          isCustomerLoggedIn
          isNT
          countryCode
          availableLanguages {
            code
            name
            prefix
            flag
          }
          currentLanguageTag
          replacedUrlPart
          urlLocale
          countriesData: phonePrefixes {
            code
            name
            phonePrefix
            popular
          }
          formatMoneyLocale
          skin
          desktopScreenMinWidth
          slipDefaultAmount
        }
      }
    }
  }
}
    `;
export const getRoutingConfigsDocument = `
    query getRoutingConfigs {
  queries {
    system {
      getRouting {
        ts
        data {
          result
          routing
        }
      }
    }
  }
}
    `;
export const getRulesDocument = `
    query getRules {
  queries {
    rules {
      getRules {
        ts
        data {
          rules {
            id
            caption
            isPrintEnabled
            children {
              id
              caption
              isPrintEnabled
              children {
                id
                caption
                contentPageKey
                isPrintEnabled
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const getSearchDataDocument = `
    query getSearchData($options: SearchRequest!) {
  queries {
    search {
      search(input: $options) {
        data {
          events {
            id
            leagueId
            sportId
            league
            score
            kickoff
            teams
            message
            isLive
          }
          games {
            systemId
            id
            title
            provider
            hasDemo
            imageUrl
            imageUrl2x
            imageUrl3x
            launchUrl
            demoQueryParam
            isNew
            simpleRegistrationShouldBeCompleted
            isAggregated
            system
          }
          message
          result
        }
        ts
      }
    }
  }
}
    `;
export const getSelfExclusionDocument = `
    query getSelfExclusion($options: CustomerLimitsRequest) {
  queries {
    limits {
      getDepositLimits(input: $options) {
        ts
        data {
          selfExclusion {
            from
            until
            isSelfExcluded
          }
        }
      }
    }
  }
}
    `;
export const getServiceWorkerConfigurationDocument = `
    query getServiceWorkerConfiguration {
  queries {
    serviceWorker {
      getConfiguration {
        ts
        data {
          result
          app {
            enabled
            isNT
            ntMode
            configReloadInterval
            logger {
              enabled
              logLevel
              params {
                key
                value
              }
            }
            sentry {
              enabled
              params {
                key
                value
              }
            }
            resolver {
              enabled
              isNT
              maxAge
              mode
              redirectFallback
              resolvers
              customReserveOrigin
              manuallyBlockedOrigins
              frameCookieFixMode
            }
            cache {
              excludes
            }
            fetchExcludes
            customDomains
          }
          shell {
            appHash
            appUrl
            enabled
            isNT
            ntMode
          }
        }
      }
    }
  }
}
    `;
export const getSiteConfigSettingsDocument = `
    query getSiteConfigSettings {
  queries {
    system {
      siteConfig {
        ts
        data {
          settings {
            jsBundlesCDNUrl
            themeSwitcherEnabled
            themes {
              id
              isDefault
            }
            bookmakerRatingsUrl
            socialMediaRssUrl
            socialMediaVkUrl
            socialMediaInstagramUrl
            socialMediaTelegramUrl
            googlePlayIconUrl
            appleStoreIconUrl
            feedbackFormMaxChars
            linkCDNUrl
            isServiceSuspended
            affiliateMigrationLink
            untrustedDevicePasswordResetRedirectTimer
            forcedLayoutEnabled
            appVloaderDelayMs
            modulesDefaultUpdateInterval
            modulesCustomUpdateInterval {
              name
              interval
            }
            isSeoBetCmsSpintaxEnabled
            isMetaBetCmsSpintaxEnabled
            isLanguageSelectorFlagsEnabled
            isPlayTimeControlEnabled
            fontFamily
            isDevtoolsTrackerDisabled
            themeFooterSwitcherEnabled
          }
          webSockets {
            isEnabled
            isBalanceEnabled
            isCbcBonusWithdrawalAmountEnabled
            isLoyaltyUpdateEnabled
            isOnDepositEnabled
            isLoyaltyProgramEnabled
            isSocketStickyBonusStatus
            isCashbackEnabled
            isWheelNotificationEnabled
            isFlexibleFreeSpinBetLimitNotificationEnabled
          }
          sportEvents {
            activatedSportUpdateInterval
            sportLineLiveUpdateInterval
            sportLinePrematchUpdateInterval
            sportLineSidebarSportsUpdateInterval
            sportFiltersEnabled
            sportLineUpdateInterval
            comingEventsEnabled
            comingEventsKickoffFilter
            comingEventsLimit
          }
          sportLine {
            slipEventsUpdateInterval
            pendingBetsUpdateInterval
            stakeInputValidationTimeout
            deleteClosedEventTimeout
            sportlineCatalogUrl
            familyFilterResetEnabled
            onlyActivatedSportUpdateEnabled
            onlyActivatedSportUpdateOnChangeEnabled
            doUseRelativeTimeForSportline
            doRemoveDuplicateEvents
            filterTransitionFadeEffectEnabled
            filterTransitionFadeOutDuration
            filterTransitionFadeInDuration
            liveStreamEnabled
            liveStreamWidgetFloatingEnabled
            sportRadarWidgetFloatingEnabled
            lsportsWidgetEnabled
            betgeniusWidgetEnabled
            sportConfigurationV2 {
              sportFamily
              config {
                widgetType
                timerMode
              }
            }
            sportUrlMapping {
              sportId
              sportUrl
            }
            marketGroupsTabsEnabled
            marketTypesSelectionEnabled
            listMarketsDropdownEnabled
            listMarketsDropdownLimit
            relatedEventsLimit
            runnersCountDisplayEnabled
            cmsMainMarketsUsageEnabled
            maxTopLeaguesTabsInSidebar
            maxTopLeaguesInTab
            timeReversedIndicatorEnabled
            eventDateFormat
            cybersportV2Enabled
            topEventsEnabled
            topEventsLogosEnabled
            prematchFavoriteEnabled
            eventChangeSubscriptionEnabled
            topEventsJackpotsSectionEnabled
            zeroMarginEnabled
            sportEventStatisticsEnabled
            noMarketsBlockedBtnEnabled
            sportLeagueStatisticsEnabled
            liveOutrightsEnabled
            leagueHeaderDataRequestEnabled
            sportHeaderDataRequestEnabled
            regionHeaderDataRequestEnabled
            virtualSportLiveEventsEnabled
            oddsFormatSwitcherEnabled
            displayPrematchEventsInLiveEnabled
          }
          promotions {
            leaderboardPageSize
            promotionsHomepageCategoryId
            isPromotionsLeaderboardArchiveEnabled
            isPromotionsHighlightLinkEnabled
            isPromotionsRequestNicknameEnabled
            isPromotionsLikeEnabled
            promotionsLikeFeedbackCountdown
            isTempCouponFeatureEnabled
            tempCouponCampaignInterval {
              from
              to
            }
            festivalCampaignId
            festivalActionUrl
            festivalTournamentActionUrls
            festivalCouponCampaignId
            cashbackNotificationAutoCloseDuration
            isCashbackLoyaltyModalEnabled
          }
          profile {
            subscriptionBlockEnabled
            profileReferralProgramEnabled
            referralProgramSocialItems {
              name
              url
              title
              description
              quote
              hashtags
              media
              restrictedLocales
              iconName
              backgroundColor
              status
            }
            referralProgramMainBlockEnabled
            profileBetsCounterEnabled
          }
          captcha {
            loginCaptchaStatus
            passwordRecoveryCaptchaStatus
            registrationCaptchaStatus
            loginCaptchaType
            passwordRecoveryCaptchaType
            registrationCaptchaType
            captchaSecuritySignEnabled
            captchaIframeUrl
            captchaApiUrl
            useIframeForCaptcha
            recaptcha {
              theme
              siteKeys {
                captchaType
                key
              }
            }
          }
          errorHandler {
            settings {
              enabled
              errorHandlerType
              params {
                key
                value
              }
              ignoredErrors {
                error
                type
              }
              consoleLogLevels
              browserTracingEnabled
              sessionReplayEnabled
              profilingEnabled
            }
            ignoredErrorsCommon {
              error
              type
            }
          }
          partner {
            isPartnersEnabled
            isPartnersV2Enabled
          }
          metricsTools {
            metricsToolsData {
              type
              setup {
                counterId
                config {
                  key
                  value
                }
              }
            }
          }
          slip {
            pendingBetsPaginationCount
            outdatedEventsFilteringEnabled
            multiSinglesEnabled
            fastBets {
              enabled
              values {
                currency
                values
              }
            }
            standardBetLimits {
              min
              max
              currency
            }
            accumulatorBoostEnabled
            accumulatorBoostMinOdds
            accumulatorBoostPerOutcomeCount {
              count
              boost
            }
            accumulatorBoostOnlyPrimaryMarkets
          }
          deposits {
            suggestedAmounts {
              currency
              depositAmounts
            }
            depositMetricsUpdateInterval
            depositMetricsAfterBalanceDelay
            bonusListBeforeDepositEnabled
          }
          support {
            callbackEnabled
            isCallCenterWorking
            faqEnabled
            callCenterCallbackEnabled
            isLivechatAnonymEnabled
            isResponsibleGamblingFeedbackEnabled
            isHelpEmailEnabled
            helpButtonMode
          }
          pushNotifications {
            enabled
            isAnonymousRegistrationEnabled
            isStartOnboardingEnabled
            isPushOnBetsEnabled
            stopRequests
          }
          pushNotificationsWeb {
            openTimeout
            modalEnabled
            postponeHours
            modalRestrictedRoutes
          }
          firebase {
            webApiKey
            webProjectId
            webMessagingSenderId
            webAppId
            webMeasurementId
            webVapidKey
          }
          identification {
            acceptedFileTypes {
              documentType
              fileTypes
            }
            maxFilesizes {
              documentType
              filesize
            }
            esiaIdentificationConfig {
              esiaIdentificationType
              etsupisEsiaRedirectUrl
            }
            thirdPartyRetryDelay
          }
          popularSearchQueries {
            popularSearchQueriesData
          }
          downtime {
            enabled
            visibleBeforeHours
            interval {
              from
              to
              shown
            }
          }
          security {
            browserFingerprintingEnabled
            faceTouchIDReminderCount
            faceTouchIDReminderFrequency
            g2svInactiveInterval
            helmetAPIUrl
            lexGuardAPIUrl
            lexGuardProfilingSubdomain
            lexGuardOrgId
            phoneWrongAttemptsLimit
            pinCodeRequestedPlaces
            pinCodeModalShowLimit
            pinCodeWrongAttemptsLimit
          }
          search {
            searchSportlineMinchars
          }
          mobileBlocks {
            mobileReserveDomainsCustom
            mobileReserveDomains {
              domain
              blockedSource
              status
            }
          }
          mobileAppCordova {
            mobileAppDownloadEnabled
            mobileAppAndroidDownloadEnabled
            mobileAppIosDownloadEnabled
            mobileAppDownloadTopBannerEnabled
            mobileAppIosDownloadUrl
            mobileAppAndroidDownloadUrl
            mobile5UpdateCheckPeriod
            mobileAppUpdateConfigGeneric {
              apkStorageUrl
              apkName
              appVersion
              isUpdateAvailable
              isApkUpdateMandatory
            }
            mobileAppUpdateConfigCustomerSpecific {
              apkStorageUrl
              apkName
              appVersion
              isUpdateAvailable
              isApkUpdateMandatory
            }
            mobileLoginFingerprintEnabled
            flowLoginEnabled
            ionicAppflowEnabled
            autoThemeEnabled
          }
          appDownloadAd {
            isVisible
          }
          registration {
            isEmailTabEnabledOnPasswordRestorePage
            isPhoneTabEnabledOnPasswordRestorePage
            isSuggestedEmailRegistrationEnabled
            suggestedEmailRegistrationTimeout
            smsResendCodeTimer
            phoneCodeLength {
              phoneCodeMethod
              value
            }
            idleRedirectCount
            marketingLinkIdleRedirect
            marketingLinkParam
            idleTimeToRedirect
            showModalOnLeaveEnabled
            formsEmailHints
            formsEmailHintsEnabled
            isRegistrationDisabled
            millisToSleepAfterCountryChange
            appsFlyerSupportedSdkEvents
            footerBannerLogotypesByLocale {
              id
              iconLight
              iconDark
              alt
              url
            }
          }
          bonusWallet {
            bonusCodeInputFormStatus
            bonusLinkLoyalty
            startBonusScreenEnabled
            loyaltyProgramEgsStatus
            delayBeforeNewBonusesListRequest
            bonusLinkEgsLoyalty
          }
          zetaDcp {
            configs {
              type
              params {
                key
                value
              }
            }
            isEnabled
          }
          payments {
            paymentsListsReloadTimeout
            lowBalanceAmounts {
              currency
              value
            }
            isInteracEnabled
            isExpirationValidationDisabled
            isNoMethodMessageEnabled
            balanceButtonCurrencies
            depositMetricsRequestDelay
            isCcTokensEnabled
            isPaymentsMinAmountSuggestionEnabled
          }
          sso {
            enabled
          }
          virtualSport {
            isEnabled
            providerUrl
            items {
              tournament
              clientId
              selectedByDefault
              order
              sportUrlName
              isEnabled
            }
          }
          license {
            kwkIconEnabled
            crcIconEnabled
            crcEgamingIconEnabled
            kwkUrl
            crcUrl
            crcEgamingUrl
            crcEgamingLogoUrl
          }
          sportradar {
            pixelManager
            counterId
          }
          fastTrack {
            brandId
            brandName
            notificationsConfigApiUrl
            requestTimeout
            modalsShowTimeout
          }
          fastGames {
            isEnabled
          }
          externalGames {
            isStoryBannerEnabled
            topWinnersFilter
            gameZeroBalanceReloadTimeout
            vpnInactiveStopTimeout
            gameLoadTimeout
            egsStartGameRetryCounter
            egsStartGameRetryTimeout
            vpnEgsStartGameRetryCounter
            vpnEgsStartGameRetryTimeout
            lazyLoadPagesCounter
          }
          egsSkeletons {
            slotsAllTab {
              ...EgsSkeletonConfigFields
            }
            slotsGamePage {
              ...EgsSkeletonConfigFields
            }
            slotsMyTab {
              ...EgsSkeletonConfigFields
            }
            slotsProvider {
              ...EgsSkeletonConfigFields
            }
            liveAllTab {
              ...EgsSkeletonConfigFields
            }
            liveMyTab {
              ...EgsSkeletonConfigFields
            }
            liveGamePage {
              ...EgsSkeletonConfigFields
            }
            liveProvider {
              ...EgsSkeletonConfigFields
            }
            loyalty {
              ...EgsSkeletonConfigFields
            }
            search {
              ...EgsSkeletonConfigFields
            }
            fastGames {
              ...EgsSkeletonConfigFields
            }
            home {
              ...EgsSkeletonConfigFields
            }
          }
          widgets {
            iframeUrl
            sportradarLiveMatchTrackerEnabled
            sportradarStatisticsEnabled
            sportradarLiveScoreEnabled
          }
          sumSub {
            isWebSdkInProdMode
            sdkUrl
          }
          faq {
            faqEnabled
          }
          domainCheck {
            checkInterval
            msgMaxSizeBytes
            domains
          }
          rules {
            isTitleEnabled
            isNavigationOpen
            isSearchEnabled
            isMobileTabsEnabled
          }
          bonuses {
            bonusesEnable
            bonusesPolling
            isLeonShopEnabled
            isWeb2BonusCashbackWidgetEnabled
          }
          fJs {
            isEnabled
            endpointSubdomain
            apiKey
            visitorIdTtl
          }
          footer {
            isFooterSocialNetworksEnabled
            socialNetworkLogotypesByLocale {
              iconDark
              iconLight
              width
              height
              href
              alt
            }
            isFooterLogotypesEnabled
            isFooterOldLogotypesEnabled
            footerLogotypes {
              iconLight
              iconDark
              height
              alt
              href
              width
              height
            }
            footerLogotypesByLocale {
              iconLight
              iconDark
              height
              alt
              href
              width
              height
            }
          }
          loyalty {
            isEgsLoyaltyProgramLvlUpModelEnabled
            isEgsLoyaltyEnabled
          }
          landing {
            isLandingAndroidEnabled
            landingAndroidImageHeader {
              src
              x1
              x2
              x3
              x1webp
              x2webp
              x3webp
            }
            landingAndroidImageGamblingFirst {
              ...Web2ImageFields
            }
            landingAndroidImageGamblingSecond {
              ...Web2ImageFields
            }
            landingAndroidImageGamblingThird {
              ...Web2ImageFields
            }
            landingAndroidImageFree {
              ...Web2ImageFields
            }
            landingAndroidImageBonusFirst {
              ...Web2ImageFields
            }
            landingAndroidImageBonusSecond {
              ...Web2ImageFields
            }
            landingAndroidImageBonusThird {
              ...Web2ImageFields
            }
            landingAndroidImageBackground {
              ...Web2ImageFields
            }
            landingAndroidImageFooter {
              ...Web2ImageFields
            }
            landingCouponsBackground {
              ...Web2ImageFields
            }
            landingCouponsMainImage {
              ...Web2ImageFields
            }
            landingCouponsSecondaryImage {
              ...Web2ImageFields
            }
            isLandingWelcomeEnabled
            calculatorSum {
              currency
              value
              step
              maxValue
              minValue
            }
            landingCalcLeagueId
            landingCalcMatchId
            landingCalcMatchBetline
            landingFestEmbed
            promoActionLinks {
              actionUrl
              categoryId
              id
            }
            welcomeEgsCategory
            isLandingFestivalEnabled
            festPromoDetailsLink
            androidSocialNetworks {
              url
              title
              iconName
            }
            isLandingVipEnabled
            landingVipFaqItems {
              title
              description
            }
          }
          header {
            isHeaderBalanceEnabled
          }
          vpnLoader {
            isClickEnabled
            iconsCount
            minIconSize
            maxIconSize
            slideSpeed
          }
          routing {
            homePageType
          }
          verification {
            isVideoVerificationEnabled
          }
          activityReporter {
            activityReporterEnabled
          }
          guest {
            doRedirectGuestWithAccountToLogin
            redirectToLoginTimeout
          }
          responsibleGambling {
            responsibleGamblingV2Enabled
            selfExclusionOptions {
              months
              btrKey
            }
          }
          appflow {
            config {
              isEnabled
              doReset
              appId
              channel
              maxVersions
              minBackgroundDuration
              updateMethod
              host
              debug
            }
          }
          sportsbookUIVersion {
            sportsbookUITopPageVersion
            sportsbookUILivePageVersion
            sportsbookUISportsPageVersion
            sportsbookUILeaguePageVersion
            sportsbookUIRegionPageVersion
            sportsbookUIEventDetailsPageVersion
            sportsbookUINavigationVersion
          }
          login {
            configs {
              type
              isDefault
            }
          }
        }
      }
    }
  }
}
    ${EgsSkeletonConfigFieldsFragmentDoc}
${Web2ImageFieldsFragmentDoc}`;
export const getSportradarLiveMatchTrackerConfigurationDocument = `
    query getSportradarLiveMatchTrackerConfiguration($options: EmptyQueryRequest!) {
  queries {
    configContent {
      getSportRadarWidgetConfiguration(input: $options) {
        data {
          active
          theme
          productionUrl
          customLang
          widgets {
            active
            sport
            name
            order
            config
          }
          cssStyles {
            sport
            style
          }
        }
        ts
      }
    }
  }
}
    `;
export const getSportradarLiveScoreConfigurationDocument = `
    query getSportradarLiveScoreConfiguration($options: EmptyQueryRequest!) {
  queries {
    configContent {
      getSportRadarLiveScoreConfiguration(input: $options) {
        data {
          productionUrl
          customLang
          cssStyles
        }
        ts
      }
    }
  }
}
    `;
export const getSportradarStatisticsConfigurationDocument = `
    query getSportradarStatisticsConfiguration($options: EmptyQueryRequest!) {
  queries {
    configContent {
      getSportRadarStatisticsConfiguration(input: $options) {
        data {
          productionUrl
          customLang
          cssStyles
        }
        ts
      }
    }
  }
}
    `;
export const getSubscribedEventsDocument = `
    query getSubscribedEvents($options: EmptyQueryRequest!) {
  queries {
    betLine {
      change {
        getSubscribedEvents(input: $options) {
          ts
          data {
            eventIds
          }
        }
      }
    }
  }
}
    `;
export const getTempCouponsDocument = `
    query getTempCoupons($options: TempCouponsRequest!) {
  queries {
    bonuses {
      getTempCoupons(input: $options) {
        ts
        data {
          coupons
        }
      }
    }
  }
}
    `;
export const getTranslationsDocument = `
    query getTranslations($options: TranslationBundleByKeysInput!) {
  queries {
    system {
      getTranslationBundleByKeys(input: $options) {
        ts
        data {
          translations {
            key
            value
          }
        }
      }
    }
  }
}
    `;
export const getUpcomingMeetingDocument = `
    query getUpcomingMeeting {
  queries {
    verification {
      getUpcomingMeeting {
        ts
        data {
          meetingSlot {
            id
            startTime
            endTime
            roomUrl
          }
          isTimeChangeAvailable
        }
      }
    }
  }
}
    `;
export const getUserDataDocument = `
    query getUserData($options: UserDataRequest!) {
  queries {
    customer {
      getUserData(input: $options) {
        ts
        data {
          customerData {
            ...CustomerDataFields
          }
        }
      }
    }
  }
}
    ${CustomerDataFieldsFragmentDoc}`;
export const getUserDataPhoneDocument = `
    query getUserDataPhone($options: UserDataRequest!) {
  queries {
    customer {
      getUserData(input: $options) {
        ts
        data {
          customerData {
            ...CustomerDataPhoneFields
          }
        }
      }
    }
  }
}
    ${CustomerDataPhoneFieldsFragmentDoc}`;
export const getUserProfileBonusDocument = `
    query getUserProfileBonus($options: CustomerDataRequest!) {
  queries {
    customer {
      getProfileData(input: $options) {
        ts
        data {
          result
          profileData {
            isBonusEnabled
            isEncashEnabled
            isBonusAvailable
            minBonusAmount
            bonusAmount
            unreadOperatorMessagesCount
            waitingBetsAmount
            requestedWithdrawal
            bonusTermsAccepted
            bonusProgramStatus
            bonusProgramStatusClass
            isLeonShopEnabled
            isLeonShopOpen
            leonShopURL
            isHideAccNumIfCannotGamble
            requestedWithdrawals {
              requestedAmount
              requestedCount
              singlePaymentId
            }
          }
        }
      }
    }
  }
}
    `;
export const getVerificationTokenDocument = `
    query getVerificationToken($options: GetTokenRequest!) {
  queries {
    verification {
      getTokenV2(input: $options) {
        ts
        data {
          token
          purseMask
        }
      }
    }
  }
}
    `;
export const getWhatIsNewDataDocument = `
    query getWhatIsNewData {
  queries {
    whatsNew {
      getWhatsNew {
        ts
        data {
          keys {
            key
            content {
              lang
              html
              css
              javascript
            }
            order
            shown
          }
        }
      }
    }
  }
}
    `;
export const goBackDocument = `
    query goBack($options: PreviousFormInput!) {
  queries {
    registration {
      goBack(input: $options) {
        ts
        data {
          form {
            uiFormSchema {
              order
              fields {
                id
                widget
                hidden
                title
                defaultValue
                disabled
                hintMessage
                type
                encrypted
                isFrontOnly
                labels {
                  key
                  value
                }
                options {
                  key
                  value
                }
              }
            }
            formValidationSchema
            schemaId
            registrationType
            trigger
            formError
            formErrorCaption
            formSuccess
            validated
            hasFieldsErrors
            duplicateUser
            formName
            affiliateId
            stage
            priority
            failsCount
            formStep
            goBack {
              formParams {
                key
                value
              }
              schemaId
            }
            formProgress
          }
        }
      }
    }
  }
}
    `;
export const confirmManualApplicationDocument = `
    query confirmManualApplication($options: ManualCustomerDataConfirmRequest!) {
  mutations {
    identification {
      confirmManualApplication(input: $options) {
        ts
        data {
          manualCustomerData {
            status
          }
        }
      }
    }
  }
}
    `;
export const deleteDocumentsDocument = `
    query deleteDocuments($options: String!) {
  mutations {
    identification {
      deleteDoc(docType: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
export const dropManualDataDocument = `
    query dropManualData {
  mutations {
    identification {
      dropManualData {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
export const esiaDataFetchDocument = `
    query esiaDataFetch($options: EsiaResponseInput!) {
  mutations {
    identification {
      esiaDataFetch(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
export const esiaRedirectDocument = `
    query esiaRedirect($options: EmptyMutationRequest!) {
  mutations {
    identification {
      esiaRedirect(input: $options) {
        ts
        data {
          esiaRedirectDate
        }
      }
    }
  }
}
    `;
export const etsupisPhoneVerificationInitDocument = `
    query etsupisPhoneVerificationInit($options: EtsupisPhoneVerificationRequest!) {
  mutations {
    identification {
      phoneVerificationInit(input: $options) {
        ts
        data {
          result
          phoneCodeMethod
          canResendCodeAfterSeconds
        }
      }
    }
  }
}
    `;
export const etsupisPhoneVerificationVerifyDocument = `
    query etsupisPhoneVerificationVerify($options: EtsupisVerifyRequest!) {
  mutations {
    identification {
      phoneVerificationVerify(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
export const finishApplicationDocument = `
    query finishApplication {
  mutations {
    identification {
      finishApplication {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
export const getEsiaAouth2LinkDocument = `
    query getEsiaAouth2Link($options: EsiaAuth2LinkRequest!) {
  queries {
    identification {
      getEsiaAouth2Link(input: $options) {
        ts
        data {
          link
        }
      }
    }
  }
}
    `;
export const getIdentificationStateDocument = `
    query getIdentificationState {
  queries {
    identification {
      getState {
        ts
        data {
          identState {
            ...IdentStateFields
          }
        }
      }
    }
  }
}
    ${IdentStateFieldsFragmentDoc}`;
export const getThirdPartyIdentificationInitDocument = `
    query getThirdPartyIdentificationInit($options: ThirdPartyIdentificationRequest!) {
  mutations {
    identification {
      thirdPartyIdentificationInit(input: $options) {
        ts
        data {
          redirectUrl
        }
      }
    }
  }
}
    `;
export const initIdentAppDocument = `
    query initIdentApp {
  mutations {
    identification {
      initIdentApp {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
export const resetIdentificationRequestDocument = `
    query resetIdentificationRequest {
  mutations {
    identification {
      reset {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
export const saveIdentificationDraftDataDocument = `
    query saveIdentificationDraftData($options: ManualCustomerDataDraftSetRequest!) {
  mutations {
    identification {
      saveIdentificationDraftData(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
export const setInnDocument = `
    query setInn($options: SetInnRequest!) {
  mutations {
    identification {
      setInn(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
export const setManualDataDocument = `
    query setManualData($options: ManualCustomerDataSetRequest!) {
  mutations {
    identification {
      setManualData(input: $options) {
        ts
        data {
          manualCustomerData {
            birthDate
            docNr
            docType
            firstName
            inn
            innApiAttemptsCounter {
              attemptsMade
              isLocked
              lockTimeout
              totalAttempts
            }
            lastName
            middleName
            status
          }
        }
      }
    }
  }
}
    `;
export const submitDocumentsDocument = `
    query submitDocuments {
  mutations {
    identification {
      submit {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
export const getLobbyDocument = `
    query getLobby($options: LobbyRequest!) {
  queries {
    externalGames {
      getLobby(input: $options) {
        ts
        data {
          items {
            ...AbstractLobbyItemFields
            ... on LobbyGamesCategoryItem {
              ...LobbyGamesCategoryItemFields
            }
            ... on LobbyPromotionsItem {
              ...LobbyPromotionsItemFields
            }
            ... on LobbyGroupsItem {
              ...LobbyGroupsItemFields
            }
            ... on LobbyTopWinnersItem {
              ...LobbyTopWinnersItemFields
            }
            ... on LobbyBannersItem {
              ...LobbyBannerItemFields
            }
            ... on LobbySportlineTopMatchesItem {
              ...LobbySportlineTopMatchesItemFields
            }
            ... on LobbyAllTopWinnersItem {
              ...LobbyAllTopWinnersItemFields
            }
          }
        }
      }
    }
  }
}
    ${AbstractLobbyItemFieldsFragmentDoc}
${LobbyGamesCategoryItemFieldsFragmentDoc}
${LobbyPromotionsItemFieldsFragmentDoc}
${LobbyGroupsItemFieldsFragmentDoc}
${LobbyTopWinnersItemFieldsFragmentDoc}
${LobbyBannerItemFieldsFragmentDoc}
${LobbySportlineTopMatchesItemFieldsFragmentDoc}
${LobbyAllTopWinnersItemFieldsFragmentDoc}`;
export const logoutDocument = `
    query logout {
  mutations {
    auth {
      logout {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
export const makeBatchSlipDocument = `
    query makeBatchSlip($options: BatchMakeBetRequest!) {
  mutations {
    betSlip {
      makeBatchSlip(input: $options) {
        ts
        data {
          duration
          results {
            accepted
            betId
            errorCode
            errorMessage
            readyForCashout
            slipInfo {
              ...SlipInfoFields
            }
            betTransfer {
              items {
                sportId
                marketId
                sportName
                leagueName
                marketName
                runnerName
                eventName
                regionName
                winStatus
                isBanker
                eventId
              }
            }
          }
        }
      }
    }
  }
}
    ${SlipInfoFieldsFragmentDoc}`;
export const doDeleteNotificationDocument = `
    query doDeleteNotification($options: DeleteFrontNotificationRequest!) {
  mutations {
    frontNotifications {
      delete(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
export const doMarkAsClickedDocument = `
    query doMarkAsClicked($options: FrontNotificationClickedRequest!) {
  mutations {
    frontNotifications {
      markAsClicked(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
export const doMarkAsDeliveredDocument = `
    query doMarkAsDelivered($options: FrontNotificationDeliveredRequest!) {
  mutations {
    frontNotifications {
      markAsDelivered(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
export const doMarkNotificationDocument = `
    query doMarkNotification($options: ReadFrontNotificationRequest!) {
  mutations {
    frontNotifications {
      markAsRead(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
export const getNotificationsDocument = `
    query getNotifications($options: FrontNotificationsRequest!) {
  queries {
    frontNotifications {
      getNotifications(input: $options) {
        ts
        data {
          items {
            ...FrontNotificationFields
            ... on BonusActivationNotification {
              ...BonusActivationNotificationFields
            }
            ... on AlcrNotification {
              ...AlcrNotificationFields
            }
            ... on VideoVerificationNotification {
              ...VideoNotificationFields
            }
            ... on RefProgramNotification {
              ...RefProgramNotificationFields
            }
            ... on CryptoDepositStatusNotification {
              ...CryptoDepositStatusNotificationFields
            }
            ... on DepositNotification {
              ...DepositNotificationFields
            }
          }
          next {
            limit
            offset
          }
        }
      }
    }
  }
}
    ${FrontNotificationFieldsFragmentDoc}
${BonusActivationNotificationFieldsFragmentDoc}
${AlcrNotificationFieldsFragmentDoc}
${VideoNotificationFieldsFragmentDoc}
${RefProgramNotificationFieldsFragmentDoc}
${CryptoDepositStatusNotificationFieldsFragmentDoc}
${DepositNotificationFieldsFragmentDoc}`;
export const doCancelRequestedWithdrawalsDocument = `
    query doCancelRequestedWithdrawals($options: EmptyMutationRequest) {
  mutations {
    finance {
      withdrawal {
        cancelRequestedWithdrawals(input: $options) {
          ts
          data {
            totalCount
            totalAmount
          }
        }
      }
    }
  }
}
    `;
export const doSubmitDepositDocument = `
    query doSubmitDeposit($options: SubmitPaymentRequest!) {
  mutations {
    finance {
      deposit {
        submit(input: $options) {
          ts
          data {
            formStep
            result
            msg
            confirmationForm
            schemaId
            redirectUrl
            uiFormSchema {
              order
              fields {
                isFrontOnly
                id
                widget
                hidden
                title
                defaultValue
                disabled
                hintMessage
                encrypted
                options {
                  key
                  value
                  valueList {
                    ... on PaymentSystemOptions {
                      label
                      name
                      value
                      isCopyEnabled
                    }
                  }
                }
                type
                labels {
                  key
                  value
                }
              }
            }
            formValidationSchema
            cancel {
              formParams {
                key
                value
              }
              schemaId
            }
            redirectCharset
            redirectMethod
            redirectFormParams
            nettoAmount
            bruttoAmount
            taxAmount
            htmlContent
            htmlContentProperties {
              value
              key
            }
          }
        }
      }
    }
  }
}
    `;
export const doSubmitWithdrawalDocument = `
    query doSubmitWithdrawal($options: SubmitPaymentRequest!) {
  mutations {
    finance {
      withdrawal {
        submit(input: $options) {
          ts
          data {
            formStep
            result
            msg
            confirmationForm
            schemaId
            redirectUrl
            uiFormSchema {
              order
              fields {
                isFrontOnly
                id
                widget
                hidden
                title
                defaultValue
                disabled
                hintMessage
                encrypted
                options {
                  key
                  value
                }
                type
                labels {
                  key
                  value
                }
              }
            }
            formValidationSchema
            balance
            balanceTimestamp
            cancel {
              formParams {
                key
                value
              }
              schemaId
            }
            redirectCharset
            redirectFormParams
            redirectMethod
            nettoAmount
            bruttoAmount
            taxAmount
            htmlContent
            htmlContentProperties {
              value
              key
            }
          }
        }
      }
    }
  }
}
    `;
export const getCompanyFeeDocument = `
    query getCompanyFee($options: CompanyFeeRequest!) {
  queries {
    finance {
      getCompanyFee(input: $options) {
        ts
        data {
          companyFee
          anchorIgnore
          payableAmount
          result
        }
      }
    }
  }
}
    `;
export const getCryptoNetworkInfoDocument = `
    query getCryptoNetworkInfo($options: CryptoNetworkInfoRequest!) {
  queries {
    finance {
      deposit {
        getCryptoNetworkInfo(input: $options) {
          ts
          data {
            networks {
              key
              value
            }
            current {
              id
              qrCodeImgSrc
              address
              currency
              currencyRate
              cryptoName
              network
            }
          }
        }
      }
    }
  }
}
    `;
export const getDepositSystemsListDocument = `
    query getDepositSystemsList($options: GetDepositSystemsRequest) {
  queries {
    finance {
      deposit {
        getDepositSystemsList(input: $options) {
          ts
          data {
            isDepositRequiredEmailVerification
            isFirstDeposit
            paymentCategories {
              name
              isFolded
              paymentSystems {
                id
                name
                logoName
                logoSrc
                logoSrcThemed
                paymentSystemBadge {
                  isActiveAndValid
                }
                minAmount {
                  amount
                  currency
                }
                maxAmount {
                  amount
                  currency
                }
                depositCur
                disabled
                commission
                enrollmentTime
              }
            }
            errorCode
            message
          }
        }
      }
    }
  }
}
    `;
export const getDepositSystemsOverviewListDocument = `
    query getDepositSystemsOverviewList($options: DepositSystemInput) {
  queries {
    finance {
      depositSystem {
        getDepositSystemsList(input: $options) {
          ts
          data {
            depositcategories {
              name
              paymentSystems {
                url
                id
                name
                nameBold
                menuName
                isdisabled
                disabledtitle
                disabledcomment
                currencies
                comment
                enrollmentTime
                className
                imageUrl
                logoSrc
                logoName
                logoSrcThemed
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const getDepositsRegistrationCompletionStatusDocument = `
    query getDepositsRegistrationCompletionStatus($options: GetDepositSystemInfoRequest!) {
  queries {
    finance {
      deposit {
        getDepositSystemInfo(input: $options) {
          ts
          data {
            additionalRegistrationNeeded
          }
        }
      }
    }
  }
}
    `;
export const getDepositsSystemInfoDocument = `
    query getDepositsSystemInfo($options: GetDepositSystemInfoRequest!) {
  queries {
    finance {
      deposit {
        getDepositSystemInfo(input: $options) {
          ts
          data {
            hideNoSelectedBonusModal
            additionalRegistrationNeeded
            isDepositRequiredEmailVerification
            isFirstDeposit
            paymentSystemData {
              buttonName
              defaultInputValue
              showAdditionalInfo
              allowedIncrements {
                currency
                amount
              }
              allowInputSuggestedAmountsOnly
              suggestedAmounts {
                currency
                amounts
              }
              formStep
              paymentType
              id
              logoName
              logoSrc
              logoSrcThemed
              pmtmethod
              minAmount {
                amount
                currency
              }
              maxAmount {
                amount
                currency
              }
              instructions
              enrollmentTime
              paymentSystemId
              paymentType
              name
              description
              isTerminal
              depositCur
              isDepositReadOnly
              formValidationSchema
              schemaId
              openIn
              bankcards {
                pan
                type
                token
                phone
              }
              uiFormSchema {
                order
                fields {
                  isFrontOnly
                  id
                  widget
                  hidden
                  title
                  defaultValue
                  disabled
                  hintMessage
                  encrypted
                  options {
                    key
                    value
                  }
                  type
                  labels {
                    key
                    value
                  }
                }
              }
              isMobileCommerce
              allowNewCard
              bonuses {
                bonusCode
                campaignId
                campaignName
                campaignType
                depositAmountPercent
                description
                imgUrl
                actionUrl
                categoryId
                maxDepositAmount {
                  amount
                  currency
                }
                minDepositAmount {
                  amount
                  currency
                }
                terms
                image {
                  ...Web2ImageFields
                }
              }
              forcedCustomerVerificationRequired
              isCrypto
              isDeepLinkAllowed
              isP2p
            }
          }
        }
      }
    }
  }
}
    ${Web2ImageFieldsFragmentDoc}`;
export const getWithdrawalStatsDocument = `
    query getWithdrawalStats($options: EmptyQueryRequest) {
  queries {
    finance {
      getWithdrawalStats(input: $options) {
        data {
          requestedAmount
          requestedCount
          singlePaymentId
        }
        ts
      }
    }
  }
}
    `;
export const getWithdrawalSystemsListDocument = `
    query getWithdrawalSystemsList($options: GetWithdrawalSystemsRequest) {
  queries {
    finance {
      withdrawal {
        getWithdrawalSystemsList(input: $options) {
          ts
          data {
            paymentCategories {
              name
              isFolded
              paymentSystems {
                id
                name
                logoName
                logoSrc
                logoSrcThemed
                paymentSystemBadge {
                  isActiveAndValid
                }
                minAmount {
                  amount
                  currency
                }
                maxAmount {
                  amount
                  currency
                }
                depositCur
                disabled
                commission
                enrollmentTime
              }
            }
            hideCommissionHint
            errorCode
            message
          }
        }
      }
    }
  }
}
    `;
export const getWithdrawalSystemsOverviewListDocument = `
    query getWithdrawalSystemsOverviewList($options: GetWithdrawalSystemsRequest) {
  queries {
    finance {
      withdrawal {
        getWithdrawalSystemsNotAuthorized(input: $options) {
          ts
          data {
            errorCode
            message
            hideCommissionHint
            paymentCategories {
              name
              paymentSystems {
                id
                name
                logoName
                logoSrc
                minAmount {
                  amount
                  currency
                }
                maxAmount {
                  amount
                  currency
                }
                depositCur
                disabled
                enrollmentTime
                commission
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const getWithdrawalsRegistrationCompletionStatusDocument = `
    query getWithdrawalsRegistrationCompletionStatus($options: GetWithdrawalSystemInfoRequest!) {
  queries {
    finance {
      withdrawal {
        getWithdrawalSystemInfo(input: $options) {
          ts
          data {
            additionalRegistrationNeeded
          }
        }
      }
    }
  }
}
    `;
export const getWithdrawalsSystemInfoDocument = `
    query getWithdrawalsSystemInfo($options: GetWithdrawalSystemInfoRequest!) {
  queries {
    finance {
      withdrawal {
        getWithdrawalSystemInfo(input: $options) {
          ts
          data {
            additionalRegistrationNeeded
            isWithdrawalApplicationRequired
            isWithdrawalRequiredEmailVerification
            availableForWithdraw
            paymentSystemData {
              companyFeeThreshold
              companyFeePercent
              defaultInputValue
              showAdditionalInfo
              allowedIncrements {
                currency
                amount
              }
              allowInputSuggestedAmountsOnly
              suggestedAmounts {
                currency
                amounts
              }
              formStep
              paymentType
              id
              logoName
              logoSrc
              logoSrcThemed
              pmtmethod
              minAmount {
                amount
                currency
              }
              maxAmount {
                amount
                currency
              }
              instructions
              enrollmentTime
              paymentSystemId
              paymentType
              name
              description
              isTerminal
              formValidationSchema
              schemaId
              bankcards {
                pan
                type
                token
                phone
              }
              uiFormSchema {
                order
                fields {
                  isFrontOnly
                  id
                  widget
                  hidden
                  title
                  defaultValue
                  disabled
                  hintMessage
                  encrypted
                  options {
                    key
                    value
                  }
                  type
                  labels {
                    key
                    value
                  }
                }
              }
              isMobileCommerce
              isCrypto
              isDeepLinkAllowed
            }
            hideCommissionHint
            errorCode
            message
          }
        }
      }
    }
  }
}
    `;
export const doCreatePinCodeDocument = `
    query doCreatePinCode($options: CreatePinCodeRequest!) {
  mutations {
    pinCode {
      createPinCode(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
export const doDeletePinCodeDocument = `
    query doDeletePinCode($options: DeletePinCodeRequest!) {
  mutations {
    pinCode {
      deletePinCode(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
export const doVerifyPinCodeDocument = `
    query doVerifyPinCode($options: VerifyPinCodeRequest!) {
  mutations {
    pinCode {
      verifyPinCode(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
export const getPinCodeStatusDocument = `
    query getPinCodeStatus {
  queries {
    pinCode {
      getPinCodeStatus {
        ts
        data {
          wasPinCodeSetOnce
          status
          bannedUntil
        }
      }
    }
  }
}
    `;
export const doDeactivateTimerDocument = `
    query doDeactivateTimer($options: EmptyMutationRequest!) {
  mutations {
    playTimeControl {
      deactivateTimer(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
export const doPlayTimeControlDocument = `
    query doPlayTimeControl($options: ActivateTimerRequest!) {
  mutations {
    playTimeControl {
      setTimer(input: $options) {
        ts
        data {
          state {
            hours
            minutes
            timeLeft
            hours
            startDt
            percentLeft
          }
        }
      }
    }
  }
}
    `;
export const playTimeControlDocument = `
    query playTimeControl($options: EmptyQueryRequest!) {
  queries {
    playTimeControl {
      getState(input: $options) {
        ts
        data {
          state {
            hours
            minutes
            percentLeft
            startDt
            timeLeft
          }
        }
      }
    }
  }
}
    `;
export const doButtonMutationDocument = `
    query doButtonMutation($options: UpdatePromotionRequest!) {
  mutations {
    promotions {
      updatePromotion(input: $options) {
        ts
        data {
          actionButton {
            buttonType
            currencyCode
            state
            title
            value
          }
        }
      }
    }
  }
}
    `;
export const doPromotionParticipationDocument = `
    query doPromotionParticipation($options: SetParticipationRequest!) {
  mutations {
    promotions {
      setParticipation(input: $options) {
        ts
        data {
          confirmed
        }
      }
    }
  }
}
    `;
export const doPromotionRateDocument = `
    query doPromotionRate($options: RatePromotionRequest!) {
  mutations {
    promotions {
      rate(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
export const getActiveOrAvailableCampaignsDocument = `
    query getActiveOrAvailableCampaigns {
  queries {
    promotions {
      getActiveOrAvailableCampaigns {
        ts
        data {
          campaigns {
            actionUrl
            categoryId
            type
            campaignId
            state
            showNotification
          }
        }
      }
    }
  }
}
    `;
export const getBonusWalletPromotionDocument = `
    query getBonusWalletPromotion {
  queries {
    promotions {
      getBonusWalletPromotion {
        ts
        data {
          promotion {
            linkedCategoryId
            howToUseBonusWidget
            termsOfActions
            bonusTitle
            bonusItems {
              bonusItemTitle
              bonusItemImage
              bonusItemDescription
            }
            howWorksTitle
            howWorksItems {
              howWorksItemTitle
              howWorksItemDescription
            }
            actionId
            actionUrl
            bannerUrl
            bannerImage {
              ...Web2ImageFields
            }
            code
            description
            isArchived
            leaderBoardSize
            name
            shortDescription
            nativeScriptDescriptionKey
            startDateNumber
            endDateNumber
            isNew
            socialLinks {
              name
              url
            }
            participationType
            promotionType
            tournaments {
              name
              id
            }
          }
          isCustomerParticipating
          result
        }
      }
    }
  }
}
    ${Web2ImageFieldsFragmentDoc}`;
export const getPromotionCategoriesDocument = `
    query getPromotionCategories {
  queries {
    promotions {
      getPromotionCategories {
        ts
        data {
          categories {
            name
            categoryId
          }
        }
      }
    }
  }
}
    `;
export const getPromotionDetailsDocument = `
    query getPromotionDetails($options: PromotionRequest!) {
  queries {
    promotions {
      getPromotion(input: $options) {
        ts
        data {
          promotion {
            sportBonus {
              bonusId
              campaignId
              customerId
              customerLogin
              state
              bonusCreatedAt
              bonusAmount
              transferAmount
              withdrawalAmount
              currencyCode
              bonusRequirements {
                name
                requiredValue
                currentValue
                satisfied
                progressBarValue
                valueType
                localizedName {
                  key
                  value
                }
              }
              confirmation {
                confirmationRequired
                confirmed
              }
              bonusProgress
              wagerProgress
              wager {
                result
                expiredAt
                requirements {
                  name
                  requiredValue
                  currentValue
                  satisfied
                  progressBarValue
                  valueType
                }
              }
              freebet {
                state
                expiredAt
              }
              refuseAvailable
            }
            howToUseBonusWidget
            termsOfActions
            bonusTitle
            bonusItems {
              bonusItemTitle
              bonusItemImage
              bonusItemDescription
            }
            howWorksTitle
            howWorksItems {
              howWorksItemTitle
              howWorksItemDescription
            }
            actionId
            actionUrl
            bannerUrl
            bannerImage {
              ...Web2ImageFields
            }
            code
            description
            metaDescription
            isArchived
            leaderBoardSize
            name
            shortDescription
            nativeScriptDescriptionKey
            startDateNumber
            endDateNumber
            isNew
            socialLinks {
              name
              url
            }
            participationType
            promotionType
            actionButton {
              buttonType
              currencyCode
              state
              title
              value
              customLink
              hintMessage
            }
            leaderBoardDatesDescription
            tournaments {
              name
              id
            }
            isNT
            isShowEndDateCountdown
            isShowStartDateCountdown
            startDateCountdown
            isHot
            isBlockParticipationButton
            id
            likes
            dislikes
            customerRating
            categoryId
            shortBannerImage {
              ...Web2ImageFields
            }
            bgColor
            landingPageImage {
              ...Web2ImageFields
            }
          }
          isCustomerParticipating
          result
        }
      }
    }
  }
}
    ${Web2ImageFieldsFragmentDoc}`;
export const getPromotionNavigationConfigDocument = `
    query getPromotionNavigationConfig {
  queries {
    system {
      getNavigation(input: {list: ["PROMOTION_CATEGORIES_NAVIGATION"]}) {
        ts
        data {
          navigation
        }
      }
    }
  }
}
    `;
export const getPromotionsDocument = `
    query getPromotions($options: GetPromotionsRequest!) {
  queries {
    promotions {
      getPromotions(input: $options) {
        ts
        data {
          promotions {
            position
            description
            code
            actionUrl
            name
            shortDescription
            bannerUrl
            shortBannerUrl
            bannerImage {
              ...Web2ImageFields
            }
            shortBannerImage {
              ...Web2ImageFields
            }
            landingPageImage {
              ...Web2ImageFields
            }
            isNew
            endDateNumber
            startDateNumber
            actionId
            nativeScriptDescriptionKey
            leaderBoardSize
            isArchived
            isCustomerParticipating
            categoryId
            isNT
            isHot
            isShowEndDateCountdown
            isShowStartDateCountdown
            startDateCountdown
            isBlockParticipationButton
            id
            likes
            dislikes
            customerRating
            promotionType
            bgColor
          }
          result
        }
      }
    }
  }
}
    ${Web2ImageFieldsFragmentDoc}`;
export const doConfirmationsDocument = `
    query doConfirmations($options: EmptyMutationRequest) {
  mutations {
    refProgram {
      confirmations(input: $options) {
        ts
        data {
          confirmed
        }
      }
    }
  }
}
    `;
export const doRewardingDocument = `
    query doRewarding($options: RewardRequest!) {
  mutations {
    refProgram {
      reward(input: $options) {
        ts
        data {
          available
          confirmed
          playerRequirements {
            type
            satisfied
            requiredValue
            currency
          }
          rewardOptions {
            rewardTriggerType
            freespinValue
            freebetValue
            rewards {
              depositAmount
              rewardAmount {
                playerReward {
                  count
                  nominalValue
                  amount
                  rewardType
                }
                referralReward {
                  count
                  nominalValue
                  amount
                  rewardType
                }
              }
            }
          }
          totalRewardAmount
          referralsCount
          referrals {
            customerId
            status
            firstName
            lastName
            registrationDate
            requirements {
              type
              satisfied
              requiredValue
              currency
            }
            referralReward {
              count
              nominalValue
              amount
              rewardType
            }
            playerReward {
              count
              nominalValue
              amount
              rewardType
            }
            playerRewardOptions {
              count
              nominalValue
              amount
              rewardType
              game {
                id
                name
              }
              wagerMultiplier
              freebetMinOdds
              freespinProvider
              freespinProviderName
            }
          }
        }
      }
    }
  }
}
    `;
export const getAvailabilityDocument = `
    query getAvailability($options: EmptyQueryRequest!) {
  queries {
    refProgram {
      getAvailability(input: $options) {
        ts
        data {
          available
          referralCount
        }
      }
    }
  }
}
    `;
export const getDetailsDocument = `
    query getDetails($options: EmptyQueryRequest!) {
  queries {
    refProgram {
      getDetails(input: $options) {
        ts
        data {
          available
          referralsCount
          confirmed
          playerRequirements {
            type
            satisfied
            requiredValue
            currency
          }
          rewardOptions {
            rewardTriggerType
            freespinValue
            freebetValue
            rewards {
              depositAmount
              rewardAmount {
                playerReward {
                  count
                  nominalValue
                  amount
                  rewardType
                }
                referralReward {
                  count
                  nominalValue
                  amount
                  rewardType
                }
              }
            }
          }
          totalRewardAmount
          referralsCount
          referrals {
            customerId
            status
            firstName
            lastName
            registrationDate
            requirements {
              type
              satisfied
              requiredValue
              currency
            }
            referralReward {
              count
              nominalValue
              amount
              rewardType
            }
            playerReward {
              count
              nominalValue
              amount
              rewardType
            }
            playerRewardOptions {
              count
              nominalValue
              amount
              rewardType
              game {
                id
                name
              }
              wagerMultiplier
              freebetMinOdds
              freebetMaxOdds
              freespinProvider
              freespinProviderName
            }
          }
          referralUrl
        }
      }
    }
  }
}
    `;
export const getReferralDocument = `
    query getReferral($options: EmptyQueryRequest!) {
  queries {
    refProgram {
      getReferral(input: $options) {
        ts
        data {
          customerId
          rewards {
            depositAmount
            rewardAmount {
              playerReward {
                count
                nominalValue
                amount
                rewardType
              }
              referralReward {
                count
                nominalValue
                amount
                rewardType
              }
            }
          }
          referralDetails {
            customerId
            status
            registrationDate
            requirements {
              type
              satisfied
              requiredValue
              currency
            }
            referralReward {
              count
              nominalValue
              amount
              rewardType
            }
            playerReward {
              count
              nominalValue
              amount
              rewardType
            }
            playerRewardOptions {
              count
              nominalValue
              amount
              rewardType
              game {
                id
                name
              }
              wagerMultiplier
              freebetMinOdds
              freespinProvider
              freespinProviderName
            }
          }
        }
      }
    }
  }
}
    `;
export const getRewardsDocument = `
    query getRewards($options: RewardsRequest!) {
  queries {
    refProgram {
      getRewards(input: $options) {
        ts
        data {
          currency
          rewards {
            depositAmount
            rewardAmount {
              playerReward {
                count
                nominalValue
                amount
                rewardType
              }
              referralReward {
                count
                nominalValue
                amount
                rewardType
              }
            }
          }
          referralReward {
            count
            nominalValue
            amount
            rewardType
          }
          referralRequirements {
            type
            satisfied
            requiredValue
            currency
          }
        }
      }
    }
  }
}
    `;
export const doRegistrationDocument = `
    query doRegistration($options: RegisterInput!) {
  mutations {
    registration {
      submit(inputForm: $options) {
        ts
        data {
          result
          form {
            uiFormSchema {
              order
              fields {
                isFrontOnly
                id
                widget
                hidden
                title
                defaultValue
                disabled
                hintMessage
                type
                encrypted
                error
                labels {
                  key
                  value
                }
                options {
                  key
                  value
                }
              }
            }
            registrationType
            schemaId
            formValidationSchema
            formStep
            formName
            priority
            stage
            formProgress
            formError
            validated
            hasFieldsErrors
            duplicateUser
            failsCount
            sendSms {
              formParams {
                key
                value
              }
              schemaId
            }
            goBack {
              formParams {
                key
                value
              }
              schemaId
            }
          }
          generatedPassword
          customerData {
            ...CustomerDataFields
          }
        }
      }
    }
  }
}
    ${CustomerDataFieldsFragmentDoc}`;
export const getCurrenciesByCountryDocument = `
    query getCurrenciesByCountry($options: CountryCurrenciesRequest!) {
  queries {
    registration {
      getCurrenciesByCountry(input: $options) {
        ts
        data {
          currencies {
            text
            value
          }
        }
      }
    }
  }
}
    `;
export const getIncompleteRegistrationFieldsDocument = `
    query getIncompleteRegistrationFields($options: IncompleteFieldsRequest!) {
  queries {
    registration {
      getIncompleteRegistrationFields(input: $options) {
        ts
        data {
          schemaId
          formValidationSchema
          uiFormSchema {
            order
            fields {
              isFrontOnly
              id
              widget
              hidden
              title
              defaultValue
              disabled
              hintMessage
              options {
                key
                value
              }
              labels {
                key
                value
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const updateIncompleteRegistrationFieldsDocument = `
    query updateIncompleteRegistrationFields($options: UpdateIncompleteFieldsRequest!) {
  mutations {
    registration {
      updateIncompleteRegistrationFields(input: $options) {
        ts
        data {
          result
          registrationForm {
            schemaId
            formValidationSchema
            uiFormSchema {
              order
              fields {
                error
                isFrontOnly
                id
                widget
                hidden
                title
                defaultValue
                disabled
                hintMessage
                options {
                  key
                  value
                }
                labels {
                  key
                  value
                }
              }
            }
            trigger
            formError
            formStep
            formErrorCaption
            formSuccess
            validated
            hasFieldsErrors
            duplicateUser
            formName
            affiliateId
            stage
            priority
            failsCount
          }
        }
      }
    }
  }
}
    `;
export const removeCustomerMessageDocument = `
    query removeCustomerMessage($options: DeleteMessageRequest!) {
  mutations {
    customer {
      messages {
        deleteMessage(input: $options) {
          ts
          data {
            result
          }
        }
      }
    }
  }
}
    `;
export const resendVerificationEmailDocument = `
    query resendVerificationEmail($options: EmptyMutationRequest!) {
  mutations {
    customer {
      resendVerificationEmail(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
export const resetDisposableKeysDocument = `
    query resetDisposableKeys($options: G2SVCodeRequest!) {
  mutations {
    g2sv {
      resetDisposableKeys(input: $options) {
        ts
        data {
          disposableKeys
        }
      }
    }
  }
}
    `;
export const resetShownDocument = `
    query resetShown($options: EmptyMutationRequest) {
  mutations {
    whatsNew {
      resetShown(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
export const checkRestorePasswordSmsDocument = `
    query checkRestorePasswordSms($options: PwdRecoveryPhoneCheckSmsInput!) {
  mutations {
    pwdRecoveryPhone {
      checkSms(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
export const checkRestorePasswordUidDocument = `
    query checkRestorePasswordUid($options: PwdRecoveryEmailCheckUidInput!) {
  mutations {
    pwdRecoveryEmail {
      isValidUid(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
export const doRestorePasswordByEmailDocument = `
    query doRestorePasswordByEmail($options: PwdRecoveryEmailChangePwdInput!) {
  mutations {
    pwdRecoveryEmail {
      changePwdByEmail(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
export const doRestorePasswordByPhoneDocument = `
    query doRestorePasswordByPhone($options: PwdRecoveryPhoneChangePwdInput!) {
  mutations {
    pwdRecoveryPhone {
      changePwdByPhone(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
export const resetPinCodeEmailDocument = `
    query resetPinCodeEmail($options: ResetPinCodeRequest!) {
  mutations {
    pinCode {
      resetPinCodeEmail(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
export const resetPinCodePhoneDocument = `
    query resetPinCodePhone($options: ResetPinCodeRequest!) {
  mutations {
    pinCode {
      resetPinCodePhone(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
export const sendRestorePasswordLinkToEmailDocument = `
    query sendRestorePasswordLinkToEmail($options: PwdRecoveryEmailSendUidInput!) {
  mutations {
    pwdRecoveryEmail {
      sendUid(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
export const sendRestorePasswordSmsOrCallToPhoneDocument = `
    query sendRestorePasswordSmsOrCallToPhone($options: PwdRecoveryPhoneSendSmsInput!) {
  mutations {
    pwdRecoveryPhone {
      sendPasswordResetVerificationCode(input: $options) {
        ts
        data {
          formStep
        }
      }
    }
  }
}
    `;
export const sendRestorePasswordSmsToPhoneDocument = `
    query sendRestorePasswordSmsToPhone($options: PwdRecoveryPhoneSendSmsInput!) {
  mutations {
    pwdRecoveryPhone {
      sendSms(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
export const sendRestorePinLinkToEmailDocument = `
    query sendRestorePinLinkToEmail($options: EmptyMutationRequest!) {
  mutations {
    pinCode {
      sendResetTokenEmail(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
export const sendRestorePinLinkToPhoneDocument = `
    query sendRestorePinLinkToPhone($options: SendResetTokenPhoneRequest!) {
  mutations {
    pinCode {
      sendResetTokenPhone(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
export const savePushSettingsDocument = `
    query savePushSettings($options: PushNotificationSettingsRequest!) {
  mutations {
    customer {
      savePushNotificationSettings(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
export const getSpintaxMetaDataDocument = `
    query getSpintaxMetaData($options: SpintaxMetadataRequest!) {
  queries {
    system {
      cms {
        getSpintaxMetadata(input: $options) {
          ts
          data {
            result
            metadata
          }
        }
      }
    }
  }
}
    `;
export const setAdditionalPropsUserDocument = `
    query setAdditionalPropsUser($options: CustomerConfigRequest!) {
  mutations {
    customer {
      config {
        setConfig(input: $options) {
          ts
          data {
            result
          }
        }
      }
    }
  }
}
    `;
export const setBannerClosedDocument = `
    query setBannerClosed($options: SetBannerClosedRequest!) {
  mutations {
    banners {
      setBannerClosed(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
export const setBetLimitsDocument = `
    query setBetLimits($options: SetCustomerLimitsRequest!) {
  mutations {
    limits {
      setBetLimits(input: $options) {
        ts
        data {
          result
          limitsUpdatableAt {
            dailyUpdatableAt
            weeklyUpdatableAt
            monthlyUpdatableAt
          }
          limits {
            dailyLimit
            weeklyLimit
            monthlyLimit
            dailyPercent
            dailyLeft
            dailyEndAt
            weeklyPercent
            weeklyLeft
            weeklyEndAt
            monthlyPercent
            monthlyLeft
            monthlyEndAt
          }
        }
      }
    }
  }
}
    `;
export const setCoookieDocument = `
    query setCoookie($options: SetCookieRequest!) {
  mutations {
    cookies {
      setCookie(input: $options) {
        ts
        data {
          cookies {
            name
            value
            version
            maxAge
            path
            secure
            httpOnly
          }
        }
      }
    }
  }
}
    `;
export const setDepositLimitsDocument = `
    query setDepositLimits($options: SetCustomerLimitsRequest!) {
  mutations {
    limits {
      setDepositLimits(input: $options) {
        ts
        data {
          result
          limitsUpdatableAt {
            dailyUpdatableAt
            weeklyUpdatableAt
            monthlyUpdatableAt
          }
          limits {
            dailyLimit
            weeklyLimit
            monthlyLimit
            dailyPercent
            dailyLeft
            dailyEndAt
            weeklyPercent
            weeklyLeft
            weeklyEndAt
            monthlyPercent
            monthlyLeft
            monthlyEndAt
          }
        }
      }
    }
  }
}
    `;
export const setDowntimeShownDocument = `
    query setDowntimeShown($options: SetDowntimeShownRequest!) {
  mutations {
    cookies {
      setDowntimeShown(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
export const setWhatsNewDocument = `
    query setWhatsNew($options: SetWhatsNewRequest!) {
  mutations {
    whatsNew {
      setWhatsNew(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
export const getTsupisLegalAgreementsVersionsDocument = `
    query getTsupisLegalAgreementsVersions($options: LegalAgreementsRequest!) {
  queries {
    registration {
      getLegalAgreement(input: $options) {
        ts
        data {
          offer {
            version
          }
          personalDataAgreement {
            version
          }
        }
      }
    }
  }
}
    `;
export const getTsupisOfferAgreementsDocument = `
    query getTsupisOfferAgreements($options: LegalAgreementsRequest!) {
  queries {
    registration {
      getLegalAgreement(input: $options) {
        ts
        data {
          offer {
            version
            text
          }
        }
      }
    }
  }
}
    `;
export const getTsupisPersonalDataAgreementsDocument = `
    query getTsupisPersonalDataAgreements($options: LegalAgreementsRequest!) {
  queries {
    registration {
      getLegalAgreement(input: $options) {
        ts
        data {
          personalDataAgreement {
            version
            text
          }
        }
      }
    }
  }
}
    `;
export const updateCustomerProfileFieldsDocument = `
    query updateCustomerProfileFields($options: UpdateIncompleteFieldsRequest!) {
  mutations {
    registration {
      updateCustomerProfileFields(input: $options) {
        ts
        data {
          result
          registrationForm {
            registrationType
            uiFormSchema {
              order
              fields {
                error
                isFrontOnly
                id
                widget
                hidden
                title
                defaultValue
                disabled
                hintMessage
                options {
                  key
                  value
                }
                labels {
                  key
                  value
                }
              }
            }
            formValidationSchema
            schemaId
            formStep
            trigger
            formError
            formErrorCaption
            formSuccess
            validated
            hasFieldsErrors
            duplicateUser
            formName
            affiliateId
            stage
            priority
            failsCount
          }
        }
      }
    }
  }
}
    `;
export const verifyEmailDocument = `
    query verifyEmail($options: VerifyEmailRequest!) {
  mutations {
    customer {
      verifyEmail(input: $options) {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
export const withdrawBonusCBCDocument = `
    query withdrawBonusCBC {
  mutations {
    bonuses {
      withdrawBonus {
        ts
        data {
          result
        }
      }
    }
  }
}
    `;
export let mocks = {};
export function mockGetBetlineLeagueStandings(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getBetlineLeagueStandings(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getBetlineLeagueStandingsDocument), variables, cb, requestOptions);
}
export function mockGetBetlineLeagueStatus(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getBetlineLeagueStatus(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getBetlineLeagueStatusDocument), variables, cb, requestOptions);
}
export function mockGetBetlineLeagueTopPlayers(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getBetlineLeagueTopPlayers(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getBetlineLeagueTopPlayersDocument), variables, cb, requestOptions);
}
export function mockGetBetlineMatchEventStatistics(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getBetlineMatchEventStatistics(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getBetlineMatchEventStatisticsDocument), variables, cb, requestOptions);
}
export function mockGetBetlineMatchStatistics(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getBetlineMatchStatistics(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getBetlineMatchStatisticsDocument), variables, cb, requestOptions);
}
export function mockGetLeagueData(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getLeagueData(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getLeagueDataDocument), variables, cb, requestOptions);
}
export function mockGetLeaguePlayoffByLeagueId(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getLeaguePlayoffByLeagueId(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getLeaguePlayoffByLeagueIdDocument), variables, cb, requestOptions);
}
export function mockGetLeagueStandingsByLeagueId(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getLeagueStandingsByLeagueId(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getLeagueStandingsByLeagueIdDocument), variables, cb, requestOptions);
}
export function mockGetLeagueStatisticsByLeagueId(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getLeagueStatisticsByLeagueId(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getLeagueStatisticsByLeagueIdDocument), variables, cb, requestOptions);
}
export function mockDoCalculatePack(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doCalculatePack(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doCalculatePackDocument), variables, cb, requestOptions);
}
export function mockDoCalculateRound(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doCalculateRound(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doCalculateRoundDocument), variables, cb, requestOptions);
}
export function mockDoCollectPackReward(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doCollectPackReward(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doCollectPackRewardDocument), variables, cb, requestOptions);
}
export function mockGetAllPacks(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getAllPacks(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getAllPacksDocument), variables, cb, requestOptions);
}
export function mockGetPacks(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getPacks(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getPacksDocument), variables, cb, requestOptions);
}
export function mockGetRounds(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getRounds(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getRoundsDocument), variables, cb, requestOptions);
}
export function mockDoConfirmCashback(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doConfirmCashback(client, cb) {
    let variables = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, requestOptions = arguments.length > 3 ? arguments[3] : void 0;
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doConfirmCashbackDocument), variables, cb, requestOptions);
}
export function mockDoRefuseCashback(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doRefuseCashback(client, cb) {
    let variables = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, requestOptions = arguments.length > 3 ? arguments[3] : void 0;
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doRefuseCashbackDocument), variables, cb, requestOptions);
}
export function mockGetCashback(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getCashback(client, cb) {
    let variables = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, requestOptions = arguments.length > 3 ? arguments[3] : void 0;
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getCashbackDocument), variables, cb, requestOptions);
}
export function mockAddFavoriteGame(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function addFavoriteGame(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(addFavoriteGameDocument), variables, cb, requestOptions);
}
export function mockGetCasinoLoyaltyStatus(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getCasinoLoyaltyStatus(client, cb) {
    let variables = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, requestOptions = arguments.length > 3 ? arguments[3] : void 0;
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getCasinoLoyaltyStatusDocument), variables, cb, requestOptions);
}
export function mockGetPromotionsByEgsGame(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getPromotionsByEgsGame(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getPromotionsByEgsGameDocument), variables, cb, requestOptions);
}
export function mockGetVpnConfig(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getVpnConfig(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getVpnConfigDocument), variables, cb, requestOptions);
}
export function mockRemoveFavoriteGame(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function removeFavoriteGame(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(removeFavoriteGameDocument), variables, cb, requestOptions);
}
export function mockCheckPushTokenExists(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function checkPushTokenExists(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(checkPushTokenExistsDocument), variables, cb, requestOptions);
}
export function mockClearCookies(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function clearCookies(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(clearCookiesDocument), variables, cb, requestOptions);
}
export function mockDoMarkDeviceUntrusted(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doMarkDeviceUntrusted(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doMarkDeviceUntrustedDocument), variables, cb, requestOptions);
}
export function mockDoModifyTimeZone(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doModifyTimeZone(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doModifyTimeZoneDocument), variables, cb, requestOptions);
}
export function mockDoRefuseBonus(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doRefuseBonus(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doRefuseBonusDocument), variables, cb, requestOptions);
}
export function mockGetBonusMatrix(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getBonusMatrix(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getBonusMatrixDocument), variables, cb, requestOptions);
}
export function mockGetCustomerBonuses(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getCustomerBonuses(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getCustomerBonusesDocument), variables, cb, requestOptions);
}
export function mockSaveBetSlipConfiguration(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function saveBetSlipConfiguration(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(saveBetSlipConfigurationDocument), variables, cb, requestOptions);
}
export function mockCustomerIdentityCheck(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function customerIdentityCheck(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(customerIdentityCheckDocument), variables, cb, requestOptions);
}
export function mockDisableCustomerMode(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function disableCustomerMode(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(disableCustomerModeDocument), variables, cb, requestOptions);
}
export function mockDoActivateAffiliateProgram(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doActivateAffiliateProgram(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doActivateAffiliateProgramDocument), variables, cb, requestOptions);
}
export function mockDoActivateBonusCode(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doActivateBonusCode(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doActivateBonusCodeDocument), variables, cb, requestOptions);
}
export function mockDoAddLoginDevice(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doAddLoginDevice(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doAddLoginDeviceDocument), variables, cb, requestOptions);
}
export function mockDoAppsFlyerQualifiedDepositsReported(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doAppsFlyerQualifiedDepositsReported(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doAppsFlyerQualifiedDepositsReportedDocument), variables, cb, requestOptions);
}
export function mockDoCashOut(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doCashOut(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doCashOutDocument), variables, cb, requestOptions);
}
export function mockDoChangeCustomerNickname(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doChangeCustomerNickname(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doChangeCustomerNicknameDocument), variables, cb, requestOptions);
}
export function mockDoChangeCustomerSettings(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doChangeCustomerSettings(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doChangeCustomerSettingsDocument), variables, cb, requestOptions);
}
export function mockDoChangeLanguage(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doChangeLanguage(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doChangeLanguageDocument), variables, cb, requestOptions);
}
export function mockDoChangePassword(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doChangePassword(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doChangePasswordDocument), variables, cb, requestOptions);
}
export function mockDoConfirmBonus(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doConfirmBonus(client, cb) {
    let variables = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, requestOptions = arguments.length > 3 ? arguments[3] : void 0;
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doConfirmBonusDocument), variables, cb, requestOptions);
}
export function mockDoCreateBonusCode(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doCreateBonusCode(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doCreateBonusCodeDocument), variables, cb, requestOptions);
}
export function mockDoEncashBonus(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doEncashBonus(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doEncashBonusDocument), variables, cb, requestOptions);
}
export function mockDoEventSubscribe(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doEventSubscribe(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doEventSubscribeDocument), variables, cb, requestOptions);
}
export function mockDoEventUnsubscribe(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doEventUnsubscribe(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doEventUnsubscribeDocument), variables, cb, requestOptions);
}
export function mockDoFavoriteSportEventRemove(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doFavoriteSportEventRemove(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doFavoriteSportEventRemoveDocument), variables, cb, requestOptions);
}
export function mockDoFavoriteSportEventsAdd(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doFavoriteSportEventsAdd(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doFavoriteSportEventsAddDocument), variables, cb, requestOptions);
}
export function mockDoLogin(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doLogin(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doLoginDocument), variables, cb, requestOptions);
}
export function mockDoLoginCas(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doLoginCas(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doLoginCasDocument), variables, cb, requestOptions);
}
export function mockDoLoginFingerprint(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doLoginFingerprint(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doLoginFingerprintDocument), variables, cb, requestOptions);
}
export function mockDoLoginG2sv(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doLoginG2sv(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doLoginG2svDocument), variables, cb, requestOptions);
}
export function mockDoLoginPinCode(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doLoginPinCode(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doLoginPinCodeDocument), variables, cb, requestOptions);
}
export function mockDoPostponeWebPushToken(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doPostponeWebPushToken(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doPostponeWebPushTokenDocument), variables, cb, requestOptions);
}
export function mockDoReadCustomerNotification(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doReadCustomerNotification(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doReadCustomerNotificationDocument), variables, cb, requestOptions);
}
export function mockDoRefuseMeeting(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doRefuseMeeting(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doRefuseMeetingDocument), variables, cb, requestOptions);
}
export function mockDoRegisterCustomerOrder(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doRegisterCustomerOrder(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doRegisterCustomerOrderDocument), variables, cb, requestOptions);
}
export function mockDoRegisterPushToken(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doRegisterPushToken(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doRegisterPushTokenDocument), variables, cb, requestOptions);
}
export function mockDoRequestCallback(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doRequestCallback(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doRequestCallbackDocument), variables, cb, requestOptions);
}
export function mockDoSaveFastBets(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doSaveFastBets(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doSaveFastBetsDocument), variables, cb, requestOptions);
}
export function mockDoSaveLog(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doSaveLog(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doSaveLogDocument), variables, cb, requestOptions);
}
export function mockDoSavePriceChangePolicy(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doSavePriceChangePolicy(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doSavePriceChangePolicyDocument), variables, cb, requestOptions);
}
export function mockDoSaveShowMaxFastBet(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doSaveShowMaxFastBet(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doSaveShowMaxFastBetDocument), variables, cb, requestOptions);
}
export function mockDoSaveSportFilters(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doSaveSportFilters(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doSaveSportFiltersDocument), variables, cb, requestOptions);
}
export function mockDoScheduleMeeting(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doScheduleMeeting(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doScheduleMeetingDocument), variables, cb, requestOptions);
}
export function mockDoSelfExclusion(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doSelfExclusion(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doSelfExclusionDocument), variables, cb, requestOptions);
}
export function mockDoSendFeedback(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doSendFeedback(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doSendFeedbackDocument), variables, cb, requestOptions);
}
export function mockDoSendWeb2Feedback(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doSendWeb2Feedback(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doSendWeb2FeedbackDocument), variables, cb, requestOptions);
}
export function mockDoToggleCustomerLeague(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doToggleCustomerLeague(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doToggleCustomerLeagueDocument), variables, cb, requestOptions);
}
export function mockDoTsupisCardDelete(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doTsupisCardDelete(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doTsupisCardDeleteDocument), variables, cb, requestOptions);
}
export function mockDoUpdateCustomerData(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doUpdateCustomerData(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doUpdateCustomerDataDocument), variables, cb, requestOptions);
}
export function mockDoUpdateNotificationConsents(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doUpdateNotificationConsents(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doUpdateNotificationConsentsDocument), variables, cb, requestOptions);
}
export function mockDoWithdrawAllSportBonuses(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doWithdrawAllSportBonuses(client, cb) {
    let variables = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, requestOptions = arguments.length > 3 ? arguments[3] : void 0;
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doWithdrawAllSportBonusesDocument), variables, cb, requestOptions);
}
export function mockDoWithdrawSportBonus(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doWithdrawSportBonus(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doWithdrawSportBonusDocument), variables, cb, requestOptions);
}
export function mockDoWithdrawalCancel(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doWithdrawalCancel(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doWithdrawalCancelDocument), variables, cb, requestOptions);
}
export function mockGetEgsCategories(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getEgsCategories(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getEgsCategoriesDocument), variables, cb, requestOptions);
}
export function mockGetEgsGameById(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getEgsGameById(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getEgsGameByIdDocument), variables, cb, requestOptions);
}
export function mockGetEgsGameByUrl(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getEgsGameByUrl(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getEgsGameByUrlDocument), variables, cb, requestOptions);
}
export function mockGetEgsGames(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getEgsGames(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getEgsGamesDocument), variables, cb, requestOptions);
}
export function mockGetEgsGroups(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getEgsGroups(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getEgsGroupsDocument), variables, cb, requestOptions);
}
export function mockGetEgsTopWinners(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getEgsTopWinners(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getEgsTopWinnersDocument), variables, cb, requestOptions);
}
export function mockStartEgsGame(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function startEgsGame(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(startEgsGameDocument), variables, cb, requestOptions);
}
export function mockEnableCustomerMode(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function enableCustomerMode(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(enableCustomerModeDocument), variables, cb, requestOptions);
}
export function mockEnableFingerprintLogin(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function enableFingerprintLogin(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(enableFingerprintLoginDocument), variables, cb, requestOptions);
}
export function mockGenerateNickname(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function generateNickname(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(generateNicknameDocument), variables, cb, requestOptions);
}
export function mockGetAccountTransaction(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getAccountTransaction(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getAccountTransactionDocument), variables, cb, requestOptions);
}
export function mockGetAffiliatedCustomers(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getAffiliatedCustomers(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getAffiliatedCustomersDocument), variables, cb, requestOptions);
}
export function mockGetApiSettings(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getApiSettings(client, cb) {
    let variables = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, requestOptions = arguments.length > 3 ? arguments[3] : void 0;
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getApiSettingsDocument), variables, cb, requestOptions);
}
export function mockGetAuthenticatorUri(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getAuthenticatorUri(client, cb) {
    let variables = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, requestOptions = arguments.length > 3 ? arguments[3] : void 0;
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getAuthenticatorUriDocument), variables, cb, requestOptions);
}
export function mockGetAuthenticatorUriQRCode(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getAuthenticatorUriQRCode(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getAuthenticatorUriQRCodeDocument), variables, cb, requestOptions);
}
export function mockGetAvailableBonusesForTransfer(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getAvailableBonusesForTransfer(client, cb) {
    let variables = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, requestOptions = arguments.length > 3 ? arguments[3] : void 0;
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getAvailableBonusesForTransferDocument), variables, cb, requestOptions);
}
export function mockGetAvailableForms(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getAvailableForms(client, cb) {
    let variables = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, requestOptions = arguments.length > 3 ? arguments[3] : void 0;
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getAvailableFormsDocument), variables, cb, requestOptions);
}
export function mockGetAvailableMeetingSlots(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getAvailableMeetingSlots(client, cb) {
    let variables = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, requestOptions = arguments.length > 3 ? arguments[3] : void 0;
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getAvailableMeetingSlotsDocument), variables, cb, requestOptions);
}
export function mockGetBalance(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getBalance(client, cb) {
    let variables = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, requestOptions = arguments.length > 3 ? arguments[3] : void 0;
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getBalanceDocument), variables, cb, requestOptions);
}
export function mockGetBanner(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getBanner(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getBannerDocument), variables, cb, requestOptions);
}
export function mockGetBanners(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getBanners(client, cb) {
    let variables = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, requestOptions = arguments.length > 3 ? arguments[3] : void 0;
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getBannersDocument), variables, cb, requestOptions);
}
export function mockGetBatchSlipInfo(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getBatchSlipInfo(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getBatchSlipInfoDocument), variables, cb, requestOptions);
}
export function mockGetBet(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getBet(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getBetDocument), variables, cb, requestOptions);
}
export function mockGetBetLimits(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getBetLimits(client, cb) {
    let variables = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, requestOptions = arguments.length > 3 ? arguments[3] : void 0;
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getBetLimitsDocument), variables, cb, requestOptions);
}
export function mockGetBetgeniusWidgetConfiguration(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getBetgeniusWidgetConfiguration(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getBetgeniusWidgetConfigurationDocument), variables, cb, requestOptions);
}
export function mockGetBonusCodes(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getBonusCodes(client, cb) {
    let variables = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, requestOptions = arguments.length > 3 ? arguments[3] : void 0;
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getBonusCodesDocument), variables, cb, requestOptions);
}
export function mockGetBonusLoyalty(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getBonusLoyalty(client, cb) {
    let variables = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, requestOptions = arguments.length > 3 ? arguments[3] : void 0;
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getBonusLoyaltyDocument), variables, cb, requestOptions);
}
export function mockGetBonusTransferAmount(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getBonusTransferAmount(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getBonusTransferAmountDocument), variables, cb, requestOptions);
}
export function mockGetBonusWagers(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getBonusWagers(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getBonusWagersDocument), variables, cb, requestOptions);
}
export function mockGetBonuses(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getBonuses(client, cb) {
    let variables = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, requestOptions = arguments.length > 3 ? arguments[3] : void 0;
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getBonusesDocument), variables, cb, requestOptions);
}
export function mockGetCampaignCoupons(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getCampaignCoupons(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getCampaignCouponsDocument), variables, cb, requestOptions);
}
export function mockGetCashoutOffer(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getCashoutOffer(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getCashoutOfferDocument), variables, cb, requestOptions);
}
export function mockGetCmsContentSpintaxBatchTranslations(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getCmsContentSpintaxBatchTranslations(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getCmsContentSpintaxBatchTranslationsDocument), variables, cb, requestOptions);
}
export function mockGetCmsContentTranslations(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getCmsContentTranslations(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getCmsContentTranslationsDocument), variables, cb, requestOptions);
}
export function mockGetConsents(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getConsents(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getConsentsDocument), variables, cb, requestOptions);
}
export function mockGetCookie(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getCookie(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getCookieDocument), variables, cb, requestOptions);
}
export function mockGetCustomerHistory(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getCustomerHistory(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getCustomerHistoryDocument), variables, cb, requestOptions);
}
export function mockGetCustomerHistoryItem(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getCustomerHistoryItem(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getCustomerHistoryItemDocument), variables, cb, requestOptions);
}
export function mockGetCustomerHistoryList(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getCustomerHistoryList(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getCustomerHistoryListDocument), variables, cb, requestOptions);
}
export function mockGetCustomerLeague(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getCustomerLeague(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getCustomerLeagueDocument), variables, cb, requestOptions);
}
export function mockGetCustomerLeagues(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getCustomerLeagues(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getCustomerLeaguesDocument), variables, cb, requestOptions);
}
export function mockGetCustomerMatchStreamEvents(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getCustomerMatchStreamEvents(client, cb) {
    let variables = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, requestOptions = arguments.length > 3 ? arguments[3] : void 0;
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getCustomerMatchStreamEventsDocument), variables, cb, requestOptions);
}
export function mockGetCustomerMatches(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getCustomerMatches(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getCustomerMatchesDocument), variables, cb, requestOptions);
}
export function mockGetCustomerNotifications(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getCustomerNotifications(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getCustomerNotificationsDocument), variables, cb, requestOptions);
}
export function mockGetCustomerNotificationsCount(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getCustomerNotificationsCount(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getCustomerNotificationsCountDocument), variables, cb, requestOptions);
}
export function mockGetCustomerOffers(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getCustomerOffers(client, cb) {
    let variables = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, requestOptions = arguments.length > 3 ? arguments[3] : void 0;
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getCustomerOffersDocument), variables, cb, requestOptions);
}
export function mockGetCustomerOrders(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getCustomerOrders(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getCustomerOrdersDocument), variables, cb, requestOptions);
}
export function mockGetCustomerProfileFields(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getCustomerProfileFields(client, cb) {
    let variables = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, requestOptions = arguments.length > 3 ? arguments[3] : void 0;
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getCustomerProfileFieldsDocument), variables, cb, requestOptions);
}
export function mockGetCustomerVerifyData(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getCustomerVerifyData(client, cb) {
    let variables = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, requestOptions = arguments.length > 3 ? arguments[3] : void 0;
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getCustomerVerifyDataDocument), variables, cb, requestOptions);
}
export function mockGetDepositLimits(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getDepositLimits(client, cb) {
    let variables = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, requestOptions = arguments.length > 3 ? arguments[3] : void 0;
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getDepositLimitsDocument), variables, cb, requestOptions);
}
export function mockGetEncashBonusData(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getEncashBonusData(client, cb) {
    let variables = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, requestOptions = arguments.length > 3 ? arguments[3] : void 0;
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getEncashBonusDataDocument), variables, cb, requestOptions);
}
export function mockGetFAQCategories(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getFAQCategories(client, cb) {
    let variables = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, requestOptions = arguments.length > 3 ? arguments[3] : void 0;
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getFAQCategoriesDocument), variables, cb, requestOptions);
}
export function mockGetFAQItems(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getFAQItems(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getFAQItemsDocument), variables, cb, requestOptions);
}
export function mockGetFeedbackTypes(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getFeedbackTypes(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getFeedbackTypesDocument), variables, cb, requestOptions);
}
export function mockGetFormSchema(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getFormSchema(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getFormSchemaDocument), variables, cb, requestOptions);
}
export function mockGetFreebetData(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getFreebetData(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getFreebetDataDocument), variables, cb, requestOptions);
}
export function mockGetGeneralStats(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getGeneralStats(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getGeneralStatsDocument), variables, cb, requestOptions);
}
export function mockGetInlineChats(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getInlineChats(client, cb) {
    let variables = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, requestOptions = arguments.length > 3 ? arguments[3] : void 0;
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getInlineChatsDocument), variables, cb, requestOptions);
}
export function mockGetIntercomUserHash(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getIntercomUserHash(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getIntercomUserHashDocument), variables, cb, requestOptions);
}
export function mockGetLSportsWidgetConfiguration(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getLSportsWidgetConfiguration(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getLSportsWidgetConfigurationDocument), variables, cb, requestOptions);
}
export function mockGetLastBetResult(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getLastBetResult(client, cb) {
    let variables = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, requestOptions = arguments.length > 3 ? arguments[3] : void 0;
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getLastBetResultDocument), variables, cb, requestOptions);
}
export function mockGetLeaderBoard(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getLeaderBoard(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getLeaderBoardDocument), variables, cb, requestOptions);
}
export function mockGetLexGuardSession(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getLexGuardSession(client, cb) {
    let variables = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, requestOptions = arguments.length > 3 ? arguments[3] : void 0;
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getLexGuardSessionDocument), variables, cb, requestOptions);
}
export function mockGetLiveChatSettings(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getLiveChatSettings(client, cb) {
    let variables = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, requestOptions = arguments.length > 3 ? arguments[3] : void 0;
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getLiveChatSettingsDocument), variables, cb, requestOptions);
}
export function mockGetLiveChatStatus(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getLiveChatStatus(client, cb) {
    let variables = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, requestOptions = arguments.length > 3 ? arguments[3] : void 0;
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getLiveChatStatusDocument), variables, cb, requestOptions);
}
export function mockGetMatchStreamUrl(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getMatchStreamUrl(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getMatchStreamUrlDocument), variables, cb, requestOptions);
}
export function mockGetNavigationConfig(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getNavigationConfig(client, cb) {
    let variables = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, requestOptions = arguments.length > 3 ? arguments[3] : void 0;
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getNavigationConfigDocument), variables, cb, requestOptions);
}
export function mockGetOnboarding(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getOnboarding(client, cb) {
    let variables = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, requestOptions = arguments.length > 3 ? arguments[3] : void 0;
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getOnboardingDocument), variables, cb, requestOptions);
}
export function mockGetPartners(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getPartners(client, cb) {
    let variables = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, requestOptions = arguments.length > 3 ? arguments[3] : void 0;
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getPartnersDocument), variables, cb, requestOptions);
}
export function mockGetPendingBets(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getPendingBets(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getPendingBetsDocument), variables, cb, requestOptions);
}
export function mockGetPopularQueries(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getPopularQueries(client, cb) {
    let variables = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, requestOptions = arguments.length > 3 ? arguments[3] : void 0;
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getPopularQueriesDocument), variables, cb, requestOptions);
}
export function mockGetPushSettings(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getPushSettings(client, cb) {
    let variables = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, requestOptions = arguments.length > 3 ? arguments[3] : void 0;
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getPushSettingsDocument), variables, cb, requestOptions);
}
export function mockGetRootSettings(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getRootSettings(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getRootSettingsDocument), variables, cb, requestOptions);
}
export function mockGetRootSettingsInternational(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getRootSettingsInternational(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getRootSettingsInternationalDocument), variables, cb, requestOptions);
}
export function mockGetRoutingConfigs(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getRoutingConfigs(client, cb) {
    let variables = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, requestOptions = arguments.length > 3 ? arguments[3] : void 0;
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getRoutingConfigsDocument), variables, cb, requestOptions);
}
export function mockGetRules(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getRules(client, cb) {
    let variables = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, requestOptions = arguments.length > 3 ? arguments[3] : void 0;
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getRulesDocument), variables, cb, requestOptions);
}
export function mockGetSearchData(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getSearchData(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getSearchDataDocument), variables, cb, requestOptions);
}
export function mockGetSelfExclusion(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getSelfExclusion(client, cb) {
    let variables = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, requestOptions = arguments.length > 3 ? arguments[3] : void 0;
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getSelfExclusionDocument), variables, cb, requestOptions);
}
export function mockGetServiceWorkerConfiguration(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getServiceWorkerConfiguration(client, cb) {
    let variables = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, requestOptions = arguments.length > 3 ? arguments[3] : void 0;
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getServiceWorkerConfigurationDocument), variables, cb, requestOptions);
}
export function mockGetSiteConfigSettings(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getSiteConfigSettings(client, cb) {
    let variables = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, requestOptions = arguments.length > 3 ? arguments[3] : void 0;
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getSiteConfigSettingsDocument), variables, cb, requestOptions);
}
export function mockGetSportradarLiveMatchTrackerConfiguration(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getSportradarLiveMatchTrackerConfiguration(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getSportradarLiveMatchTrackerConfigurationDocument), variables, cb, requestOptions);
}
export function mockGetSportradarLiveScoreConfiguration(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getSportradarLiveScoreConfiguration(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getSportradarLiveScoreConfigurationDocument), variables, cb, requestOptions);
}
export function mockGetSportradarStatisticsConfiguration(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getSportradarStatisticsConfiguration(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getSportradarStatisticsConfigurationDocument), variables, cb, requestOptions);
}
export function mockGetSubscribedEvents(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getSubscribedEvents(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getSubscribedEventsDocument), variables, cb, requestOptions);
}
export function mockGetTempCoupons(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getTempCoupons(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getTempCouponsDocument), variables, cb, requestOptions);
}
export function mockGetTranslations(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getTranslations(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getTranslationsDocument), variables, cb, requestOptions);
}
export function mockGetUpcomingMeeting(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getUpcomingMeeting(client, cb) {
    let variables = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, requestOptions = arguments.length > 3 ? arguments[3] : void 0;
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getUpcomingMeetingDocument), variables, cb, requestOptions);
}
export function mockGetUserData(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getUserData(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getUserDataDocument), variables, cb, requestOptions);
}
export function mockGetUserDataPhone(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getUserDataPhone(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getUserDataPhoneDocument), variables, cb, requestOptions);
}
export function mockGetUserProfileBonus(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getUserProfileBonus(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getUserProfileBonusDocument), variables, cb, requestOptions);
}
export function mockGetVerificationToken(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getVerificationToken(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getVerificationTokenDocument), variables, cb, requestOptions);
}
export function mockGetWhatIsNewData(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getWhatIsNewData(client, cb) {
    let variables = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, requestOptions = arguments.length > 3 ? arguments[3] : void 0;
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getWhatIsNewDataDocument), variables, cb, requestOptions);
}
export function mockGoBack(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function goBack(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(goBackDocument), variables, cb, requestOptions);
}
export function mockConfirmManualApplication(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function confirmManualApplication(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(confirmManualApplicationDocument), variables, cb, requestOptions);
}
export function mockDeleteDocuments(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function deleteDocuments(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(deleteDocumentsDocument), variables, cb, requestOptions);
}
export function mockDropManualData(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function dropManualData(client, cb) {
    let variables = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, requestOptions = arguments.length > 3 ? arguments[3] : void 0;
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(dropManualDataDocument), variables, cb, requestOptions);
}
export function mockEsiaDataFetch(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function esiaDataFetch(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(esiaDataFetchDocument), variables, cb, requestOptions);
}
export function mockEsiaRedirect(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function esiaRedirect(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(esiaRedirectDocument), variables, cb, requestOptions);
}
export function mockEtsupisPhoneVerificationInit(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function etsupisPhoneVerificationInit(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(etsupisPhoneVerificationInitDocument), variables, cb, requestOptions);
}
export function mockEtsupisPhoneVerificationVerify(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function etsupisPhoneVerificationVerify(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(etsupisPhoneVerificationVerifyDocument), variables, cb, requestOptions);
}
export function mockFinishApplication(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function finishApplication(client, cb) {
    let variables = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, requestOptions = arguments.length > 3 ? arguments[3] : void 0;
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(finishApplicationDocument), variables, cb, requestOptions);
}
export function mockGetEsiaAouth2Link(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getEsiaAouth2Link(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getEsiaAouth2LinkDocument), variables, cb, requestOptions);
}
export function mockGetIdentificationState(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getIdentificationState(client, cb) {
    let variables = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, requestOptions = arguments.length > 3 ? arguments[3] : void 0;
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getIdentificationStateDocument), variables, cb, requestOptions);
}
export function mockGetThirdPartyIdentificationInit(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getThirdPartyIdentificationInit(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getThirdPartyIdentificationInitDocument), variables, cb, requestOptions);
}
export function mockInitIdentApp(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function initIdentApp(client, cb) {
    let variables = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, requestOptions = arguments.length > 3 ? arguments[3] : void 0;
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(initIdentAppDocument), variables, cb, requestOptions);
}
export function mockResetIdentificationRequest(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function resetIdentificationRequest(client, cb) {
    let variables = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, requestOptions = arguments.length > 3 ? arguments[3] : void 0;
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(resetIdentificationRequestDocument), variables, cb, requestOptions);
}
export function mockSaveIdentificationDraftData(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function saveIdentificationDraftData(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(saveIdentificationDraftDataDocument), variables, cb, requestOptions);
}
export function mockSetInn(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function setInn(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(setInnDocument), variables, cb, requestOptions);
}
export function mockSetManualData(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function setManualData(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(setManualDataDocument), variables, cb, requestOptions);
}
export function mockSubmitDocuments(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function submitDocuments(client, cb) {
    let variables = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, requestOptions = arguments.length > 3 ? arguments[3] : void 0;
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(submitDocumentsDocument), variables, cb, requestOptions);
}
export function mockGetLobby(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getLobby(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getLobbyDocument), variables, cb, requestOptions);
}
export function mockLogout(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function logout(client, cb) {
    let variables = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, requestOptions = arguments.length > 3 ? arguments[3] : void 0;
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(logoutDocument), variables, cb, requestOptions);
}
export function mockMakeBatchSlip(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function makeBatchSlip(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(makeBatchSlipDocument), variables, cb, requestOptions);
}
export function mockDoDeleteNotification(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doDeleteNotification(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doDeleteNotificationDocument), variables, cb, requestOptions);
}
export function mockDoMarkAsClicked(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doMarkAsClicked(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doMarkAsClickedDocument), variables, cb, requestOptions);
}
export function mockDoMarkAsDelivered(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doMarkAsDelivered(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doMarkAsDeliveredDocument), variables, cb, requestOptions);
}
export function mockDoMarkNotification(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doMarkNotification(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doMarkNotificationDocument), variables, cb, requestOptions);
}
export function mockGetNotifications(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getNotifications(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getNotificationsDocument), variables, cb, requestOptions);
}
export function mockDoCancelRequestedWithdrawals(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doCancelRequestedWithdrawals(client, cb) {
    let variables = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, requestOptions = arguments.length > 3 ? arguments[3] : void 0;
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doCancelRequestedWithdrawalsDocument), variables, cb, requestOptions);
}
export function mockDoSubmitDeposit(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doSubmitDeposit(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doSubmitDepositDocument), variables, cb, requestOptions);
}
export function mockDoSubmitWithdrawal(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doSubmitWithdrawal(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doSubmitWithdrawalDocument), variables, cb, requestOptions);
}
export function mockGetCompanyFee(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getCompanyFee(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getCompanyFeeDocument), variables, cb, requestOptions);
}
export function mockGetCryptoNetworkInfo(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getCryptoNetworkInfo(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getCryptoNetworkInfoDocument), variables, cb, requestOptions);
}
export function mockGetDepositSystemsList(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getDepositSystemsList(client, cb) {
    let variables = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, requestOptions = arguments.length > 3 ? arguments[3] : void 0;
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getDepositSystemsListDocument), variables, cb, requestOptions);
}
export function mockGetDepositSystemsOverviewList(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getDepositSystemsOverviewList(client, cb) {
    let variables = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, requestOptions = arguments.length > 3 ? arguments[3] : void 0;
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getDepositSystemsOverviewListDocument), variables, cb, requestOptions);
}
export function mockGetDepositsRegistrationCompletionStatus(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getDepositsRegistrationCompletionStatus(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getDepositsRegistrationCompletionStatusDocument), variables, cb, requestOptions);
}
export function mockGetDepositsSystemInfo(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getDepositsSystemInfo(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getDepositsSystemInfoDocument), variables, cb, requestOptions);
}
export function mockGetWithdrawalStats(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getWithdrawalStats(client, cb) {
    let variables = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, requestOptions = arguments.length > 3 ? arguments[3] : void 0;
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getWithdrawalStatsDocument), variables, cb, requestOptions);
}
export function mockGetWithdrawalSystemsList(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getWithdrawalSystemsList(client, cb) {
    let variables = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, requestOptions = arguments.length > 3 ? arguments[3] : void 0;
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getWithdrawalSystemsListDocument), variables, cb, requestOptions);
}
export function mockGetWithdrawalSystemsOverviewList(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getWithdrawalSystemsOverviewList(client, cb) {
    let variables = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, requestOptions = arguments.length > 3 ? arguments[3] : void 0;
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getWithdrawalSystemsOverviewListDocument), variables, cb, requestOptions);
}
export function mockGetWithdrawalsRegistrationCompletionStatus(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getWithdrawalsRegistrationCompletionStatus(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getWithdrawalsRegistrationCompletionStatusDocument), variables, cb, requestOptions);
}
export function mockGetWithdrawalsSystemInfo(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getWithdrawalsSystemInfo(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getWithdrawalsSystemInfoDocument), variables, cb, requestOptions);
}
export function mockDoCreatePinCode(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doCreatePinCode(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doCreatePinCodeDocument), variables, cb, requestOptions);
}
export function mockDoDeletePinCode(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doDeletePinCode(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doDeletePinCodeDocument), variables, cb, requestOptions);
}
export function mockDoVerifyPinCode(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doVerifyPinCode(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doVerifyPinCodeDocument), variables, cb, requestOptions);
}
export function mockGetPinCodeStatus(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getPinCodeStatus(client, cb) {
    let variables = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, requestOptions = arguments.length > 3 ? arguments[3] : void 0;
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getPinCodeStatusDocument), variables, cb, requestOptions);
}
export function mockDoDeactivateTimer(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doDeactivateTimer(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doDeactivateTimerDocument), variables, cb, requestOptions);
}
export function mockDoPlayTimeControl(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doPlayTimeControl(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doPlayTimeControlDocument), variables, cb, requestOptions);
}
export function mockPlayTimeControl(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function playTimeControl(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(playTimeControlDocument), variables, cb, requestOptions);
}
export function mockDoButtonMutation(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doButtonMutation(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doButtonMutationDocument), variables, cb, requestOptions);
}
export function mockDoPromotionParticipation(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doPromotionParticipation(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doPromotionParticipationDocument), variables, cb, requestOptions);
}
export function mockDoPromotionRate(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doPromotionRate(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doPromotionRateDocument), variables, cb, requestOptions);
}
export function mockGetActiveOrAvailableCampaigns(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getActiveOrAvailableCampaigns(client, cb) {
    let variables = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, requestOptions = arguments.length > 3 ? arguments[3] : void 0;
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getActiveOrAvailableCampaignsDocument), variables, cb, requestOptions);
}
export function mockGetBonusWalletPromotion(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getBonusWalletPromotion(client, cb) {
    let variables = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, requestOptions = arguments.length > 3 ? arguments[3] : void 0;
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getBonusWalletPromotionDocument), variables, cb, requestOptions);
}
export function mockGetPromotionCategories(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getPromotionCategories(client, cb) {
    let variables = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, requestOptions = arguments.length > 3 ? arguments[3] : void 0;
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getPromotionCategoriesDocument), variables, cb, requestOptions);
}
export function mockGetPromotionDetails(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getPromotionDetails(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getPromotionDetailsDocument), variables, cb, requestOptions);
}
export function mockGetPromotionNavigationConfig(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getPromotionNavigationConfig(client, cb) {
    let variables = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, requestOptions = arguments.length > 3 ? arguments[3] : void 0;
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getPromotionNavigationConfigDocument), variables, cb, requestOptions);
}
export function mockGetPromotions(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getPromotions(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getPromotionsDocument), variables, cb, requestOptions);
}
export function mockDoConfirmations(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doConfirmations(client, cb) {
    let variables = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, requestOptions = arguments.length > 3 ? arguments[3] : void 0;
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doConfirmationsDocument), variables, cb, requestOptions);
}
export function mockDoRewarding(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doRewarding(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doRewardingDocument), variables, cb, requestOptions);
}
export function mockGetAvailability(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getAvailability(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getAvailabilityDocument), variables, cb, requestOptions);
}
export function mockGetDetails(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getDetails(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getDetailsDocument), variables, cb, requestOptions);
}
export function mockGetReferral(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getReferral(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getReferralDocument), variables, cb, requestOptions);
}
export function mockGetRewards(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getRewards(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getRewardsDocument), variables, cb, requestOptions);
}
export function mockDoRegistration(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doRegistration(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doRegistrationDocument), variables, cb, requestOptions);
}
export function mockGetCurrenciesByCountry(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getCurrenciesByCountry(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getCurrenciesByCountryDocument), variables, cb, requestOptions);
}
export function mockGetIncompleteRegistrationFields(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getIncompleteRegistrationFields(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getIncompleteRegistrationFieldsDocument), variables, cb, requestOptions);
}
export function mockUpdateIncompleteRegistrationFields(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function updateIncompleteRegistrationFields(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(updateIncompleteRegistrationFieldsDocument), variables, cb, requestOptions);
}
export function mockRemoveCustomerMessage(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function removeCustomerMessage(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(removeCustomerMessageDocument), variables, cb, requestOptions);
}
export function mockResendVerificationEmail(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function resendVerificationEmail(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(resendVerificationEmailDocument), variables, cb, requestOptions);
}
export function mockResetDisposableKeys(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function resetDisposableKeys(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(resetDisposableKeysDocument), variables, cb, requestOptions);
}
export function mockResetShown(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function resetShown(client, cb) {
    let variables = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, requestOptions = arguments.length > 3 ? arguments[3] : void 0;
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(resetShownDocument), variables, cb, requestOptions);
}
export function mockCheckRestorePasswordSms(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function checkRestorePasswordSms(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(checkRestorePasswordSmsDocument), variables, cb, requestOptions);
}
export function mockCheckRestorePasswordUid(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function checkRestorePasswordUid(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(checkRestorePasswordUidDocument), variables, cb, requestOptions);
}
export function mockDoRestorePasswordByEmail(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doRestorePasswordByEmail(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doRestorePasswordByEmailDocument), variables, cb, requestOptions);
}
export function mockDoRestorePasswordByPhone(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function doRestorePasswordByPhone(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(doRestorePasswordByPhoneDocument), variables, cb, requestOptions);
}
export function mockResetPinCodeEmail(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function resetPinCodeEmail(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(resetPinCodeEmailDocument), variables, cb, requestOptions);
}
export function mockResetPinCodePhone(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function resetPinCodePhone(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(resetPinCodePhoneDocument), variables, cb, requestOptions);
}
export function mockSendRestorePasswordLinkToEmail(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function sendRestorePasswordLinkToEmail(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(sendRestorePasswordLinkToEmailDocument), variables, cb, requestOptions);
}
export function mockSendRestorePasswordSmsOrCallToPhone(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function sendRestorePasswordSmsOrCallToPhone(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(sendRestorePasswordSmsOrCallToPhoneDocument), variables, cb, requestOptions);
}
export function mockSendRestorePasswordSmsToPhone(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function sendRestorePasswordSmsToPhone(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(sendRestorePasswordSmsToPhoneDocument), variables, cb, requestOptions);
}
export function mockSendRestorePinLinkToEmail(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function sendRestorePinLinkToEmail(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(sendRestorePinLinkToEmailDocument), variables, cb, requestOptions);
}
export function mockSendRestorePinLinkToPhone(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function sendRestorePinLinkToPhone(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(sendRestorePinLinkToPhoneDocument), variables, cb, requestOptions);
}
export function mockSavePushSettings(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function savePushSettings(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(savePushSettingsDocument), variables, cb, requestOptions);
}
export function mockGetSpintaxMetaData(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getSpintaxMetaData(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getSpintaxMetaDataDocument), variables, cb, requestOptions);
}
export function mockSetAdditionalPropsUser(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function setAdditionalPropsUser(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(setAdditionalPropsUserDocument), variables, cb, requestOptions);
}
export function mockSetBannerClosed(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function setBannerClosed(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(setBannerClosedDocument), variables, cb, requestOptions);
}
export function mockSetBetLimits(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function setBetLimits(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(setBetLimitsDocument), variables, cb, requestOptions);
}
export function mockSetCoookie(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function setCoookie(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(setCoookieDocument), variables, cb, requestOptions);
}
export function mockSetDepositLimits(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function setDepositLimits(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(setDepositLimitsDocument), variables, cb, requestOptions);
}
export function mockSetDowntimeShown(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function setDowntimeShown(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(setDowntimeShownDocument), variables, cb, requestOptions);
}
export function mockSetWhatsNew(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function setWhatsNew(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(setWhatsNewDocument), variables, cb, requestOptions);
}
export function mockGetTsupisLegalAgreementsVersions(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getTsupisLegalAgreementsVersions(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getTsupisLegalAgreementsVersionsDocument), variables, cb, requestOptions);
}
export function mockGetTsupisOfferAgreements(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getTsupisOfferAgreements(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getTsupisOfferAgreementsDocument), variables, cb, requestOptions);
}
export function mockGetTsupisPersonalDataAgreements(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function getTsupisPersonalDataAgreements(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(getTsupisPersonalDataAgreementsDocument), variables, cb, requestOptions);
}
export function mockUpdateCustomerProfileFields(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function updateCustomerProfileFields(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(updateCustomerProfileFieldsDocument), variables, cb, requestOptions);
}
export function mockVerifyEmail(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function verifyEmail(client, cb, variables, requestOptions) {
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(verifyEmailDocument), variables, cb, requestOptions);
}
export function mockWithdrawBonusCBC(callback) {
    // @ts-ignore
    return ()=>{};
}
export async function withdrawBonusCBC(client, cb) {
    let variables = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, requestOptions = arguments.length > 3 ? arguments[3] : void 0;
    // @ts-ignore
    if (void 0 === client) throw new Error('Unexpected state');
    // @ts-ignore
    return client.requestGraphql(print(withdrawBonusCBCDocument), variables, cb, requestOptions);
}
export function unMockAll() {
    mocks = {};
}
