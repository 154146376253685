import { isNumber } from '@leon-hub/guards';
import { getBuiltinConfig } from '@leon-hub/service-locator-env';
let canUseConfig = true;
export default function createCoreApi(client) {
    return {
        async syncServerTime () {
            if (canUseConfig) {
                const { docParseTime, time } = getBuiltinConfig();
                canUseConfig = false;
                if (time && docParseTime) {
                    const timeFromParseToNow = Date.now() - docParseTime;
                    return time + timeFromParseToNow;
                }
            }
            return client.request({
                id: 'time/sync',
                endpoint: '/time',
                guard: isNumber,
                silent: true
            });
        }
    };
}
