import { isLocalStorageAvailable, localStorageManager } from '@leon-hub/local-storage';
export var DEBUG_KEY;
var DEBUG_KEY1;
(DEBUG_KEY1 = DEBUG_KEY || (DEBUG_KEY = {}))["GENERAL"] = "DEBUG", DEBUG_KEY1["POST_MESSAGE"] = "DEBUG_POST_MESSAGE", DEBUG_KEY1["ADVANCED_HTTP_REQUESTS_DISABLED"] = "ADVANCED_HTTP_REQUESTS_DISABLED";
export function isDebugEnabled() {
    let debugKey = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : "DEBUG";
    return !!isLocalStorageAvailable() && Boolean(localStorageManager.getItem(debugKey));
}
export function enableDebug() {
    let debugKey = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : "DEBUG";
    if (isLocalStorageAvailable()) localStorageManager.setItem(debugKey, '1');
}
export function disableDebug() {
    let debugKey = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : "DEBUG";
    if (isLocalStorageAvailable()) localStorage.removeItem(debugKey);
}
