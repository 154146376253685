import { _ as _define_property } from "@swc/helpers/_/_define_property";
import noop from 'lodash/noop';
import { AbstractError, LogLevel } from '@leon-hub/errors';
import { getLocationHref } from '@leon-hub/service-locator-env';
import { isLogData } from '../guards';
import { consoleLog } from '../utils';
import { NameSpacedLogger } from './NameSpacedLogger';
export class Logger {
    init(options) {
        this.options = options;
        this.loaded = true;
    }
    setRemoteSaveAction(remoteSaveAction) {
        this.options.remoteSaveAction = remoteSaveAction;
    }
    // eslint-disable-next-line sonarjs/cognitive-complexity
    sendLog(level, input, payload, error, namespace) {
        if (!this.loaded) // eslint-disable-next-line no-console
        console.warn('LOGGING CALLED WITH DEFAULT PARAMETERS! To setup logger please call logger.init() action!');
        let message = '';
        let trace = '';
        let payloadForRemoteLog = '';
        let payloadForLocalLog = payload;
        const namespacePrefix = namespace && namespace.length > 0 ? // eslint-disable-next-line prefer-template
        namespace.map((item)=>`[${item}]`).join('') + ' ' : '';
        if ('string' == typeof input) {
            message = input;
            if (payload) {
                if (payload instanceof Error) {
                    trace = payload.stack || '';
                    if (payload instanceof AbstractError) payloadForRemoteLog = JSON.stringify(payload);
                    else message += `\n${payload}`;
                } else {
                    payloadForRemoteLog = JSON.stringify(payload);
                    payloadForLocalLog = payload;
                }
            }
            if (error) {
                trace = error.stack || '';
                message += `\n${error}`;
            }
        } else if (input instanceof AbstractError) {
            const { stack, message: errorMessage, ...sendToLog } = input.toJSON();
            message = `${input}`;
            trace = stack || '';
            payloadForRemoteLog = JSON.stringify(sendToLog);
            payloadForLocalLog = payloadForLocalLog || input.payload;
        } else if (input instanceof Error) {
            message = `${input}`;
            trace = input.stack || '';
            payloadForRemoteLog = JSON.stringify(payload || input);
        } else if (isLogData(input)) {
            message = input.message;
            trace = input.stacktrace;
            payloadForRemoteLog = input.payload || '';
            payloadForLocalLog = payloadForLocalLog || input.payload;
        }
        message = `${namespacePrefix}${message}`;
        if (void 0 !== this.options.remoteSaveAction && Logger.isLoggableLevel(level, this.options.remoteSaveActionLogLevel)) {
            let url = '';
            try {
                url = getLocationHref();
            } catch  {}
            //
            const remoteInput = {
                appName: this.options.appName,
                appVersion: this.options.appVersion,
                level,
                line: 0,
                column: 0,
                error: message,
                file: '',
                url,
                ts: 0,
                stacktrace: trace || '',
                payload: payloadForRemoteLog || ''
            };
            // eslint-disable-next-line no-console
            this.options.remoteSaveAction(remoteInput).catch(noop);
        }
        if (!this.options.remoteSaveAction || this.options.isConsoleOutputAllowed?.()) consoleLog(level, message, trace, payloadForLocalLog);
    }
    static isLoggableLevel(level1, level2) {
        if (!level2) return true;
        switch(level2){
            case LogLevel.DEBUG:
                return true;
            case LogLevel.INFO:
                return level1 === LogLevel.INFO || level1 === LogLevel.WARN || level1 === LogLevel.ERROR;
            case LogLevel.WARN:
                return level1 === LogLevel.WARN || level1 === LogLevel.ERROR;
            case LogLevel.ERROR:
                return level1 === LogLevel.ERROR;
            default:
                return false;
        }
    }
    info(input, payload, error) {
        this.sendLog(LogLevel.INFO, input, payload, error);
    }
    debug(input, payload, error) {
        this.sendLog(LogLevel.DEBUG, input, payload, error);
    }
    warn(input, payload, error) {
        this.sendLog(LogLevel.WARN, input, payload, error);
    }
    error(input, payload, error) {
        this.sendLog(LogLevel.ERROR, input, payload, error);
    }
    createNamespace(namespace) {
        return new NameSpacedLogger(this, namespace ? [
            namespace
        ] : []);
    }
    constructor(options){
        _define_property(this, "loaded", false);
        _define_property(this, "options", {
            appName: '',
            appVersion: '',
            remoteSaveActionLogLevel: LogLevel.DEBUG
        });
        if (options) this.init(options);
    }
}
