import { QueryParameterName } from '@leon-hub/query-manager';
import { getQueryParams } from '@leon-hub/service-locator-env';
import ApplicationGqlClient from './ApplicationGqlClient';
let gqlClient;
export default function useGraphqlClient() {
    if (!gqlClient) {
        const runtimePlatform = getQueryParams()[QueryParameterName.PLATFORM];
        gqlClient = new ApplicationGqlClient({
            headers: runtimePlatform ? {
                // @see LEONWEB-6203
                'X-APP-PLATFORM': runtimePlatform
            } : {},
            // Set origin to same page url for prerender,
            // because default origin is defined with ?prhost query param.
            origin: process.env.VUE_APP_PRERENDER ? '' : void 0
        });
    }
    return gqlClient;
}
