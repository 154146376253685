import { ApiErrorCode } from '../../errors/ApiErrorCode';
import GqlApiBatchError from './GqlApiBatchError';
export class GqlApiPreviousRequestHasNotExpired extends GqlApiBatchError {
    constructor(options){
        super({
            ...options,
            code: ApiErrorCode.API_PREVIOUS_REQUEST_HAS_NOT_EXPIRED
        });
    }
}
