import { isNumber, isObject } from '@leon-hub/guards';
var NavigationFailureType;
var NavigationFailureType1;
(NavigationFailureType1 = NavigationFailureType || (NavigationFailureType = {}))[NavigationFailureType1["redirected"] = 2] = "redirected", NavigationFailureType1[NavigationFailureType1["aborted"] = 4] = "aborted", NavigationFailureType1[NavigationFailureType1["cancelled"] = 8] = "cancelled", NavigationFailureType1[NavigationFailureType1["duplicated"] = 16] = "duplicated";
export default function isWhitelistedError(error) {
    return !!(isObject(error) && // eslint-disable-next-line no-underscore-dangle
    (error._isRouter && isNumber(error.type) && [
        2,
        16,
        8
    ].includes(error.type) || // eslint-disable-next-line no-underscore-dangle
    error._isWhiteListed || error instanceof Error && // eslint-disable-next-line
    (error.message.includes("This browser doesn't support the API's required to use the Firebase") || // some tracker script loaded through googletagmanager is blocked by adblocker
    error.stack?.includes('googletagmanager'))));
}
