export default function promiseTimeout(param) {
    let { promise, timeout, error, onTimeout } = param;
    if (timeout === 1 / 0) return promise;
    Number.isFinite(timeout);
    let timeoutID;
    let pending = true;
    const rejectingPromise = new Promise((resolve, reject)=>{
        timeoutID = setTimeout(()=>{
            if (pending) {
                const rejection = 'string' != typeof error && error ? error() : new Error(error || `Timeout of ${timeout} exceeded`);
                reject(rejection);
                onTimeout?.();
            }
        }, timeout);
    });
    const tearDown = ()=>{
        pending = false;
        // Do not throw a timeout error when the promise is rejected.
        // Avoid triggering an unexpected breakpoint within the debug session.
        if (timeoutID) clearInterval(timeoutID);
    };
    return Promise.race([
        promise.then((result)=>{
            tearDown();
            return result;
        }, (reason)=>{
            tearDown();
            return Promise.reject('function' == typeof error ? error(reason) : reason);
        }),
        rejectingPromise
    ]);
}
